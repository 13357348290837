import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import camelCase from 'lodash/camelCase';
import compact from 'lodash/compact';
import has from 'lodash/has';
import Col from 'react-bootstrap/Col';
import Fade from 'react-bootstrap/Fade';
import PropTypes from 'prop-types';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { applyMask } from '@/masks';
import Button from '@/components/Button';
import Popover from '@/components/Popover';
import FormField from '@/components/FormField';
import Form from '@/components/Form';
import { MaskedInput, Select, TextInput } from '@/components/Inputs';
import Icon from '@/components/Icon';
import Truncate from '@/components/Truncate';
import Tooltip from '@/components/Tooltip';
import LoadingButton from '@/components/LoadingButton';
import { prepareTrackerIntent } from '@/utils';

const propTypes = {
  active: PropTypes.bool,
  show: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onSelectIntent: PropTypes.func,
  onEditEntity: PropTypes.func,
  entityScreen: PropTypes.string,
  relatedEntity: PropTypes.object,
  entities: PropTypes.array,
  activity: PropTypes.object,
  intents: PropTypes.array
};

const defaultProps = {
  active: false,
  show: false,
  entityScreen: '',
  onSave: () => { },
  onClose: () => { },
  onCancel: () => { },
  onEditEntity: () => { },
  onSelectIntent: () => { },
  relatedEntity: {},
  entities: [],
  activity: {},
  intents: []
};

const POPOVER_DELAY = 500;

const MAX_PHONE_LENGTH = 11;
const BR_DDI = '+55';
const PHONE_TYPE_FIELD = 'phone_type';
const PHONE_FIELD = 'phone';
const EMAIL_FIELD = 'email';
const WHATSAPP_FIELD = 'whatsapp';
const WORK_PHONE_FIELD = 'work_phone';
const MOBILE_PHONE_FIELD = 'mobile_phone';

const PHONE_TYPE_OPTIONS = [
  { label: 'Celular', value: MOBILE_PHONE_FIELD },
  { label: 'WhatsApp', value: WHATSAPP_FIELD },
  { label: 'Telefone', value: WORK_PHONE_FIELD }
];

const INTENTS_PROPS = {
  'contact.phone': {
    label: (value) => (
      <>
        <span className='ms-2 me-1'>Adicionar o número de telefone</span>
        <Truncate className='fw-bold' style={{ maxWidth: 230 }}>{value}</Truncate>
        <span className='ms-1'>aos dados do contato?</span>
      </>
    ),
    additionalText: 'Selecione em qual campo adicionar o número',
    buttonText: 'Sim, adicionar',
    title: (name) => `Adicionar telefone em ${name}`,
    warning: (text) => `Este telefone irá substituir o valor atual do campo telefone: ${text}`
  },
  'contact.email': {
    label: (value) => (
      <>
        <span className='ms-2 me-1'>Adicionar o e-mail</span>
        <Truncate className='fw-bold' style={{ maxWidth: 230 }}>{value}</Truncate>
        <span className='ms-1'>aos dados do contato?</span>
      </>
    ),
    additionalText: 'E-mail',
    buttonText: 'Sim, adicionar',
    buttonClass: 'mt-4',
    title: (name) => `Adicionar e-mail em ${name}`,
    warning: (text) => `Este e-mail irá substituir o valor atual do campo e-mail: ${text}`
  },
  'activity.note': {
    label: () => <span className='ms-2 me-1'>Que tal registrar uma nova anotação?</span>,
    buttonText: 'Sim, registrar',
    buttonClass: 'mt-4'
  },
  'activity.task': {
    label: () => <span className='ms-2 me-1'>Que tal registrar uma nova tarefa?</span>,
    buttonText: 'Sim, registrar',
    buttonClass: 'mt-4'
  },
  'activity.email': {
    label: () => <span className='ms-2 me-1'>
      Que tal agendar um próximo passo para enviar o e-mail?
    </span>,
    buttonText: 'Sim, agendar',
    buttonClass: 'mt-4'
  },
  'activity.proposal': {
    label: () => <span className='ms-2 me-1'>
      Que tal agendar um próximo passo para enviar a proposta?
    </span>,
    buttonText: 'Sim, agendar',
    buttonClass: 'mt-4'
  },
  'activity.visit': {
    label: () => <span className='ms-2 me-1'>
      Que tal agendar um próximo passo para fazer a visita?
    </span>,
    buttonText: 'Sim, agendar',
    buttonClass: 'mt-4'
  },
  'activity.call': {
    label: () => <span className='ms-2 me-1'>
      Que tal agendar um próximo passo para fazer a ligação?
    </span>,
    buttonText: 'Sim, agendar',
    buttonClass: 'mt-4'
  },
  'activity.meeting': {
    label: () => <span className='ms-2 me-1'>
      Que tal agendar um próximo passo para fazer a reunião?
    </span>,
    buttonText: 'Sim, agendar',
    buttonClass: 'mt-4'
  }
};


const CONTACT_INPUTS = {
  [WHATSAPP_FIELD]: {
    InputComponent: MaskedInput,
    inputProps: {
      name: WHATSAPP_FIELD,
      maskType: WHATSAPP_FIELD,
      placeholder: '+00 (00) 00000-0000'
    }
  },
  [WORK_PHONE_FIELD]: {
    InputComponent: MaskedInput,
    inputProps: {
      name: WORK_PHONE_FIELD,
      maskType: PHONE_FIELD,
      placeholder: '(00) 00000-0000'
    }
  },
  [MOBILE_PHONE_FIELD]: {
    InputComponent: MaskedInput,
    inputProps: {
      name: MOBILE_PHONE_FIELD,
      maskType: PHONE_FIELD,
      placeholder: '(00) 00000-0000'
    }
  },
  [EMAIL_FIELD]: {
    InputComponent: TextInput,
    inputProps: {
      name: EMAIL_FIELD,
      placeholder: 'exemplo@email.com.br',
      className: 'w-100'
    }
  }
};

function SmartSuggestion({
  active,
  show,
  entityScreen,
  onSave,
  onClose,
  onCancel,
  onEditEntity,
  onSelectIntent,
  intents,
  relatedEntity,
  entities,
  activity
}) {
  const { user } = useAuth();
  const tracker = useTracking();
  const [selectedPhoneField, setSelectedPhoneField] = useState(MOBILE_PHONE_FIELD);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showContactPopover, setShowContactPopover] = useState(false);
  const [entityMatched, setEntityMatched] = useState({});
  const currentIntent = intents[currentIndex];
  const fieldType = currentIntent?.type;
  const fieldProcessing = currentIntent?.processing;
  const suggestionData = currentIntent?.suggestionData;
  const fieldValue = currentIntent?.value;
  const fieldPadSize = fieldValue?.length + BR_DDI.length;
  const isPhoneField = fieldType?.includes(PHONE_FIELD);
  const isContactIntent = fieldType?.includes('contact.');
  const isActivityIntent = fieldType?.includes('activity.');
  const selectedMask = fieldValue?.length <= MAX_PHONE_LENGTH ? PHONE_FIELD : WHATSAPP_FIELD;

  const initialValues = {
    [PHONE_TYPE_FIELD]: MOBILE_PHONE_FIELD,
    [WHATSAPP_FIELD]: fieldValue?.padStart(fieldPadSize, BR_DDI),
    [MOBILE_PHONE_FIELD]: fieldValue,
    [WORK_PHONE_FIELD]: fieldValue,
    [EMAIL_FIELD]: fieldValue
  };

  const inputType = isPhoneField ? selectedPhoneField : EMAIL_FIELD;
  const { InputComponent, inputProps } = CONTACT_INPUTS[inputType] || {};
  const intentsProps = INTENTS_PROPS[fieldType];

  const isOrganizationMatched = has(entityMatched, 'people');
  const trackerIntents = intents.map(prepareTrackerIntent);
  const trackerCurrentIntent = prepareTrackerIntent(currentIntent);

  useEffect(() => {
    if (isContactIntent && !fieldProcessing) {
      const matchedContacts = compact([suggestionData]);

      const result = entities?.find((entityFound) => {
        const entityName = entityFound?.name?.trim()?.toLowerCase();
        const matched = matchedContacts?.find((matchedContact) => {
          const matchedName = matchedContact?.name?.trim()?.toLowerCase();
          return (
            entityName?.includes?.(matchedName) ||
            matchedName?.includes?.(entityName)
          );
        });
        return matched;
      });

      const foundEntityMatched = result ?? relatedEntity;

      setEntityMatched(foundEntityMatched);
    }
  }, [isContactIntent, fieldProcessing]);

  useEffect(() => {
    if (!currentIntent) {
      setCurrentIndex(0);
    }
  }, [intents]);

  const handleFormSubmit = (form) => {
    const data = {
      id: entityMatched.id,
      contact: {
        id: entityMatched.contact.id,
        [inputType]: form[inputType]
      }
    };
    const entityType = isOrganizationMatched ? 'organization' : 'person';
    onEditEntity(data, () => {
      setShowContactPopover(false);
      setTimeout(() => {
        tracker.trackSmartSuggestionConfirmed({
          user,
          activity,
          screen: entityScreen,
          intents: trackerIntents,
          currentIntent: trackerCurrentIntent
        });
        onSave(currentIntent);
      }, 1);
    }, { ...entityMatched, entityType });
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, intents?.length - 1));
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleAddActivityClick = () => {
    tracker.trackSmartSuggestionAccepted({
      user,
      activity,
      screen: entityScreen,
      intents: trackerIntents,
      currentIntent: trackerCurrentIntent
    });
    onSelectIntent(currentIntent);
  };

  const handleAddContactClick = () => {
    tracker.trackSmartSuggestionAccepted({
      user,
      activity,
      screen: entityScreen,
      intents: trackerIntents,
      currentIntent: trackerCurrentIntent
    });
    setShowContactPopover(true);
  };

  const handleClose = () => {
    tracker.trackSmartSuggestionClosed({
      user,
      activity,
      screen: entityScreen,
      intents: trackerIntents,
      currentIntent: trackerCurrentIntent
    });
    setCurrentIndex(0);
    onClose();
  };

  const handlePopoverCancel = () => {
    setShowContactPopover(false);
    setTimeout(() => {
      tracker.trackSmartSuggestionCancelled({
        user,
        activity,
        screen: entityScreen,
        intents: trackerIntents,
        currentIntent: trackerCurrentIntent
      });
      onCancel(currentIntent);
      if (currentIndex < intents?.length - 1) {
        setCurrentIndex(0);
      } else {
        handleClose();
      }
    }, POPOVER_DELAY);
  };

  if (!active) {
    return null;
  }

  return (
    <Fade in={show} unmountOnExit>
      <div
        className={classnames(
          'd-flex align-items-center justify-content-between',
          'bg-lighter-purple p-2 rounded-bottom'
        )}
      >
        <div className='d-flex align-items-center justify-content-start'>
          {
            intents?.length > 1
              ? (
                <>
                  <Tooltip content='Sugestão anterior' placement='top'>
                    <Button
                      className='p-0 mb-1'
                      size='sm'
                      variant='link'
                      onClick={handlePrev}
                      disabled={!currentIndex}
                    >
                      <Icon name='arrow-left' className='text-dark-gray' />
                    </Button>
                  </Tooltip>
                  <span className='fw-bold mb-1 text-small'>
                    {currentIndex + 1}/{intents?.length}
                  </span>
                  <Tooltip content='Próxima sugestão' placement='top'>
                    <Button
                      className='p-0 mb-1'
                      size='sm'
                      variant='link'
                      onClick={handleNext}
                      disabled={currentIndex === intents?.length - 1}
                    >
                      <Icon name='arrow-right' className='text-dark-gray' />
                    </Button>
                  </Tooltip>
                </>
              )
              : null
          }
          <div className='d-inline-flex text-nowrap'>
            {intentsProps?.label(isPhoneField ? applyMask(fieldValue, selectedMask) : fieldValue)}
          </div>
        </div>
        <div className='d-flex'>
          {
            isContactIntent
              ? (
                <Popover
                  className='width-6'
                  placement='left'
                  hide={!showContactPopover}
                  trigger={['clickIn']}
                  showArrow
                  rootCloseEvent='mousedown'
                  content={() => (
                    <Form
                      name='smart-suggestion-contacts-form'
                      initialValues={initialValues}
                      onSubmit={handleFormSubmit}
                      className='w-100'
                    >
                      {({ handleSubmit, handleBlur, isSubmitting }) => {
                        const { contact } = entityMatched ?? {};
                        const entityPhone = contact?.[camelCase(selectedPhoneField)];
                        const entityEmail = contact?.email;
                        const entityPhoneOrEmail = isPhoneField ? entityPhone : entityEmail;

                        return (
                          <>
                            <h4 className='my-4'>
                              {intentsProps?.title?.(entityMatched?.name)}
                            </h4>
                            {
                              entityPhoneOrEmail
                                ? (
                                  <div className={classnames(
                                    'd-flex align-items-center bg-light-yellow rounded p-3 mb-3'
                                  )}>
                                    <Col sm={1} className='ms-1'>
                                      <Icon name='info' size='sm' />
                                    </Col>
                                    <Col sm={11}>
                                      {intentsProps?.warning?.(entityPhoneOrEmail)}
                                    </Col>
                                  </div>
                                )
                                : null
                            }
                            <div className='my-2'>{intentsProps?.additionalText}</div>
                            <div className='d-flex'>
                              {
                                isPhoneField
                                  ? (
                                    <FormField
                                      as={Select}
                                      name={PHONE_TYPE_FIELD}
                                      value={selectedPhoneField}
                                      className='me-2 w-50'
                                      options={PHONE_TYPE_OPTIONS}
                                      onChange={setSelectedPhoneField}
                                    />
                                  )
                                  : null
                              }
                              <FormField
                                as={InputComponent}
                                onBlur={handleBlur}
                                {...inputProps}
                              />
                            </div>
                            <div className={classnames(
                              'd-flex justify-content-end',
                              intentsProps?.buttonClass
                            )}>
                              <Button
                                variant='outline-dark-gray'
                                className='me-2'
                                onClick={handlePopoverCancel}
                                disabled={isSubmitting}
                              >
                                Cancelar
                              </Button>
                              <LoadingButton
                                variant='primary'
                                loadingText='Salvando...'
                                onClick={handleSubmit}
                                isLoading={isSubmitting}
                              >
                                Salvar
                              </LoadingButton>
                            </div>
                          </>
                        );
                      }
                      }
                    </Form>
                  )}
                >
                  <div>
                    <Button
                      variant='white'
                      onClick={handleAddContactClick}
                      className='text-nowrap'
                    >
                      {intentsProps?.buttonText}
                    </Button>
                  </div>
                </Popover>
              )
              : null
          }
          {
            isActivityIntent
              ? (
                <div>
                  <LoadingButton
                    variant='white'
                    loadingText='Analisando...'
                    isLoading={fieldProcessing}
                    onClick={handleAddActivityClick}
                    className='text-nowrap'
                  >
                    {intentsProps?.buttonText}
                  </LoadingButton>
                </div>
              )
              : null
          }
          <Button variant='link' onClick={handleClose}>
            <Icon name='close' className='text-dark-gray' />
          </Button>
        </div>
      </div>
    </Fade>
  );
}

SmartSuggestion.propTypes = propTypes;
SmartSuggestion.defaultProps = defaultProps;

export default SmartSuggestion;
