import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { toCurrencyString } from '@/number';
import Icon from '@/components/Icon';
import Truncate from '@/components/Truncate';

const propTypes = {
  discount: PropTypes.object.isRequired,
  periodicityText: PropTypes.string
};

const ICONS = {
  percentage: 'promo',
  value: 'coupon'
};

function DiscountBanner({ discount, periodicityText }) {
  const {
    description,
    discountValue,
    percentOff,
    discountType,
    billingCoupon,
    recurring,
    default: isDefault
  } = discount;

  const rightText = toCurrencyString(discountValue);

  return (
    <div
      className={classnames(
        'd-flex',
        'align-items-center',
        'px-2',
        'py-3',
        'mt-3',
        'rounded',
        'bg-light-green',
        'text-success',
        'text-small'
      )}
    >
      <div>
        <Icon
          name={ICONS[discountType]}
          size='lg'
        />
      </div>

      <div className='ms-3 me-3 flex-fill'>
        { isDefault
          ? (
            <span>
              Você ganhou <span className='fw-bold'>{percentOff}% de desconto</span> <br />
              assinando o plano {periodicityText}.
            </span>
          )
          : (
            <div>
              {!recurring && (
                <div className='fw-bold'>
                  Cupom aplicado: {billingCoupon?.code}
                </div>
              )}

              <Truncate as='span' lines={2}>
                {description}
              </Truncate>
            </div>
          )
        }
      </div>

      <span className='white-space-nowrap fw-bold'>
        {`- ${rightText}`}
      </span>
    </div>
  );
}

DiscountBanner.propTypes = propTypes;

export default DiscountBanner;
