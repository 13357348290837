import { useMutation, useQueryClient } from 'react-query';
import { post } from '@/lib/fetch';
import { activitiesKeys } from '@/api';
import { EMAIL_STATUSES } from '@/utils';

export async function createEmail(params = {}) {
  const { data } = await post('/emails', params);

  return data;
}

export function useCreateEmail({ activityEntityType, config = {} } = {}) {
  const queryClient = useQueryClient();

  const getEntityId = (data) => {
    switch (activityEntityType) {
      case 'deal':
        return data.deal.id;
      case 'person':
        return data.person.id;
      case 'organization':
        return data.organization.id;
      default:
        return null;
    }
  };

  const onSuccess = ({ data: newData }) => {
    const key = activitiesKeys.infiniteListsByEntity({
      entityType: activityEntityType,
      entityId: getEntityId(newData)
    });

    // Feedback otimista de Enviado é mostrado mesmo antes de o envio ser completo
    const emailWithSentStatus = {
      ...newData.email,
      status: EMAIL_STATUSES.SENT
    };
    const dataWithSentEmails = { ...newData, email: emailWithSentStatus };

    // Atualiza dados da query evitando mutação
    queryClient.setQueriesData(key, (previousData) => {
      if (!previousData) {
        return;
      }

      const [firstPage, ...otherPages] = previousData.pages;

      const updatedFirstPage = {
        ...firstPage,
        data: [dataWithSentEmails, ...firstPage.data]
      };

      return {
        ...previousData,
        pages: [
          updatedFirstPage,
          ...otherPages
        ]
      };
    });
  };

  return useMutation(
    createEmail,
    { ...config, onSuccess }
  );
}
