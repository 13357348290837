import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

DetailsCard.propTypes = {
  title: PropTypes.string.isRequired,
  actionButton: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
};

DetailsCard.defaultProps = {
  title: '',
  actionButton: null,
  className: '',
  size: 'md'
};

function DetailsCard({ title, children, className, actionButton, size, actionTitle }) {
  return (
    <div className={classnames('bg-white rounded', className)}>
      <div className={classnames(
        'd-flex justify-content-between align-items-center',
        'px-3 text-darker-gray fw-bold',
        { 'py-1': actionButton },
        { 'py-3': !actionButton },
        { 'ms-2': actionTitle }
      )}>
        {title}
        {actionButton}
      </div>

      <hr className='m-0' />

      <div className={classnames(
        'px-3 py-4 me-4 overflow-y-auto overflow-x-hidden',
        { 'entity-modal-height-sm': size === 'sm' },
        { 'max-height-5': size === 'md' },
        { 'entity-modal-height-lg': size === 'lg' }
      )}>
        {children}
      </div>
    </div>
  );
}

export default DetailsCard;
