import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '@/components/Modal';

const propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  url: PropTypes.string
};

function ThreeDSecureModal({ show, onHide, url }) {
  /*
   * Listener para o evento de mensagem do iframe de 3D Secure.
   * Nesse caso, esperamos a mensagem '3DS-authentication-complete' para então
   * chamar a função onHide, que fecha o modal após a autenticação.
   * ref: https://docs.stripe.com/payments/3d-secure/authentication-flow
   */
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === '3DS-authentication-complete') {
        onHide();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [onHide]);

  return (
    <Modal
      disableClose
      size='md'
      show={show}
      onHide={onHide}
      bodyClassName='d-flex flex-column p-8 align-items-center text-center'
      backdrop='static'
    >
      <iframe
        width='100%'
        height='600px'
        src={url}
        allow='fullscreen'
      />
    </Modal>
  );
}

ThreeDSecureModal.propTypes = propTypes;

export default ThreeDSecureModal;
