import React from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  parseDate,
  getTimelineDayLabel,
  toTimeStringWithSeparator
} from '@/date';

function DateField({
  labelName,
  stringDate
}) {
  const dateStringFormater = (date) => {
    if (!date) {
      return 'Indefinido';
    }

    const parsedDate = date ? parseDate(date) : null;
    const dateLabel = parsedDate && getTimelineDayLabel(parsedDate);
    const formattedDate = (
      parsedDate ? toTimeStringWithSeparator(dateLabel, parsedDate) : null
    );
    return formattedDate;
  };

  return (
    <Row className='align-items-center mb-3'>
      <Col sm={4}>
        <span className='fw-bold text-small'>{labelName}</span>
      </Col>
      <Col sm={8}>
        <span className='text-nowrap'>{dateStringFormater(stringDate)}</span>
      </Col>
    </Row>
  );
}

export default DateField;
