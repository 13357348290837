import React from 'react';
import { useQueryClient } from 'react-query';

import DetailsContact from '@/components/EntityModal/DetailsContact';
import { usePerson, useOnUpdate, useVoip } from '@/contexts/personModal';
import { personEditPath } from '@/routes';
import {
  peopleKeys,
  usePeopleCustomFields,
  useUpdatePerson
} from '@/api';
import { canEditPerson } from '@/policies';
import APIErrorMessage from '@/components/APIErrorMessage';
import Actions from '@/components/EntityModal/Person/Sidebar/Actions';
import PersonBasicData from '@/components/EntityModal/Person/Sidebar/PersonBasicData';
import EntityProducts from '@/components/EntityModal/EntityProducts';
import Skeleton from '@/components/Skeleton';

function SidebarContent({ person }) {
  const onUpdate = useOnUpdate();
  const entityEditPath = personEditPath(person.id);
  const queryClient = useQueryClient();
  const updatePersonMutation = useUpdatePerson();
  const voip = useVoip();

  const {
    data: { data: personCustomFields } = {},
    isLoadingPersonCustomFields
  } = usePeopleCustomFields({ config: { staleTime: 1000 } });

  const onRefreshModal = () => {
    queryClient.invalidateQueries(
      peopleKeys.detail(person.id)
    );
  };

  const onUpdatePerson = (params, done, personData) => {
    updatePersonMutation.mutate({ personId: personData.id, params }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='person' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Pessoa atualizada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        onRefreshModal();
        done?.();
      }
    });
  };

  const entityType = 'person';
  const entityCustomFields = personCustomFields || [];

  return (
    <>
      <Actions />

      <PersonBasicData
        person={person}
        onEditEntity={onUpdate}
        entityType={entityType}
        entityCustomFields={entityCustomFields}
      />

      <DetailsContact
        onEditEntity={onUpdate}
        entity={person}
        entityType={entityType}
        entityTypeModal={entityType}
        entityEditPath={entityEditPath}
        entityCustomFields={entityCustomFields}
        canEditPolicy={canEditPerson}
        loading={isLoadingPersonCustomFields}
        onCreatePerson={onRefreshModal}
        onEditPerson={onUpdatePerson}
        showTitle={false}
        voip={voip}
      />
      <EntityProducts
        entity={person}
        canEditPolicy={canEditPerson}
        updateEntity={onUpdate}
        entityType='person'
      />
    </>
  );
}

function Sidebar() {
  const person = usePerson();

  if (!person) {
    return (
      <>
        <Skeleton width='100%' height={150} className='mb-3' />
        <Skeleton width='100%' height={300} className='mb-3' />
        <Skeleton width='100%' height={300} className='mb-3' />
      </>
    );
  }

  return (<SidebarContent person={person} />);
}

export default Sidebar;
