import React, { useState, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { format, isPast, parse } from 'date-fns';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Col, Row, Nav } from 'react-bootstrap';
import compact from 'lodash/compact';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { useStoredState } from '@/hooks';
import strings from '@/strings';
import { ACTIVITY_TYPES, filterForImages, filterForDocuments } from '@/utils';
import { canCreateActivity, canUpdateActivity } from '@/policies';
import { DATE_FORMAT, FULL_TIME_FORMAT, TIME_FORMAT, toUTCISOString } from '@/date';
import BaseForm from '@/components/Form';
import { TextArea, UserSelect, MaskedInput } from '@/components/Inputs';
import Button from '@/components/Button';
import IconLabel from '@/components/IconLabel';
import Authorization from '@/components/Authorization';
import LoadingButton from '@/components/LoadingButton';
import FormField from '@/components/FormField';
import DatePickerInput from '@/components/Inputs/DatePickerInput';
import Collapse from '@/components/Collapse';
import Icon from '@/components/Icon';
import AttachmentUpload from '@/components/EntityModal/Activities/AttachmentUpload';
import ActivitiesTemplatesDropdown
  from '@/components/EntityModal/Activities/ActivitiesTemplatesDropdown';
import DealActivitiesBanner from '@/components/EntityModal/Deal/Activities/DealActivitiesBanner';

Form.propTypes = {
  activityId: PropTypes.number,
  entityId: PropTypes.number,
  isLoading: PropTypes.bool,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onClose: PropTypes.func,
  initialValues: PropTypes.object,
  suggestedValues: PropTypes.object,
  textAreaRef: PropTypes.any
};

const ANIMATION_SPEED = 150;
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${FULL_TIME_FORMAT}`;
const END_OF_DAY_HOUR = '23:59:59';

const ACTIVITY_TYPE_LABELS = Object.values(strings.models.activity.type);

const generateDate = (dateString, timeString) => {
  let date = parse(`${dateString} ${END_OF_DAY_HOUR}`, DATE_TIME_FORMAT, new Date());

  if (dateString && timeString) {
    date = parse(`${dateString} ${timeString}:00`, DATE_TIME_FORMAT, new Date());
  }

  if (!dateString && timeString) {
    const todayDateString = format(new Date(), DATE_FORMAT);
    date = parse(`${todayDateString} ${timeString}:00`, DATE_TIME_FORMAT, new Date());
  }

  if (!dateString && !timeString) {
    const todayDateString = format(new Date(), DATE_FORMAT);
    date = parse(`${todayDateString} ${END_OF_DAY_HOUR}`, DATE_TIME_FORMAT, new Date());
  }
  return toUTCISOString(date);
};

const isDateRetroactive = (dateString, timeString) => {
  const validTimeString = timeString ? `${timeString}:00` : END_OF_DAY_HOUR;
  const date = parse(`${dateString} ${validTimeString}`, DATE_TIME_FORMAT, new Date());

  return isPast(date);
};

const defaultValues = {
  text: '',
  assignedUserIds: [],
  date: format(new Date(), `${DATE_FORMAT}`),
  time: format(new Date(), `${TIME_FORMAT}`),
  attachments: []
};

function Form({
  entityId, entityName, activityId, isLoading, textAreaRef,
  initialValues, onCreate, onUpdate, onClose, suggestedValues
}) {
  const { user } = useAuth();
  const tracker = useTracking();
  const isEditMode = Boolean(activityId);

  const formKey = entityId
    ? `${entityName}-${entityId}-activity${isEditMode ? `-${activityId}` : ''}`
    : '';
  const isDeal = entityName === 'deal';

  const { store, remove: clearStore, initialValue: storedValue } = useStoredState(formKey, null);

  const initialNavType = suggestedValues?.type || storedValue?.type || ACTIVITY_TYPES.TASK;
  const [navType, setNavType] = useState(initialNavType);

  const isTask = navType === ACTIVITY_TYPES.TASK;
  const isNote = navType === ACTIVITY_TYPES.NOTE;
  const initialInputTouched = !(isTask || isNote);

  const [disableAction, setDisableAction] = useState(false);
  const [isDefaultInputTouched, setIsDefaultInputTouched] = useState(initialInputTouched);
  const [clearFiles, setClearFiles] = useState(false);
  const [showForm, setShowForm] = useState(isEditMode);
  const [showBanner, setShowBanner] = useState(true);
  const [animatedText, setAnimatedText] = useState(null);

  defaultValues.text = isTask ? '' : strings.models.activity.type[navType];
  defaultValues.assignedUserIds = compact([user?.id]);
  defaultValues.date = format(new Date(), `${DATE_FORMAT}`);
  defaultValues.time = format(new Date(), `${TIME_FORMAT}`);

  const DUE_DATE_LENGTH = 10;
  const schema = Yup.object().shape({
    text: Yup.string()
      .when('$isDefaultInputTouched', ([defaultInputTouched], fieldSchema) => (
        !defaultInputTouched ? fieldSchema : fieldSchema.required('Descrição é obrigatória.')
      )),
    date: isNote
      ? Yup.string()
      : Yup.string()
        .length(DUE_DATE_LENGTH, 'A data deve seguir o formato dd/mm/aaaa. Ex: 20/07/2024')
        .required('Prazo é obrigatório.'),
    time: Yup.string().nullable(),
    assignedUserIds: isNote
      ? Yup.array().nullable()
      : Yup.array(Yup.number())
        .nullable()
        .min(1, 'Selecione ao menos um responsável')
        .required('Selecione ao menos um responsável')
  });

  useEffect(() => {
    const isBannerClosed = localStorage.getItem('isBannerClosed');
    if (isBannerClosed) {
      setShowBanner(false);
    }
  }, []);

  const onSubmitForm = (formData, { resetForm, setSubmitting }) => {
    const { date, time, text, assignedUserIds, attachments } = formData;
    setDisableAction(true);
    const newDate = generateDate(date, time);
    let finishedAt = isDateRetroactive(date, time) ? newDate : null;

    if (isEditMode) {
      finishedAt = initialValues?.finishedAt;
    }

    const data = {
      text,
      type: navType,
      assigned_user_ids: assignedUserIds || [],
      finished_at: finishedAt,
      due_at: newDate,
      entity_id: entityId,
      entity: entityName,
      images: filterForImages(attachments),
      documents: filterForDocuments(attachments)
    };

    if (isNote) {
      data.due_at = null;
      data.finished_at = null;
      data.assigned_user_ids = [];
    }

    const afterSubmitCallback = (err) => {
      if (!err) {
        const trackParams = {
          user,
          entity: {
            type: entityName,
            isTask,
            isNote,
            activityType: strings.models.activity.type[navType],
            allDay: Boolean(time || time === END_OF_DAY_HOUR),
            isFinished: Boolean(data.finished_at)
          }
        };
        if (isEditMode) {
          tracker.trackCommentChanged(trackParams);
        } else {
          resetForm(defaultValues);
          setNavType(ACTIVITY_TYPES.TASK);
          tracker.trackCommentAdded(trackParams);
        }

        setClearFiles(true);
        setShowForm(false);
        setIsDefaultInputTouched(false);
        setSubmitting(false);
        setDisableAction(false);
        clearStore();
        onClose?.(true);
        return true;
      }
      setSubmitting(false);
      setDisableAction(false);
    };

    if (isEditMode) {
      onUpdate(activityId, data, afterSubmitCallback);
    } else {
      onCreate(data, afterSubmitCallback);
    }
  };

  const handleTextAreaClick = () => {
    setShowForm(true);
    if (isTask) {
      setIsDefaultInputTouched(true);
    }
  };

  function isDefaultDescription(text) {
    return !text || ACTIVITY_TYPE_LABELS.includes(text);
  }

  const storeFormValue = (value) => {
    if (isDefaultDescription(value?.text)) {
      clearStore();
    } else {
      store(value);
    }
  };

  useEffect(() => {
    if (!disableAction) {
      setNavType(initialValues?.type || initialNavType);
    }

    if (suggestedValues?.text) {
      let index = 0;
      const wordsInterval = suggestedValues?.text?.split(' ');

      setAnimatedText('');

      const interval = setInterval(() => {
        if (index < wordsInterval.length) {
          setAnimatedText((prevText) => `${prevText || ''}${wordsInterval[index]} `);
          index = index + 1;
        } else {
          clearInterval(interval);
        }
      }, ANIMATION_SPEED);

      return () => clearInterval(interval);
    }
  }, [initialValues, suggestedValues]);

  const suggestedAnimated = () => ({
    text: animatedText,
    assignedUserIds: compact([user?.id]),
    date: suggestedValues?.date ?? format(new Date(), DATE_FORMAT),
    time: suggestedValues?.time ?? null
  });

  const formValues = useMemo(() => (
    suggestedValues ? suggestedAnimated() : storedValue || defaultValues
  ), [suggestedValues, animatedText]);

  const handleBannerClose = () => {
    localStorage.setItem('isBannerClosed', 'true');
    setShowBanner(false);
  };

  const banners = {
    [ACTIVITY_TYPES.PROPOSAL]: {
      message: 'Gere suas propostas nativamente com o Agendor.',
      buttonText: 'Experimentar',
      buttonAction: () => document.querySelector('#proposal-modal').click()
    },
    [ACTIVITY_TYPES.CALL]: {
      message: 'Faça ligações nativamente com o Agendor.',
      buttonText: 'Experimentar',
      buttonAction: () => document.querySelector('#voip-button').click()
    },
    [ACTIVITY_TYPES.EMAIL]: {
      message: 'Envie e-mails nativamente com o Agendor.',
      buttonText: 'Experimentar',
      buttonAction: () => document.querySelector('#create-email-modal').click()
    }
  };

  return (
    <Collapse in={!isLoading}>
      <div>
        <BaseForm
          name={formKey}
          className={classnames(
            'mb-6 bg-light rounded',
            { 'edit-activity-form': isEditMode },
            { 'activity-form': !isEditMode }
          )}
          /*
           * Misteriosamente initialValues não esta preenchendo dados de edicao
           * Por isso foi necessario reusar defaultValues no update
           */
          defaultValues={isEditMode ? initialValues : formValues}
          onSubmit={onSubmitForm}
          validationSchema={schema}
          validationContext={{
            isDefaultInputTouched
          }}
        >
          {({ handleBlur, handleSubmit, setTouched, handleChange,
            setFieldValue, isSubmitting, values, resetForm }) => {
            const isPastDate = isDateRetroactive(values?.date, values?.time);
            const canSubmitActivity = isEditMode ? canUpdateActivity : canCreateActivity;

            const getSaveButtonLabel = () => {
              if (isEditMode) {
                return 'Salvar alterações';
              }
              if (isNote) {
                return 'Salvar nota';
              }
              if (isPastDate) {
                return 'Salvar';
              }
              if (!isPastDate) {
                return 'Agendar atividade';
              }
              return 'Salvar alterações';
            };

            const handleCancel = () => {
              setIsDefaultInputTouched(false);
              setClearFiles(true);
              setShowForm(false);
              setNavType(ACTIVITY_TYPES.TASK);
              clearStore();
              resetForm();
              onClose?.(false);
              setTouched({}, false);
            };

            /*
             * O evento onBlur do campo 'text' foi tratado para evitar que o Formik validasse
             * quando o campo perdesse o foco no botão 'Cancelar' e '#Nav-item'.
             * Isso foi necessário para evitar que a validação ocorresse antes do clique no botão.
             */
            const onBlur = (event) => {
              storeFormValue({ ...values, type: navType });
              const targetId = event?.relatedTarget?.id;
              const isButtonCancel = targetId === 'button-cancel';
              const isNavItem = targetId === 'nav-item';

              if (isNavItem) {
                return;
              }

              if (isButtonCancel) {
                return handleCancel();
              }

              return handleBlur(event);
            };

            const handleNavTypeSelect = (selectedNavType) => {
              setTouched({}, false);
              setNavType(selectedNavType);
              setShowForm(true);
              const isSameNavType = navType === selectedNavType;
              if (isDefaultDescription(values?.text)) {
                values.text = strings.models.activity.type[selectedNavType];
              }
              storeFormValue({ ...values, type: selectedNavType });

              if (isSameNavType) {
                setNavType(ACTIVITY_TYPES.TASK);
                setIsDefaultInputTouched(true);
                storeFormValue({ ...values, type: ACTIVITY_TYPES.TASK });
              }
            };

            return <>
              <div>
                <div className={classnames({ 'px-3': isEditMode })}>
                  <Nav
                    defaultActiveKey={ACTIVITY_TYPES.TASK}
                    activeKey={navType}
                    onSelect={handleNavTypeSelect}
                    className={classnames(
                      'nav-fill',
                      { 'border': isEditMode },
                      { 'border-medium-gray': isEditMode },
                      { 'rounded': isEditMode }
                    )}
                  >
                    {Object.values(ACTIVITY_TYPES)
                      .filter((activityType) => activityType !== ACTIVITY_TYPES.TASK)
                      .map((activityType) => (
                        <Nav.Item key={activityType}>
                          <Nav.Link
                            id='nav-item'
                            className={classnames(
                              { 'p-0': isEditMode },
                              { 'px-1': !isEditMode },
                              { 'rounded-top-left': !isEditMode }
                            )}
                            eventKey={activityType}
                          >
                            <IconLabel
                              textSize='h5'
                              icon={{ name: activityType }}
                              text={strings.models.activity.type[activityType]}
                              className={classnames(
                                'fw-bold py-2 justify-content-center',
                                { 'text-darker-gray': isEditMode },
                                { 'text-dark-gray': !isEditMode }
                              )}
                            />
                          </Nav.Link>
                        </Nav.Item>
                      ))
                    }
                  </Nav>
                </div>
              </div>

              <hr className='m-0' />

              <div className={classnames(
                'd-flex flex-column rounded-bottom p-3'
              )}>
                <Row>
                  <Col sm={12}>
                    {
                      isDeal && (showBanner && banners[navType])
                        ? (
                          <DealActivitiesBanner
                            message={banners[navType].message}
                            buttonText={banners[navType].buttonText}
                            buttonAction={banners[navType].buttonAction}
                            onClose={handleBannerClose}
                          />
                        )
                        : null
                    }
                    <FormField
                      name='text'
                      ref={textAreaRef}
                      as={TextArea}
                      maxRows={10}
                      onBlur={onBlur}
                      onFocus={handleTextAreaClick}
                      className='m-0'
                      variant='white'
                      placeholder='O que foi feito e qual o próximo passo?'
                      aria-label='Descrição da atividade'
                      toolbar={
                        <ActivitiesTemplatesDropdown
                          navType={navType}
                          entityName={entityName}
                        />
                      }
                    />
                  </Col>
                </Row>

                <Collapse in={showForm || isDefaultInputTouched}>
                  <div>
                    {
                      !isNote
                        ? (
                          <Row className='mt-3'>
                            <Col sm={6}>
                              <FormField
                                name='assignedUserIds'
                                as={UserSelect}
                                className='mt-1'
                                label='Responsáveis'
                                placeholder='Selecione'
                                variant='white'
                                onBlur={onBlur}
                                multiple
                                autoAdjustValues
                              />
                            </Col>
                            <Col sm={3}>
                              <FormField
                                name='date'
                                as={DatePickerInput}
                                actionButtons={['today', 'tomorrow', 'nextWeek']}
                                label='Prazo'
                                placeholder='00/00/0000'
                                variant='white'
                                className='mt-1'
                                onBlur={onBlur}
                                onChange={
                                  (e) => {
                                    storeFormValue({ ...values, type: navType });
                                    handleChange(e);
                                  }
                                }
                              />
                            </Col>
                            <Col sm={3}>
                              <FormField
                                name='time'
                                as={MaskedInput}
                                maskType='time'
                                rightAdornment={() => (
                                  values.time
                                    ? (
                                      <Button
                                        onClick={() => setFieldValue('time', '')}
                                        size='sm'
                                        variant='link'
                                        className={classnames(
                                          'position-absolute',
                                          'top-50',
                                          'end-0',
                                          'translate-middle-y',
                                          'rounded',
                                          'p-2',
                                          'border-0'
                                        )}
                                        aria-label='Limpar'
                                      >
                                        <Icon name='close' />
                                      </Button>
                                    )
                                    : null
                                )}
                                variant='white'
                                label='Horário'
                                placeholder='00:00'
                                className='mt-1'
                                onBlur={onBlur}
                                onChange={
                                  (e) => {
                                    storeFormValue({ ...values, type: navType });
                                    handleChange(e);
                                  }
                                }
                              />
                            </Col>
                          </Row>
                        )
                        : null
                    }
                    <div className='mt-4'>
                      <div className='position-relative w-50 me-auto'>
                        {
                          <FormField
                            as={AttachmentUpload}
                            name='attachments'
                            isEditMode={isEditMode}
                            clearFiles={clearFiles}
                            disabled={disableAction || isLoading}
                            onClearFiles={() => setClearFiles(false)}
                            onUploadStart={() => setDisableAction(true)}
                            onUploadFinish={() => setDisableAction(false)}
                            onUploadError={() => setDisableAction(false)}
                          />
                        }

                      </div>
                      <div className={
                        classnames('d-flex justify-content-end mt-n9')
                      }>
                        <Button
                          id='button-cancel'
                          size='sm'
                          variant='link'
                          className='me-3'
                          disabled={disableAction || isLoading}
                          onClick={handleCancel}
                        >
                          Cancelar
                        </Button>

                        <Authorization policy={canSubmitActivity}>
                          <LoadingButton
                            isLoading={isSubmitting}
                            disabled={disableAction || isLoading}
                            onClick={handleSubmit}
                          >
                            {getSaveButtonLabel()}
                          </LoadingButton>
                        </Authorization>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            </>;
          }}
        </BaseForm>
      </div>
    </Collapse>
  );
}

export default Form;
