import React, { useEffect, useMemo, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useAlert } from 'react-alert';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getValidationErrors, isFeedbackRecent, isOptin } from '@/utils';
import APIErrorMessage from '@/components/APIErrorMessage';
import { useDocumentTitle } from '@/hooks';
import { goBack, setLegacyAlertMessage, redirect } from '@/browser';
import { dealPath, dealsPath, legacyDealUrl, legacyNewFormsOptoutUrl } from '@/routes';
import Main from '@/components/Main';
import NotFound from '@/pages/not-found';
import { isNotFoundError } from '@/errors';
import HookFullDealForm from '@/components/HookFullDealForm';
import { useAuth } from '@/lib/auth';
import { formatUTCDate } from '@/date';
import OptoutButton from '@/components/OptoutButton';
import { useTracking } from '@/lib/tracking';
import ConfirmationModal from '@/components/ConfirmationModal';
import { getInitialValues as getPrivacyInitialValues } from '@/components/Inputs/PrivacyInput';
import { getInitialValues as getCustomFieldsInitialValues } from '@/components/CustomFields';
import { useDeal, useUpdateDeal, useDeleteDeal, useDealsCustomFields } from '@/api';
import { getInitialValues as getProductsInitialValues } from '@/components/Inputs/ProductsFields';
import { getCustomer } from '@/components/Inputs/CustomerSelect';
import BackToLegacyModal from '@/components/EntityModal/BackToLegacyModal';

const PAGE_TITLE = 'Alterar negócio';

function DealEdit() {
  useDocumentTitle(PAGE_TITLE);

  const { user } = useAuth();
  const { account } = user;

  const alert = useAlert();
  const tracker = useTracking();
  const { dealId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showLegacyModal, setShowLegacyModal] = useState(false);
  const updateDealMutation = useUpdateDeal();
  const deleteDealMutation = useDeleteDeal();
  const { data: deal, error, isSuccess } = useDeal({ dealId });
  const { data: customFields, isSuccess: loadedCustomFields } = useDealsCustomFields();

  useEffect(() => {
    tracker.trackNewEditDealFormViewed({ user });
  }, [user]);

  const initialValues = useMemo(() => (
    deal ? getDealInitialValues(deal.data, { funnelId: account.defaultFunnel.id }) : {}
  ), [deal, account]);

  const handleSubmit = (formData, { setFieldError }) => (
    updateDealMutation.mutateAsync({ dealId, params: formData }, {
      onError: (err) => {
        const errors = getValidationErrors(err, 'deal');
        Object.entries(errors).forEach(([key, value]) => {
          setFieldError(key, value);
        });

        alert.show(
          <APIErrorMessage err={err} resource='deal' action='update' />,
          { variant: 'danger' }
        );
      },
      onSuccess: ({ data }) => {
        setLegacyAlertMessage('Negócio alterado com sucesso!', 'success');
        alert.show(
          'Negócio alterado com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        if (formData.funnel_id !== initialValues.funnel_id) {
          tracker.trackDealFunnelChanged({ user, page: 'Novo formulario', entity: 'Negocio' });
        }

        const base = location.state?.previous?.pathname ?? '/deals';
        if (user.features.blockLegacyEnabled || isOptin(user?.features?.dealModalEnabled)) {
          navigate(dealPath(data.id, base));
        } else {
          redirect(legacyDealUrl(data.id));
        }
      }
    })
  );

  const onCancel = () => goBack();

  const onClickDelete = () => setShowDeleteConfirm(true);
  const onCancelDelete = () => setShowDeleteConfirm(false);
  const onConfirmDelete = () => {
    deleteDealMutation.mutate({ dealId }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='deal' action='delete' />,
          { variant: 'danger' }
        );
      },
      onSuccess: () => {
        alert.show(
          'Negócio removido com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        navigate(dealsPath());
      }
    });
  };

  const handleOpenModal = () => {
    const screen = 'Formulario';
    const feedbackGivenRecently = isFeedbackRecent(screen);
    tracker.trackBackToLegacy({
      user, page: 'Formulario', entity: 'Negocio', feedback: !feedbackGivenRecently
    });
    if (feedbackGivenRecently) {
      const redirectUrl = legacyNewFormsOptoutUrl({ type: 'deal', id: dealId });
      window.location.href = redirectUrl;
    } else {
      setShowLegacyModal(true);
    }
  };

  const onCreateCustomer = ({ type }) => {
    if (type === 'organization') {
      tracker.trackOrganizationCreated({ quickAdded: true, source: 'newForm', user });
    } else {
      tracker.trackPersonCreated({ quickAdded: true, source: 'newForm', user });
    }
  };

  if (error && isNotFoundError(error)) {
    return <NotFound />;
  }

  if (!isSuccess || !loadedCustomFields) {
    return <div className='flex-grow-1 bg-light' />;
  }

  return (
    <Main fluid flex>
      <div className='py-6 border-bottom'>
        <Container>
          <Row>
            <Col
              md={{ offset: 1, span: 10 }}
              className='d-flex align-items-center justify-content-between'
            >
              <h1>{PAGE_TITLE}</h1>

              <OptoutButton
                from='newForms'
                entity={{ type: 'deal', id: dealId }}
                onClick={handleOpenModal}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <BackToLegacyModal
        show={showLegacyModal}
        onHide={() => setShowLegacyModal(false)}
        variant='newForms'
        id={deal?.id}
        entity={{ type: 'deal', id: dealId }}
        entityType={'deal'}
      />

      <HookFullDealForm
        action='edit'
        initialValues={initialValues}
        customFields={customFields.data ?? []}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        onDelete={onClickDelete}
        onCreateCustomer={onCreateCustomer}
      />

      <ConfirmationModal
        confirmationText='Excluir'
        show={showDeleteConfirm}
        onConfirm={onConfirmDelete}
        onHide={onCancelDelete}
        disabled={deleteDealMutation.isLoading}
      >
        <ConfirmationModal.Title>
          Deseja excluir este negócio?
        </ConfirmationModal.Title>

        <ConfirmationModal.Info>
          Todo o histórico de relacionamento será perdido.
        </ConfirmationModal.Info>
      </ConfirmationModal>
    </Main>
  );
}

function getDealInitialValues(deal, defaultValues) {
  return {
    title: deal.title,
    value: deal.value?.toString() ?? '',
    description: deal.description ?? '',
    started_at: deal.startedAt ? formatUTCDate(deal.startedAt) : '',
    finished_at: deal.finishedAt ? formatUTCDate(deal.finishedAt) : '',
    funnel_id: deal.funnel?.id ?? defaultValues.funnelId,
    owner_user_id: deal.ownerUser?.id ?? '',
    entity_products: getProductsInitialValues(deal.entityProducts),
    customer: getCustomer(deal.organization, deal.person),
    custom_fields: getCustomFieldsInitialValues(deal.customFields),
    ...getPrivacyInitialValues(deal),
    deal_status: deal.dealStatus
  };
}

export default DealEdit;
