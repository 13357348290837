import React from 'react';

import { useScreenSize } from '@/hooks';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import { Select } from '@/components/Inputs';

const OPTIONS = [
  {
    label: 'da empresa apenas',
    value: 'organization'
  },
  {
    label: 'da empresa e pessoas relacionados',
    value: 'organization_people'
  },
  {
    label: 'da empresa, pessoas e negócios relacionados',
    value: 'organization_people_deals'
  }
];

function getSelectedFilterMessage(mode) {
  const selectedOptionLabel = OPTIONS.find((option) => option.value === mode).label;

  return `Mostrando atividades ${selectedOptionLabel}`;
}

function ActivityEntityFilter({ mode, onChange }) {
  const { isMD } = useScreenSize();

  if (isMD) {
    return (
      <div className='flex-fill d-flex align-items-center'>
        <Tooltip
          content={getSelectedFilterMessage(mode)}
          placement='left'
        >
          <div
            className={
              'd-flex align-items-center bg-white rounded px-3 py-1 w-20 ms-auto'
            }
          >
            <Icon name='info' size='sm' />
          </div>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className='flex-fill d-flex justify-content-end'>
      <div className='d-flex align-items-center'>
        <b className='text-dark-gray me-2'>Mostrar atividades:</b>

        <div style={{ width: 230 }}>
          <Select
            label=''
            name='mode'
            value={mode}
            onChange={onChange}
            className='w-100'
            isSearchable={false}
            options={OPTIONS}
            styles={{
              menuPortal: (menuPortalProps) => ({
                ...menuPortalProps,
                zIndex: 1075,
                maxWidth: 400,
                width: '100%'
              })
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ActivityEntityFilter;
