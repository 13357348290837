import React from 'react';
import PropTypes from 'prop-types';
import Select from '@/components/Inputs/Select';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  entity: PropTypes.oneOf(['organization', 'person', 'deal']).isRequired
};

const defaultProps = {
  name: 'section',
  label: 'Seção do formulário',
  placeholder: 'Selecione uma seção do formulário',
  multiple: false,
  isClearable: false,
  isSearchable: false,
  defaultValue: null,
  onChange: () => {}
};

const options = [
  {
    label: 'Dados básicos',
    value: 'basic_data'
  },
  {
    label: 'Informações para contato',
    value: 'contact'
  },
  {
    label: 'Dados do endereço',
    value: 'address'
  }
];

const entityOptions = {
  organization: options,
  person: options,
  deal: options.slice(0, 1)
};

function CustomFieldSectionSelect(props) {
  const { entity, ...otherProps } = props;

  return (
    <Select
      { ...otherProps }
      options={entityOptions[entity]}
    />
  );
}

CustomFieldSectionSelect.propTypes = propTypes;
CustomFieldSectionSelect.defaultProps = defaultProps;

export default CustomFieldSectionSelect;
