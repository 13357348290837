import React from 'react';
import PropTypes from 'prop-types';
import sum from 'lodash/sum';
import clone from 'lodash/clone';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Icon from '@/components/Icon';
import { EditInPlace, TextInput, HookProductsFields } from '@/components/Inputs';
import { toCurrencyString, toPercentageString, calculatePercentage } from '@/number';

ProposalProducts.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  layoutMode: PropTypes.bool
};

ProposalProducts.defaultProps = {
  title: 'Produtos e serviços',
  items: [],
  layoutMode: false
};

export function ProposalProducts({ title, items, layoutMode }) {
  if (!items?.length && !layoutMode) {
    return null;
  }

  const nonRemovedProducts = items.filter((item) => !item._destroy);
  const valueWithoutDiscount = sum(nonRemovedProducts.map((item) => Number(item.subtotal)));
  const valueWithDiscount = sum(nonRemovedProducts.map((item) => Number(item.total_value)));

  const calculateDiscount = (subtotal, totalValue) => Number(subtotal) - Number(totalValue);
  const discountAbsolute = calculateDiscount(valueWithoutDiscount, valueWithDiscount);
  const discountPercentage = calculatePercentage(discountAbsolute, valueWithoutDiscount);

  const hasDiscount = discountAbsolute > 0;

  const itemWidth = hasDiscount ? '25%' : '30%';
  const unitPriceWidth = hasDiscount ? '20%' : '25%';
  const quantityWidth = hasDiscount ? '15%' : '20%';
  const discountWidth = '15%';
  const totalWidth = '25%';

  return (
    <div className='d-block w-100 mx-0 my-4 text-darker-gray'>
      <hr className='m-4 proposal-hr' />
      <h2 className='fw-bold my-4 px-4'>{title ?? 'Produtos e serviços'}</h2>
      {
        layoutMode
          ? (
            <div className='d-block py-4 px-6 mx-n4' style={{ backgroundColor: '#f0f0f7' }}>
              <div className='d-block py-2 rounded-1 text-dark-gray'>
                <span className='text-dark-gray me-2 proposal-icon-info proposal-md' />
                <span>
                  Os produtos serão preenchidos automaticamente com base nos produtos do negócio.
                </span>
              </div>
            </div>
          )
          : (
            <>
              <div className='d-block p-4 mx-0' style={{ backgroundColor: '#f0f0f7' }}>
                <div className='d-inline-block' style={{ width: itemWidth }}>
                  Item
                </div>
                <div className='d-inline-block' style={{ width: unitPriceWidth }}>
                  Preço unitário
                </div>
                <div className='d-inline-block' style={{ width: quantityWidth }}>
                  Quantidade
                </div>
                {
                  hasDiscount
                    ? (
                      <div className='d-inline-block' style={{ width: discountWidth }}>
                        Desconto
                      </div>
                    )
                    : null
                }
                <div className='d-inline-block' style={{ width: totalWidth }}>Total</div>
              </div>
              {items?.map((product, index) => (
                <div
                  key={`${product?.product_id}_${index}`}
                  className='p-4 mx-0 d-block proposal-products-border'
                >
                  <div className='d-inline-block' style={{ width: itemWidth }}>
                    {product?.name || product?.initialProduct?.name}
                  </div>
                  <div className='d-inline-block' style={{ width: unitPriceWidth }}>
                    {toCurrencyString(Number(product?.unit_value))}
                  </div>
                  <div className='d-inline-block' style={{ width: quantityWidth }}>
                    {product?.quantity}
                  </div>
                  {
                    hasDiscount
                      ? (
                        <div className='d-inline-block' style={{ width: discountWidth }}>
                          {toPercentageString(product?.discount_percentage)}
                        </div>
                      )
                      : null
                  }
                  <div className='d-inline-block' style={{ width: totalWidth }}>
                    {toCurrencyString(Number(product?.total_value))}
                  </div>
                </div>
              ))}
              <table style={{
                width: '100%',
                marginTop: '2rem',
                marginBottom: '1rem',
                borderCollapse: 'collapse',
                border: 'none',
                outline: 'none'
              }}
              >
                <tbody style={{ border: 'none', outline: 'none' }}>
                  <tr style={{ border: 'none', outline: 'none' }}>
                    <td style={{ width: '74%', border: 'none', outline: 'none' }}></td>
                    <td style={{
                      textAlign: 'left',
                      width: '26%',
                      border: 'none',
                      outline: 'none'
                    }}
                    >
                      <div>
                        <strong>Resumo</strong>
                      </div>
                      <div>
                        <span>Valor - </span>
                        <span>{toCurrencyString(valueWithoutDiscount)}</span>
                      </div>
                      {
                        hasDiscount
                          ? (
                            <div>
                              <span>
                                Valor desconto ({toPercentageString(discountPercentage)}) -
                              </span>
                              <span>{toCurrencyString(discountAbsolute)}</span>
                            </div>
                          )
                          : null
                      }
                      <div>
                        <strong>Valor total - </strong>
                        <strong>{toCurrencyString(valueWithDiscount)}</strong>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )
      }
    </div>
  );
}

ProposalProductsInput.propTypes = {
  title: PropTypes.string,
  layoutMode: PropTypes.bool,
  onChangeProducts: PropTypes.func,
  actions: PropTypes.any,
  onChangeTitle: PropTypes.func
};

ProposalProductsInput.defaultProps = {
  title: 'Produtos e serviços',
  layoutMode: false,
  onChangeProducts: () => { },
  onChangeTitle: () => { },
  actions: null
};

export function ProposalProductsInput({
  title, layoutMode, onChangeProducts, onChangeTitle, actions
}) {
  return (
    <Row className='mx-6'>
      <Col>
        <Card className='border-light p-4 pe-2'>
          <div className='d-flex'>
            <EditInPlace
              className='width-5'
              name='title'
              hideEdit={!layoutMode}
              as={TextInput}
              value={title}
              placeholder='Dê um título para a seção'
              onConfirm={(params, done) => {
                onChangeTitle(params?.title);
                done();
              }}
            >
              <h2 className='text-medium fw-bold d-block'>
                {title ?? 'Produtos e serviços'}
              </h2>
            </EditInPlace>
            <div className='d-flex align-items-end justify-content-end flex-grow-1 pe-2'>
              {actions}
            </div>
          </div>
          <span className='text-dark-gray my-2'>
            Adicione produtos ou serviços com valor e quantidade na sua oportunidade de venda.
          </span>
          {
            layoutMode
              ? (
                <div>
                  <Button
                    variant='link'
                    size='sm'
                    className='px-0 fw-bold text-light-blue'
                    disabled
                  >
                    <Icon name='plus' size='sm' className='me-1' />
                    <span>Adicionar</span>
                  </Button>
                  <div className='py-2 px-4 bg-light rounded-1 text-small text-dark-gray'>
                    <span className='text-dark-gray me-2 proposal-icon-info' />
                    {/* <Icon name='info' className='' size='sm' /> */}
                    Não é possível deixar os produtos pré-definidos aqui,
                    eles serão preenchidos automaticamente com os produtos do negócio.
                  </div>
                </div>
              )
              : (
                <div className='d-flex my-2 max-height-6 overflow-y-scroll'>
                  <HookProductsFields onChange={(products) => onChangeProducts(clone(products))} />
                </div>
              )
          }
        </Card>
      </Col>
    </Row>
  );
}
