import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useInfinitePeople } from '@/api';
import Badge from '@/components/Badge';
import { pluralize } from '@/utils';
import { CustomerSortInput } from '@/components/Inputs';
import ButtonAddPeople from '@/components/EntityModal/AddPersonButton';
import PeopleTabTable from '@/components/EntityModal/Table/PeopleTabTable';

PeopleTab.propTypes = {
  organizationId: PropTypes.number.isRequired
};

function PeopleTab({ organizationId }) {
  const [searchParams, setSearchParams] = useState('created_at desc');

  const params = { organization_id_eq: organizationId, sorts: searchParams };

  const {
    data:
    people,
    isLoading: isLoadingDeals,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfinitePeople({
    config: {
      staleTime: 1000
    },
    params
  });

  const peopleMemoized = useMemo(() => {
    const peoplePages = people?.pages ?? [];
    const peopleData = peoplePages.flatMap((page) => page.data);
    const peopleMetadata = peoplePages[0]?.meta ?? {};
    return { peopleData, peopleMetadata };
  }, [JSON.stringify(people)]);

  const { peopleData, peopleMetadata } = peopleMemoized;

  return (
    <div className='bg-white p-4 rounded-bottom'>
      <div className='d-flex justify-content-between mb-5'>
        <div className='d-flex align-items-center gap-3'>
          <h2 className='mb-0'>Pessoas</h2>
          <Badge
            variant='light'
            className='text-dark-gray fs-6 d-flex align-items-center'
          >
            {peopleMetadata.count} {pluralize('pessoa', peopleMetadata.count)}
          </Badge>
        </div>
        <div className='d-flex align-items-center gap-3'>
          <CustomerSortInput withoutRanking value={searchParams} onChange={setSearchParams} />
          <ButtonAddPeople organizationId={organizationId} />
        </div>
      </div>

      <PeopleTabTable
        organizationId={organizationId}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isLoading={isLoadingDeals}
        items={peopleData}
        itemsTotal={peopleMetadata.count}
      />
    </div>
  );
}

export default PeopleTab;
