import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import compact from 'lodash/compact';
import { Col, Row } from 'react-bootstrap';
import { EditInPlace } from '@/components/Inputs';
import Truncate from '@/components/Truncate';
import CustomFieldInput from '@/components/CustomFields/CustomFieldInput';
import { customFieldValue, isBlank } from '@/utils';
import TruncateLinkfied from '@/components/TruncateLinkfied';

DetailsCustomField.defaultProps = {
  identifier: '',
  type: '',
  customFieldOptions: [],
  name: '',
  rawValue: null,
  placeholder: 'Adicionar',
  onEdit: () => { }
};

DetailsCustomField.propTypes = {
  identifier: PropTypes.string,
  type: PropTypes.string,
  customFieldOptions: PropTypes.array,
  name: PropTypes.string,
  rawValue: PropTypes.any,
  placeholder: PropTypes.string,
  onEdit: PropTypes.func
};

const MIN_VALUE_SIZE = 20;
const SMALL_COL = 4;
const SMALL_COL_VALUE = 8;
const LARGE_COL = 12;
const MAX_TRUNCATE = 150;

const MAX_TYPE_LENGTH = {
  integer: 12,
  decimal: 16
};

function DetailsCustomField({
  identifier, type, customFieldOptions, name, rawValue, onEdit, placeholder
}) {
  const value = customFieldValue(rawValue, { type, customFieldOptions });
  const isSelect = ['select', 'multiselect'].includes(type);

  const options = useMemo(() => customFieldOptions?.map((item) => (
    { label: item.option, value: item.id }
  )) ?? [], [customFieldOptions]);

  const valueSize = value?.toString().length ?? 0;
  const isSmallValue = valueSize <= MIN_VALUE_SIZE;
  const isEmptyArray = isArray(rawValue) ? isEmpty(compact(rawValue)) : false;
  const isEmptyString = isString(rawValue) ? isEmpty(rawValue?.trim()) : false;
  const isEmptyValue = isEmptyArray || isEmptyString || isBlank(rawValue);

  return (
    <Row className='align-items-center mb-3'>
      <Col sm={SMALL_COL}>
        <Truncate className='fw-bold text-small'>
          {name}
        </Truncate>
      </Col>
      <Col sm={isSmallValue ? SMALL_COL_VALUE : LARGE_COL}>
        <EditInPlace
          isCustomField
          name={`custom_fields.${identifier}`}
          as={CustomFieldInput}
          value={rawValue}
          field={{ identifier, value: rawValue, type }}
          options={options}
          isSelect={isSelect}
          onConfirm={onEdit}
          maxLength={MAX_TYPE_LENGTH[type]}
          isClearable={type === 'select'}
        >
          {
            isEmptyValue
              ? <span className='text-medium-gray'>{placeholder}</span>
              : (
                <TruncateLinkfied
                  text={value}
                  maxLength={MAX_TRUNCATE}
                  className='text-break'
                />
              )
          }
        </EditInPlace>
      </Col>
    </Row>
  );
}

export default DetailsCustomField;
