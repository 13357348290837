import React, { useEffect } from 'react';
import fullPageGif from 'images/full-page-loading.gif';

function PaymentReturn() {
  /*
   * Após a conclusão do fluxo de autenticação 3D Secure, uma mensagem é enviada
   * para a janela pai indicando que a autenticação foi finalizada.
   * ref: https://docs.stripe.com/payments/3d-secure/authentication-flow
   */
  useEffect(() => {
    window.top.postMessage('3DS-authentication-complete', '*');
  }, []);

  return (
    <div className='d-flex align-items-center' style={{ minHeight: '100vh', width: '100%' }}>
      <div className='w-100 mx-auto text-center text-darker-gray mt-n4'>
        <img
          className='text-center width-4 height-4'
          src={fullPageGif}
        />
      </div>
    </div>
  );
}

export default PaymentReturn;
