import React, { useState } from 'react';

import { EditInPlace, MaskedInput } from '@/components/Inputs';
import CreateEmailModal from '@/components/Email/CreateEmailModal';

function EmailField({
  entityId,
  entityType,
  inputValue,
  inputName,
  onEditEntity
}) {
  const [showModal, setShowModal] = useState(false);
  const hasEmail = Boolean(inputValue);
  const emailTo = [inputValue].filter(Boolean);

  return (
    <>
      <EditInPlace
        name={inputName}
        label=''
        as={MaskedInput}
        value={inputValue}
        onConfirm={onEditEntity}
        maskType='email'
        disableLabelClick={hasEmail}
      >
        <div className='white-space-nowrap cursor-pointer'>
          {hasEmail
            ? <span onClick={() => setShowModal(true)}>{inputValue}</span>
            : <span className='text-medium-gray'>Adicionar email</span>}
        </div>
      </EditInPlace>

      <CreateEmailModal
        show={showModal}
        onClose={() => setShowModal(false)}
        entityId={entityId}
        entityType={entityType}
        to={emailTo}
      />
    </>
  );
}

export default EmailField;
