import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@/components/Avatar';
import Tooltip from '@/components/Tooltip';
import Authorization from '@/components/Authorization';
import Icon from '@/components/Icon';
import {
  EditInPlace,
  UserSelect
} from '@/components/Inputs';


SelectOwnerUser.defaultProps = {
  ownerUser: {},
  onUpdate: () => { },
  canEditPolicy: () => { }
};

SelectOwnerUser.propTypes = {
  ownerUser: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    avatarUrl: PropTypes.string
  }),
  onUpdate: PropTypes.func,
  canEditPolicy: PropTypes.func
};

function SelectOwnerUser({
  ownerUser,
  onUpdate,
  canEditPolicy
}) {
  return (
    <EditInPlace
      as={UserSelect}
      name='owner_user_id'
      label=''
      value={ownerUser?.id}
      tooltipString={`Responsável: ${ownerUser?.name}`}
      url={ownerUser?.avatarUrl}
      onConfirm={onUpdate}
      className='ms-2 mt-n2 width-5 min-width-3'
      policy={canEditPolicy}
      hideEditButtonWhenEmpty={Boolean(ownerUser)}
      isSelect
    >
      {ownerUser
        ? (
          <>
            <div className='d-flex'>
              <Avatar
                name={ownerUser?.name}
                tooltip={`Responsável: ${ownerUser?.name}`}
                url={ownerUser?.avatarUrl}
              />
              <span className='ms-1'>{ownerUser?.name}</span>
            </div>
          </>
        )
        : (
          <Authorization policy={canEditPolicy}>
            {
              (authProps) => (
                <Tooltip
                  content='Selecione um responsável'
                  hide={authProps?.disabled}>
                  <div className='text-primary'>
                    <Icon
                      name='plus'
                      className='rounded-circle bg-light-gray'
                    />
                  </div>
                </Tooltip>
              )
            }
          </Authorization>
        )}
    </EditInPlace>
  );
}

export default SelectOwnerUser;
