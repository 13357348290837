import React from 'react';
import PropTypes from 'prop-types';

import { parseDate, toTextualDateString } from '@/date';
import { useAuth } from '@/lib/auth';
import Icon from '@/components/Icon';
import { useIntercom } from 'react-use-intercom';

const propTypes = {
  currentPlanFullName: PropTypes.object.isRequired,
  selectedPlanFullName: PropTypes.string.isRequired
};

function PendingBankSlipWarning({ currentPlanFullName, selectedPlanFullName }) {
  const { user } = useAuth();
  const { lastBankSlipExpirationDate } = user.account;
  const { showNewMessages } = useIntercom();

  if (!lastBankSlipExpirationDate ||
    currentPlanFullName === selectedPlanFullName) {
    return null;
  }

  const expirationDateFormatted = toTextualDateString(parseDate(lastBankSlipExpirationDate));

  return (
    <div className='p-4 rounded mb-7 bg-light-yellow text-warning'>
      <div className='col d-flex align-items-center'>
        <div className='me-4 col-auto d-flex align-items-center'>
          <Icon name='info' size='sm' />
        </div>
        <div className='text-medium'>
          <p>
            <b>Você já tem um boleto em aberto!</b><br/> Você vai
            alterar seu plano atual <b>{currentPlanFullName}</b> para
            um <b>{selectedPlanFullName}</b>.
          </p>
          <p>
            <b>Desconsidere o boleto do plano anterior</b>, que venceria
            em <b>{expirationDateFormatted}</b>, para não pagar em duplicidade.
          </p>
            Se tiver dúvidas,{' '}
          <span className='cursor-pointer' onClick={() => showNewMessages()}>
            <u>entre em contato</u>
          </span>.
        </div>
      </div>
    </div>
  );
}

PendingBankSlipWarning.propTypes = propTypes;

export default PendingBankSlipWarning;
