import { useMutation, useQueryClient } from 'react-query';
import { post } from '@/lib/fetch';
import { dealsKeys } from '@/api';

export async function createProposalDeal(params = {}) {
  const { data } = await post('/proposal_deals', params);

  return data;
}

export function useCreateProposalDeal({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(dealsKeys.all);

  const meta = {
    ongoingChecklistKey: 'create-proposal'
  };

  return useMutation(
    createProposalDeal,
    {
      onSuccess,
      meta,
      ...config
    }
  );
}

