import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '@/lib/auth';
import { useCreateACContact, useCreateVoipStartCall } from '@/api';
import { settingsVoipPanelPath, helpUrl } from '@/routes';
import { ENTITIES_TRACKER_SCREEN, VOIP_MAIN_ERROR, ZENVIA_ERRORS, isOptin } from '@/utils';
import Popover from '@/components/Popover';
import Tooltip from '@/components/Tooltip';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import ExternalLink from '@/components/ExternalLink';
import ModalAgendorChat from '@/components/WhatsApp/ModalAgendorChat';
import { useTracking } from '@/lib/tracking';
import { useAlert } from 'react-alert';
import { useAgendorChatContext } from '@/contexts/agendor-chat';

const propTypes = {
  phone: PropTypes.string,
  entity: PropTypes.object,
  deal: PropTypes.object,
  voip: PropTypes.object
};

const defaultProps = {
  phone: '',
  entity: {},
  deal: {},
  voip: {}
};

function ContactPhoneOptions({ contactName, phone, entity, deal, voip, entityTypeModal }) {
  const [showACModal, setShowACModal] = useState(false);
  const [isLoadingAC, setIsLoadingAC] = useState(false);
  const [isLoadingVoip, setIsLoadingVoip] = useState(false);

  const { openAgendorChat } = useAgendorChatContext();

  const alert = useAlert();
  const { user } = useAuth();
  const isAdmin = user?.admin;

  const formattedPhone = phone?.startsWith('+55') ? phone : `+55${phone}`;
  const sanitizedPhone = formattedPhone.replace(/(?!^\55)\D+/gu, '');

  const acMutation = useCreateACContact();
  const voipMutation = useCreateVoipStartCall();

  const hasAccountChat = user?.account?.accountChat;
  const hasVoipEnabled = Boolean(voip?.enabled);
  const accountBalance = Number(voip?.balance || 0);
  const pricePerMinute = Number(voip?.pricePerMinute);
  const hasNoBalance = accountBalance < pricePerMinute;
  const tracker = useTracking();
  const isIframeAllowedAccount = isOptin(user?.features?.agendorChatIframeEnabled);

  const handleOpenWhatsApp = () => {
    tracker.trackWhatsappUsed({
      user,
      action: 'Whatsapp web',
      screen: ENTITIES_TRACKER_SCREEN[entityTypeModal],
      location: 'Widget dados do contato'
    });
    window.open(`https://web.whatsapp.com/send?phone=${sanitizedPhone}`, '_blank');
  };

  const handleOpenAgendorChat = () => {
    tracker.trackWhatsappUsed({
      user,
      action: 'Agendor chat',
      screen: ENTITIES_TRACKER_SCREEN[entityTypeModal],
      location: 'Widget dados do contato'
    });
    if (!hasAccountChat) {
      setShowACModal(true);
    } else {
      setIsLoadingAC(true);
      const params = {
        phone_number: `+${sanitizedPhone}`,
        name: contactName,
        entity: entityTypeModal,
        entity_id: entity.id
      };
      acMutation.mutate(params, {
        onSuccess: ({ url }) => {
          if (isIframeAllowedAccount) {
            openAgendorChat(url);
          } else {
            window.open(url, '_blank');
          }
          setIsLoadingAC(false);
        },
        onError: () => {
          alert.show(
            `Seu usuário não está vinculado a uma caixa de entrada
            ou configurado como agente no Agendor Chat. Verifique com o administrador.`,
            {
              variant: 'danger',
              timeout: 10000
            }
          );
          setIsLoadingAC(false);
        }
      });
    }
  };

  const handleStartCall = () => {
    if (!hasVoipEnabled) {
      window.location.href = `tel:${sanitizedPhone}`;
      return;
    }

    if (hasNoBalance) {
      return;
    }

    setIsLoadingVoip(true);

    function alertError(error) {
      alert.show(
        <div>
          <h6>Não foi possível realizar a ligação!</h6>
          <ul className='mb-0 pl-0'>
            <li>{error}</li>
          </ul>
        </div>,
        { variant: 'danger', timeout: 5000 }
      );
    }

    const entityId = entityTypeModal === 'deal' ? deal.id : entity.id;
    const params = {
      entity: entityTypeModal,
      entity_id: entityId,
      voip_to: sanitizedPhone.replace(/^(?:55|\+55)/gu, '')
    };

    voipMutation.mutate(params, {
      onError: () => {
        setIsLoadingVoip(false);
        alertError(VOIP_MAIN_ERROR);
      },
      onSuccess: (data) => {
        setIsLoadingVoip(false);
        if (data?.success) {
          tracker.trackVoipCallStarted({
            user,
            page: `${entityTypeModal}_modal`,
            component: 'phone_actions'
          });
          window.open(data.url, '_blank');
        } else {
          alertError(ZENVIA_ERRORS[`${data?.code}`] ?? VOIP_MAIN_ERROR);
        }
      }
    });
  };

  const callButton = (
    <>
      <LoadingButton
        variant='light'
        className='w-100 mb-2 me-2'
        onClick={handleStartCall}
        isLoading={isLoadingVoip}
        loadingText='Ligando...'
        aria-label='Fazer ligação'
        disabled={(hasVoipEnabled && hasNoBalance && !isAdmin)}
      >
        <Icon name='call' className='me-1 text-primary' />
        <span className='text-primary text-nowrap'>
          Fazer ligação
        </span>
      </LoadingButton>
    </>
  );

  return (
    <div className='actions-popover-width'>
      <h4 className='mb-4'>Ações</h4>
      <Button
        variant='light'
        className='w-100 mb-4'
        onClick={handleOpenWhatsApp}
        aria-label='WhatsApp Web'
      >
        <Icon name='whatsapp' className='me-1 text-primary' />
        <span className='text-primary text-nowrap'>
          WhatsApp Web
        </span>
      </Button>

      <LoadingButton
        variant='light'
        className='w-100 mb-4 me-2'
        onClick={handleOpenAgendorChat}
        isLoading={isLoadingAC}
        loadingText='Carregando...'
        aria-label='Agendor Chat'
      >
        <Icon name='whatsapp' className='me-1 text-primary' />
        <span className='text-primary text-nowrap'>
          Agendor Chat
        </span>
      </LoadingButton>

      {
        isAdmin
          ? (
            <Popover
              flip
              showArrow
              placement='bottom'
              hide={!hasVoipEnabled || !hasNoBalance}
              content={() => (
                <div>
                  <span className='text-dark fw-bold'>Saldo insuficiente</span>
                  <div className='my-1 mb-3'>
                    <span className='text-dark'>
                      Seu saldo não é suficiente.
                      Recarregue sua conta para continuar usando o telefone virtual.
                    </span>
                  </div>
                  <Button variant='primary' href={settingsVoipPanelPath()}>
                    Recarregar
                  </Button>
                  <Button
                    variant='light'
                    className='ms-2'
                    as={ExternalLink}
                    target='_blank'
                    href={helpUrl('activate-voip')}
                  >
                    <Icon name='external-link' className='text-dark me-1' />
                    Saiba mais
                  </Button>
                </div>
              )}
            >
              {callButton}
            </Popover>
          )
          : (
            <Tooltip
              placement='top'
              hide={!hasVoipEnabled || !hasNoBalance}
              content='Seu saldo não é suficiente.
                Solicite ao administrador da conta para adicionar créditos.'
            >
              {callButton}
            </Tooltip>
          )
      }

      <ModalAgendorChat
        show={showACModal}
        onClose={() => setShowACModal(false)}
      />
    </div>
  );
}

ContactPhoneOptions.propTypes = propTypes;
ContactPhoneOptions.defaultProps = defaultProps;

export default ContactPhoneOptions;
