import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import classnames from 'classnames';
import sum from 'lodash/sum';
import Form from '@/components/Form';
import HookFormField from '@/components/HookFormField';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import {
  DecimalInput,
  PercentualInput,
  IntegerInput,
  ProductSelect
} from '@/components/Inputs';
import Table from '@/components/Table';

import { toCurrencyString, toPercentageString, calculatePercentage } from '@/number';

const propTypes = {
  size: PropTypes.oneOf(['sm', 'md']),
  onSubtotalChange: PropTypes.func
};

const defaultProps = {
  size: 'md',
  onSubtotalChange: () => { }
};

const DISCOUNT_ROUNDED = 10000;
const CENTS_SCALE = 4;

/* eslint-disable no-magic-numbers */
export const schema = Yup.array().of(
  Yup.object().shape({
    product_id: Yup.number().nullable()
      .required('Por favor, informe o produto.'),
    unit_value: Yup.number()
      .max(999999999.99, 'Preço unitário deve ser no máximo 999.999.999,99.'),
    quantity: Yup.number()
      .max(9999999, 'Quantidade deve ser no máximo 9.999.999.'),
    total_value: Yup.number()
  })
);
/* eslint-enable no-magic-numbers */

export const defaultValues = {
  product_id: '',
  unit_value: '0',
  quantity: '0',
  total_value: '0',
  discount: '0',
  subtotal: '0',
  discount_percentage: '0'
};

const calculateSubtotal = (unitValue, quantity) => Number(unitValue) * Number(quantity);

const calculateDiscount = (discountPercentage, subtotal) => (
  (Number(subtotal) * Number(discountPercentage)) / DISCOUNT_ROUNDED
);

const calculateTotalValue = (discountPercentage, subtotal) => (
  Math.round(
    (1 - (Number(discountPercentage) / 100)) * Number(subtotal)
  )
);

const formatDecimal = (number, scale = 2) => Number(number).toFixed(scale);

const parseCents = (string) => (
  string
    ? parseInt(string.replace('.', ''), 10)
    : 0
);
const formatCents = (number) => (
  number
    ? formatDecimal(number / 100)
    : '0.00'
);

export function getInitialValues(items) {
  return items.map((item) => {
    const { id, product = {}, unitValue, quantity, discount, totalValue } = item;
    const subtotal = calculateSubtotal(unitValue, quantity);
    const percentage = calculatePercentage(discount, subtotal);

    return {
      product_id: product.id,
      name: product?.name || product?.initialProduct?.name,
      unit_value: formatDecimal(unitValue),
      quantity: quantity.toString(),
      discount: formatDecimal(discount, CENTS_SCALE),
      total_value: formatDecimal(totalValue),
      subtotal: formatDecimal(subtotal),
      discount_percentage: percentage.toString(),
      initialProduct: product,
      ...(id ? { id } : {})
    };
  });
}

function ProductRow({ index, onRemove, size, initialProduct }) {
  const { getFieldState, setValue, formState, getValues } = useFormContext();

  const setSubtotal = (unitValue, quantity) => {
    const discountPercentage = getValues(
      `entity_products.${index}.discount_percentage`
    );

    const unitValueCents = parseCents(unitValue);

    const subtotalCents = calculateSubtotal(unitValueCents, quantity);
    const totalValueCents = calculateTotalValue(discountPercentage, subtotalCents);
    const calculatedDiscount = calculateDiscount(discountPercentage, subtotalCents);

    setValue(`entity_products.${index}.discount`, formatDecimal(calculatedDiscount, CENTS_SCALE));
    setValue(`entity_products.${index}.subtotal`, formatCents(subtotalCents));
    setValue(`entity_products.${index}.total_value`, formatCents(totalValueCents));

    setValue('sync_value', true);
  };

  const onChangeDiscountPercentage = (discountPercentage) => {
    const discount = getValues(`entity_products.${index}.discount`);
    const subtotal = getValues(`entity_products.${index}.subtotal`);

    const discountCents = parseCents(discount);
    const subtotalCents = parseCents(subtotal);

    const totalValueCents = calculateTotalValue(discountPercentage, subtotalCents);
    const calculatedDiscount = calculateDiscount(discountPercentage, subtotalCents);

    if (discountCents !== calculatedDiscount) {
      setValue(`entity_products.${index}.discount`, formatDecimal(calculatedDiscount, CENTS_SCALE));
      setValue(`entity_products.${index}.total_value`, formatCents(totalValueCents));

      setValue('sync_value', true);
    }
  };

  const onChangeUnitValue = (newUnitValue) => {
    const { error } = getFieldState(`entity_products.${index}.quantity`);
    const quantity = getValues(`entity_products.${index}.quantity`);

    if (!error) {
      setSubtotal(newUnitValue, quantity);
    }
  };

  const onChangeQuantity = (newQuantity) => {
    const { error } = getFieldState(`entity_products.${index}.unit_value`);
    const unitValue = getValues(`entity_products.${index}.unit_value`);

    if (!error) {
      setSubtotal(unitValue, newQuantity);
    }
  };

  const onChangeProduct = (_id, product) => {
    const productName = product?.option?.label || product?.name;
    setValue(`entity_products.${index}.name`, productName);
    if (product?.option?.price) {
      const newUnitValue = formatDecimal(product?.option?.price);

      setValue(`entity_products.${index}.unit_value`, newUnitValue);
      onChangeUnitValue(newUnitValue);
    }
  };

  const isSmall = size === 'sm';

  /**
   * Quando o produto inicial estiver inativo, ele não vai estar no
   * resultado do request de produtos, devido ao filtro de ativos.
   * Por isso, para que ele apareça corretamente no select, ele é incluído como
   * opção default.
   */
  const defaultProductOptions = initialProduct && !initialProduct.active
    ? [{ value: initialProduct.id, label: initialProduct.name }]
    : [];

  const selectedProductId = useWatch({ name: `entity_products.${index}.product_id` });

  return (
    <tr>
      <td className='ps-0'>
        <HookFormField
          as={ProductSelect}
          name={`entity_products.${index}.product_id`}
          label=''
          placeholder='Buscar por nome, código ou categoria'
          className='mb-0'
          onChange={onChangeProduct}
          disabled={formState.isSubmitting}
          isCreatable
          queryArgs={{
            params: {
              active_true: true,
              per_page: 25,
              selected_id: selectedProductId
            }
          }}
          defaultOptions={defaultProductOptions}
        />
      </td>

      <td>
        <HookFormField
          as={DecimalInput}
          name={`entity_products.${index}.unit_value`}
          placeholder='0,00'
          onChange={onChangeUnitValue}
          className='mb-0'
          disabled={formState.isSubmitting}
          maskOptions={{
            clearMaskOnLostFocus: false,
            nullable: false,
            placeholder: '0'
          }}
        />
      </td>

      <td>
        <HookFormField
          as={IntegerInput}
          name={`entity_products.${index}.quantity`}
          placeholder='0'
          disabled={formState.isSubmitting}
          onChange={onChangeQuantity}
          className='mb-0'
        />
      </td>

      {!isSmall &&
        <td>
          <HookFormField
            as={DecimalInput}
            name={`entity_products.${index}.subtotal`}
            disabled={formState.isSubmitting}
            placeholder='R$ 0,00'
            readOnly
            className='mb-0'
          />
        </td>
      }

      <td>
        <HookFormField
          as={PercentualInput}
          name={`entity_products.${index}.discount_percentage`}
          disabled={formState.isSubmitting}
          placeholder='0'
          onChange={onChangeDiscountPercentage}
          className='mb-0'
        />
      </td>

      <td>
        <HookFormField
          as={DecimalInput}
          name={`entity_products.${index}.total_value`}
          disabled={formState.isSubmitting}
          placeholder='0,00'
          className='mb-0'
          readOnly
        />
      </td>

      <td className='pe-0'>
        <Button
          variant='outline-danger'
          size='sm'
          className='p-2 lh-1'
          disabled={formState.isSubmitting}
          onClick={onRemove}
          /*
           * Caso o clique dispare o `blur` de um input e isso gere
           * um erro de validação, o clique é cancelado.
           * ref: https://github.com/formium/formik/issues/1332
           */
          onMouseDown={(e) => e.preventDefault()}
        >
          <Icon name='delete' size='sm' />
        </Button>
      </td>
    </tr>
  );
}

function ProductHeader({ isEmpty, size }) {
  const isSmall = size === 'sm';

  if (isEmpty && !isSmall) {
    return null;
  }

  const labels = isSmall
    ? {
      item: 'Produtos e serviços',
      price: 'Preço',
      quantity: 'Quant.',
      subtotal: null,
      discount: 'Desc.',
      total_value: 'Total'
    }
    : {
      item: 'Item',
      price: 'Preço unitário (R$)',
      quantity: 'Quantidade',
      subtotal: 'Total (R$)',
      discount: 'Desconto (%)',
      total_value: 'Total com desconto (R$)'
    };

  if (!isEmpty) {
    return (
      <thead>
        <tr className='text-small text-nowrap'>
          <th
            className={classnames('ps-0', {
              'col-2': isSmall,
              'col-4': !isSmall
            })}
          >
            {labels.item}
          </th>

          <th
            className={classnames({
              'col-3': isSmall,
              'pe-4': !isSmall
            })}
          >
            {labels.price}
          </th>

          <th
            className={classnames({
              'col-2': isSmall,
              'pe-4': !isSmall
            })}
          >
            {labels.quantity}
          </th>

          {
            labels.subtotal &&
            <th className='col-2'>
              {labels.subtotal}
            </th>
          }

          <th
            className={classnames({
              'col-2': isSmall,
              'pe-7': !isSmall
            })}
          >
            {labels.discount}
          </th>

          <th
            className={classnames({
              'col-3': isSmall,
              'col-2': !isSmall
            })}
          >
            {labels.total_value}
          </th>

          <th className={classnames({ 'pe-0': !isSmall })}></th>
        </tr>
      </thead>
    );
  } else {
    return (
      <thead>
        <tr>
          <th className='text-small ps-0'>{labels.item}</th>
        </tr>
      </thead>
    );
  }
}

function ProductsSummary({ isEmpty }) {
  const { setValue } = useFormContext();
  const entityProducts = useWatch({ name: 'entity_products' });
  const valueWithoutDiscount = sum(entityProducts.map((item) => (
    item._destroy ? 0 : Number(item.subtotal)
  )));
  const valueWithDiscount = sum(entityProducts.map((item) => (
    item._destroy ? 0 : Number(item.total_value)
  )));
  const discountValue = sum(entityProducts.map((item) => (
    item._destroy ? 0 : Number(item.discount)
  )));

  const hasDiscount = valueWithoutDiscount !== valueWithDiscount;

  const discountPercentage = calculatePercentage(discountValue, valueWithoutDiscount);

  useEffect(() => {
    setValue('calculated_value', valueWithDiscount.toString());
  }, [valueWithDiscount]);

  return (
    <div className='d-flex justify-content-end text-end'>
      <table className='table table-borderless w-50 table-sm'>
        <tbody>
          {
            hasDiscount &&
            <>
              <tr className='text-medium-gray fw-bold'>
                <td>Valor</td>
                <td>{toCurrencyString(valueWithoutDiscount)}</td>
              </tr>
              <tr className='text-medium-gray fw-bold'>
                <td>Valor com desconto</td>
                <td>
                  ({toPercentageString(discountPercentage)})
                  <span className='mx-1'>-</span>
                  {toCurrencyString(discountValue)}
                </td>
              </tr>
            </>
          }
          {
            isEmpty
              ? null
              : (
                <tr className='text-dark-gray h4 mb-0'>
                  <td>Valor total</td>
                  <td>{toCurrencyString(valueWithDiscount)}</td>
                </tr>
              )
          }
        </tbody>
      </table>
    </div>
  );
}

function HookProductsFields({ size }) {
  const { fields, append, remove, update } = useFieldArray({
    name: 'entity_products',
    keyName: 'key'
  });
  const { setValue } = useFormContext();

  const nonRemovedProducts = fields.filter((item) => !item._destroy);

  const isEmpty = nonRemovedProducts.length === 0;
  const addButtonLabel = isEmpty ? 'Adicionar' : 'Adicionar outro';

  const handleRemove = (item, index) => {
    setValue('sync_value', true);

    if (item.id) {
      update(index, { ...item, _destroy: true });
    } else {
      remove(index);
    }
  };

  return (
    <Form.Group>
      <div>
        <Table borderless size='sm'>
          <ProductHeader isEmpty={isEmpty} size={size} />

          <tbody>
            {fields.map((item, index) => (
              !item._destroy &&
              <ProductRow
                key={`item_${item.key}`}
                index={index}
                onRemove={() => handleRemove(item, index)}
                size={size}
                initialProduct={item.initialProduct}
              />
            ))}
          </tbody>
        </Table>

        <Button
          variant='link'
          size='sm'
          className='px-0 fw-bold'
          onClick={() => append(defaultValues)}
        >
          <Icon name='plus' size='sm' className='me-1' />

          <span>{addButtonLabel}</span>
        </Button>
      </div>

      <ProductsSummary isEmpty={isEmpty} />
    </Form.Group>
  );
}

HookProductsFields.propTypes = propTypes;
HookProductsFields.defaultProps = defaultProps;

export default HookProductsFields;
