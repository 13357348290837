import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Stack from 'react-bootstrap/Stack';

import Badge from '@/components/Badge';
import Dropdown from '@/components/Dropdown';
import Icon, { ICONS } from '@/components/Icon';
import { useQueryClient } from 'react-query';
import { checkOngoingChecklistItem, ongoingChecklistsKeys } from '@/api';

const propTypes = {
  data: PropTypes.shape({
    key: PropTypes.string,
    icon: PropTypes.oneOf(ICONS),
    title: PropTypes.string,
    href: PropTypes.string,
    completed: PropTypes.bool
  }).isRequired
};

function OngoingChecklistItem(props) {
  const {
    data: {
      key,
      icon,
      title,
      href,
      completed
    }
  } = props;

  const unsubscribe = useRef(null);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (unsubscribe.current && completed) {
      unsubscribe.current();
      unsubscribe.current = null;
    }

    if (completed) {
      return;
    }

    unsubscribe.current = queryClient
      .getMutationCache()
      .subscribe((mutation) => {
        if (mutation.options.meta?.ongoingChecklistKey !== key) {
          return;
        }

        if (mutation.state.status !== 'success') {
          return;
        }

        queryClient.setQueryData(
          ongoingChecklistsKeys.all,
          (previousData) => checkOngoingChecklistItem(previousData, key)
        );
      });

    return unsubscribe.current;
  }, [completed]);

  return (
    <Dropdown.Item
      key={key}
      className='border border-1 rounded-1 p-2 position-relative text-dark-gray'
      href={href}
    >
      <Stack direction='horizontal' gap={3}>
        <Badge
          variant={completed ? 'flat-green' : 'primary'}
          className='p-1 rounded-circle'
        >
          <Icon size='sm' name={icon} />
        </Badge>

        <b className={completed ? 'text-decoration-line-through' : ''}>
          {title}
        </b>

        <div className='ms-auto text-primary px-1'>
          <Icon size='sm' name='arrow-right' />
        </div>
      </Stack>
    </Dropdown.Item>
  );
}

OngoingChecklistItem.propTypes = propTypes;

export default OngoingChecklistItem;
