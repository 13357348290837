import React from 'react';
import BaseTable from '@/components/EntityModal/Table/BaseTable';
import { convertDateTimeString } from '@/date';
import { toCurrencyString } from '@/number';
import Icon from '@/components/Icon';
import classNames from 'classnames';
import DealTabEmpty from '@/components/EntityModal/DealTabEmpty';

const statuses = {
  ongoing: {
    icon: 'active-deal',
    className: 'bg-light-yellow text-warning'
  },
  won: {
    icon: 'won-deal',
    className: 'bg-light-green text-success'
  },
  lost: {
    icon: 'lost-deal',
    className: 'bg-light-red text-danger'
  }
};

function DealStatus({ dealStatus }) {
  const { icon, className } = statuses[dealStatus?.key];

  return (
    <div className={classNames('px-4 py-2 rounded-1', className)}>
      <Icon name={icon} size='sm' className='me-1' />
      {dealStatus.name}
    </div>
  );
}

const TABLE = {
  headers: [
    'Nome',
    'FUNIL',
    'STATUS',
    'ETAPA',
    'VALOR',
    'DATA DE INÍCIO',
    'DATA DE CONCLUSÃO',
    'ÚLTIMA ATUALIZAÇÃO',
    'DATA DE CADASTRO'
  ],
  columns: [
    {
      callback: (value) => value,
      objectPath: 'stage.funnel.name'
    },
    {
      callback: (value) => <DealStatus dealStatus={value} />,
      objectPath: 'dealStatus'
    },
    {
      callback: (value) => value,
      objectPath: 'stage.name'
    },
    {
      callback: (value) => toCurrencyString(value),
      objectPath: 'value'
    },
    {
      callback: (value) => convertDateTimeString(value),
      objectPath: 'startedAt'
    },
    {
      callback: (value) => convertDateTimeString(value),
      objectPath: 'finishedAt'
    },
    {
      callback: (value) => convertDateTimeString(value),
      objectPath: 'updatedAt'
    },
    {
      callback: (value) => convertDateTimeString(value),
      objectPath: 'createdAt'
    }
  ]
};

function DealTabTable({
  fetchNextPage,
  hasNextPage,
  isLoading,
  entityId,
  items,
  itemsTotal,
  relatedEntityType
}) {
  return (
    <BaseTable
      entityType='deal'
      headers={TABLE.headers}
      columns={TABLE.columns}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isLoading={isLoading}
      entityId={entityId}
      items={items}
      itemsTotal={itemsTotal}
      relatedEntityType={relatedEntityType}
      EmptyComponent={<DealTabEmpty entityType={relatedEntityType} entityId={entityId} />}
    />
  );
}

export default DealTabTable;
