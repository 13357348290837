import React from 'react';
import PropTypes from 'prop-types';
import Nav from 'react-bootstrap/Nav';
import Icon, { ICONS } from '@/components/Icon';
import Link from '@/components/Link';
import Tooltip from '@/components/Tooltip';
import { useScreenSize } from '@/hooks';
import classnames from 'classnames';

const propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(ICONS).isRequired,
  text: PropTypes.string.isRequired
};

function HeaderItem({ href, icon, text, ...props }) {
  const { isLG } = useScreenSize();

  return (
    <Tooltip hide={!isLG} content={text}>
      <Nav.Link
        className='px-3 d-flex align-items-center'
        as={Link}
        href={href}
        { ...props }
      >
        <Icon name={icon} className={classnames(
          'nav-icon',
          { 'text-dark-light': isLG }
        )} />

        <span className='
          ms-2
          d-none
          d-xl-block
          nav-text
          text-white
          fw-bold'
        >
          {text}
        </span>
      </Nav.Link>
    </Tooltip>
  );
}

HeaderItem.propTypes = propTypes;

export default HeaderItem;
