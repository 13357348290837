import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { useTracking } from '@/lib/tracking';
import { useAlert } from 'react-alert';
import { getValidationErrors, isFeedbackRecent, isOptin } from '@/utils';
import APIErrorMessage from '@/components/APIErrorMessage';
import FullOrganizationForm from '@/components/FullOrganizationForm';
import { useDocumentTitle } from '@/hooks';
import {
  useOrganization,
  useUpdateOrganization,
  useDeleteOrganization,
  useOrganizationCustomFields
} from '@/api';
import { redirect, goBack, setLegacyAlertMessage } from '@/browser';
import NotFound from '@/pages/not-found';
import { isNotFoundError } from '@/errors';
import { legacyNewFormsOptoutUrl, legacyOrganizationUrl, organizationPath } from '@/routes';
import { useAuth } from '@/lib/auth';
import Main from '@/components/Main';
import ConfirmationModal from '@/components/ConfirmationModal';
import OptoutButton from '@/components/OptoutButton';
import { getInitialValues as getPrivacyInitialValues } from '@/components/Inputs/PrivacyInput';
import { getInitialValues as getCustomFieldsInitialValues } from '@/components/CustomFields';
import BackToLegacyModal from '@/components/EntityModal/BackToLegacyModal';

const PAGE_TITLE = 'Alterar empresa';

function OrganizationsEdit() {
  const { user } = useAuth();
  const alert = useAlert();
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const updateOrganizationMutation = useUpdateOrganization({
    /**
     * Quando essa mutation é bem-sucedida, por padrão as queries relacionadas
     * são invalidadas e, consequentemente, seus dados são buscados novamente.
     * Porém, aqui isso não é necessário, porque o usuário vai ser redirecionado
     * para o sistema legado logo em seguida.
     * Por isso, aqui é sobrescrito o callback padrão de `onSuccess`.
     */
    config: { onSuccess: () => {} }
  });
  const tracker = useTracking();
  const [showLegacyModal, setShowLegacyModal] = useState(false);

  const {
    data: organization,
    error,
    isSuccess: loadedOrganization
  } = useOrganization({ organizationId, params: { logo_size: 'large' } });
  const { data: customFields, isSuccess: loadedCustomFields } = useOrganizationCustomFields();
  const eventMetadata = { source: 'newForm', entityCustomFields: customFields?.data, user };

  useDocumentTitle(PAGE_TITLE);

  useEffect(() => {
    tracker.trackNewEditOrganizationFormViewed({ user });
  }, [user]);

  const onSubmit = (formData, { setFieldError, setSubmitting }) => {
    updateOrganizationMutation.mutate({ organizationId, params: formData }, {
      onError: (err) => {
        const errors = getValidationErrors(err, 'organization');
        Object.entries(errors).forEach(([key, value]) => {
          setFieldError(key, value);
        });
        alert.show(
          <APIErrorMessage err={err} resource='organization' action='update' />,
          { variant: 'danger' }
        );
        setSubmitting(false);
      },
      onSuccess: ({ data }) => {
        tracker.trackOrganizationUpdated({ ...eventMetadata, formData });
        setLegacyAlertMessage('Empresa alterada com sucesso!', 'success');

        if (user.features.blockLegacyEnabled || isOptin(user?.features?.organizationModalEnabled)) {
          navigate(organizationPath(data.id, '/organizations'));
        } else {
          redirect(legacyOrganizationUrl(data.id));
        }
      }
    });
  };

  const initialValues = useMemo(
    () => (organization ? getOrganizationInitialValues(organization.data) : {}),
    [organization]
  );

  const handleOpenModal = () => {
    const screen = 'Formulario';
    const feedbackGivenRecently = isFeedbackRecent(screen);
    tracker.trackBackToLegacy({
      user,
      page: 'Formulario',
      entity: 'Empresa',
      feedback: !feedbackGivenRecently
    });
    if (feedbackGivenRecently) {
      const redirectUrl = legacyNewFormsOptoutUrl({ type: 'organization', id: organizationId });
      window.location.href = redirectUrl;
    } else {
      setShowLegacyModal(true);
    }
  };

  const onCancel = () => goBack();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteOrganizationMutation = useDeleteOrganization();

  const onClickDelete = () => setShowDeleteConfirm(true);
  const onCancelDelete = () => setShowDeleteConfirm(false);

  const onConfirmDelete = () => {
    setIsDeleting(true);

    deleteOrganizationMutation.mutate({ organizationId }, {
      onError: (err) => {
        setIsDeleting(false);

        alert.show(
          <APIErrorMessage err={err} resource='organization' action='delete' />,
          { variant: 'danger' }
        );
      },
      onSuccess: () => {
        alert.show(
          'Empresa removida com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        navigate('/organizations');
      }
    });
  };

  if (error && isNotFoundError(error)) {
    return <NotFound />;
  }

  if (!loadedOrganization || !loadedCustomFields) {
    return <div className='flex-grow-1 bg-light' />;
  }

  return (
    <Main fluid flex>
      <div className='py-6 border-bottom'>
        <Container>
          <Row>
            <Col
              md={{ offset: 1, span: 10 }}
              className='d-flex align-items-center justify-content-between'
            >
              <h1>{PAGE_TITLE}</h1>

              <OptoutButton
                from='newForms'
                entity={{ type: 'organization', id: organizationId }}
                onClick={handleOpenModal}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <BackToLegacyModal
        show={showLegacyModal}
        onHide={() => setShowLegacyModal(false)}
        variant='newForms'
        entity={{ type: 'organization', id: organizationId }}
        entityType={'organization'}
      />

      <FullOrganizationForm
        customFields={customFields?.data || []}
        organizationId={Number(organizationId)}
        initialValues={initialValues}
        onSubmit={onSubmit}
        onCancel={onCancel}
        saveButtonText='Salvar alterações'
        cancelButtonText='Cancelar'
        onDelete={onClickDelete}
      />

      <ConfirmationModal
        confirmationText='Excluir'
        show={showDeleteConfirm}
        onConfirm={onConfirmDelete}
        onHide={onCancelDelete}
        disabled={isDeleting}
      >
        <ConfirmationModal.Title>
          Deseja excluir esta empresa?
        </ConfirmationModal.Title>

        <ConfirmationModal.Info>
          Todo o histórico de relacionamento será perdido.
        </ConfirmationModal.Info>
      </ConfirmationModal>
    </Main>
  );
}

function getContactAttributes(contact = {}) {
  return {
    email: contact.email ?? '',
    facebook: contact.facebook ?? '',
    linkedin: contact.linkedin ?? '',
    whatsapp: contact.whatsapp ?? '',
    instagram: contact.instagram ?? '',
    skype: contact.skype ?? '',
    twitter: contact.twitter ?? '',
    phone_extension: contact.phoneExtension ?? '',
    mobile_phone: contact.mobilePhone ?? '',
    work_phone: contact.workPhone ?? '',
    fax_phone: contact.faxPhone ?? ''
  };
}

function listProductsId(products = []) {
  return products.map((product) => product.id);
}

function getOrganizationInitialValues(organization) {
  return {
    name: organization.name,
    logo: organization.logo,
    legal_name: organization.legalName ?? '',
    cnpj: organization.cnpj ?? '',
    description: organization.description ?? '',
    website: organization.website ?? '',
    owner_user_id: organization.ownerUser?.id ?? '',
    contact: getContactAttributes(organization.contact),
    sector_id: organization.sector?.id ?? '',
    category_id: organization.category?.id ?? '',
    lead_origin_id: organization.leadOrigin?.id ?? '',
    products_id: listProductsId(organization.products),
    address: {
      postal_code: organization.address.postalCode ?? '',
      country: organization.address.country ?? '',
      district: organization.address.district ?? '',
      street_name: organization.address.streetName ?? '',
      street_number: organization.address.streetNumber ?? '',
      city_id: organization.address.cityId ?? '',
      state: organization.address.state ?? '',
      additional_info: organization.address.additionalInfo ?? ''
    },
    people: organization.people
      .map((person) => ({
        id: person.id,
        name: person.name,
        job_title: person.jobTitle ?? '',
        contact: {
          email: person.contact.email ?? '',
          whatsapp: person.contact.whatsapp ?? ''
        }
      })),
    custom_fields: getCustomFieldsInitialValues(organization.customFields),
    ...getPrivacyInitialValues(organization)
  };
}

export default OrganizationsEdit;
