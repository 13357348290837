import React from 'react';
import PropTypes from 'prop-types';
import Link from '@/components/Link';
import ExternalLink from '@/components/ExternalLink';
import {
  dealPath,
  organizationPath,
  personPath,
  legacyOrganizationUrl,
  legacyPersonUrl,
  legacyDealUrl
} from '@/routes';
import { useAuth } from '@/lib/auth';
import { isOptin } from '@/utils';
import isBefore from 'date-fns/isBefore';


const propTypes = {
  entity: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired,
  origin: PropTypes.string,
  target: PropTypes.string
};

export function isOrganizationModalEnabled(user) {
  const newHistoryRelease = new Date('2024-12-02');
  const accountCreatedAt = new Date(user.account.createdAt);
  const forceNewHistory = isBefore(newHistoryRelease, accountCreatedAt);

  return (
    forceNewHistory ||
    user.features.blockLegacyEnabled ||
    isOptin(user.features.organizationModalEnabled)
  );
}

function EntityLink({ entity, origin, target, ...props }) {
  const { user } = useAuth();
  const { features } = user;
  let Component, href;

  switch (entity?.type) {
    case 'organization':
      if (isOrganizationModalEnabled(user)) {
        Component = Link;
        href = organizationPath(entity.id);
      } else {
        Component = ExternalLink;
        href = legacyOrganizationUrl(entity.id, true);

        if (origin) {
          href = `${href}&origin=${origin}`;
        }
      }
      break;

    case 'person':
      if (isOrganizationModalEnabled(user)) {
        Component = Link;
        href = personPath(entity.id);
      } else {
        Component = ExternalLink;
        href = legacyPersonUrl(entity.id, true);

        if (origin) {
          href = `${href}&origin=${origin}`;
        }
      }
      break;

    case 'deal':
      if (features.blockLegacyEnabled || isOptin(features.dealModalEnabled)) {
        Component = Link;
        href = dealPath(entity.id);
      } else {
        Component = ExternalLink;
        href = legacyDealUrl(entity.id, true);
      }
      break;

    default:
      Component = ExternalLink;
  }

  return <Component { ...props } href={href ?? ''} target={target} />;
}

EntityLink.propTypes = propTypes;

export default EntityLink;
