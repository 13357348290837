import React from 'react';

import { useOrganizationCustomFields } from '@/api';
import {
  useOrganization,
  useOnUpdate,
  useOnUpdatePerson,
  useVoip
} from '@/contexts/organizationModal';
import { organizationEditPath } from '@/routes';
import { canEditOrganization } from '@/policies';
import DetailsContact from '@/components/EntityModal/DetailsContact';
import Actions from '@/components/EntityModal/Organization/Sidebar/Actions';
import OrganizationBasicData
  from '@/components/EntityModal/Organization/Sidebar/OrganizationBasicData';
import EntityProducts from '@/components/EntityModal/EntityProducts';
import Skeleton from '@/components/Skeleton';

function SidebarContent({ organization }) {
  const onUpdate = useOnUpdate();
  const onUpdatePerson = useOnUpdatePerson();
  const voip = useVoip();

  const {
    data: { data: organizationsCustomFields } = {},
    isLoading: isLoadingOrganizationsCustomFields
  } = useOrganizationCustomFields({ config: { staleTime: 1000 } });

  const entityCustomFields = organizationsCustomFields || [];
  const entityEditPath = organizationEditPath(organization.id);
  const entityType = 'organization';

  return (
    <>
      <Actions />

      <OrganizationBasicData
        organization={organization}
        onEditEntity={onUpdate}
        entityCustomFields={entityCustomFields}
        entityType={entityType}
      />

      <DetailsContact
        onEditEntity={onUpdate}
        entity={organization}
        entityType={entityType}
        entityTypeModal={entityType}
        entityEditPath={entityEditPath}
        entityCustomFields={entityCustomFields}
        canEditPolicy={canEditOrganization}
        loading={isLoadingOrganizationsCustomFields}
        onEditPerson={onUpdatePerson}
        showTitle={false}
        voip={voip}
      />
      <EntityProducts
        entity={organization}
        canEditPolicy={canEditOrganization}
        updateEntity={onUpdate}
        entityType='organization'
      />
    </>
  );
}

function Sidebar() {
  const organization = useOrganization();

  if (!organization) {
    return (
      <>
        <Skeleton width='100%' height={150} className='mb-3' />
        <Skeleton width='100%' height={300} className='mb-3' />
        <Skeleton width='100%' height={300} className='mb-3' />
      </>
    );
  }

  return (<SidebarContent organization={organization} />);
}

export default Sidebar;
