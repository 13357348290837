import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useProposalModels } from '@/api';
import { useDebouncedValue } from '@/hooks';
import emptyProposalModel from 'images/empty-proposal-model.png';
import Button from '@/components/Button';
import { TextInput } from '@/components/Inputs';
import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import StoredForm from '@/components/StoredForm';
import ProposalModelCard from '@/components/Proposal/ProposalModelCard';
import ProposalSortInput from '@/components/Proposal/ProposalSortInput';
import Tooltip from '@/components/Tooltip';
import FormField from '@/components/FormField';
import InputAdornment from '@/components/InputAdornment';
import LoadingWrapper from '@/components/LoadingWrapper';
import FeatureControlPopover from '@/components/FeatureControlPopover';

ProposalModelsModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onOpenProposal: PropTypes.func,
  onRename: PropTypes.func,
  onNewProposalModel: PropTypes.func,
  onEditProposalModel: PropTypes.func,
  proposalModels: PropTypes.array,
  modalSize: PropTypes.string
};

ProposalModelsModal.defaultProps = {
  show: false,
  onHide: () => { },
  onOpenProposal: () => { },
  onRename: () => { },
  onNewProposalModel: () => { },
  onEditProposalModel: () => { },
  proposalModels: [],
  modalSize: 'xxl'
};

const MAX_PROPOSAL_MODELS = 50;
const DEBOUNCE_DELAY = 200;
const searchDefaultValues = { q: '', sorts: 'updated_at' };

function ProposalModelsModal(props) {
  const {
    children,
    modalSize,
    onEditProposalModel,
    onHide,
    onNewProposalModel,
    onOpenProposal,
    onRename,
    show
  } = props;

  const { user } = useAuth();
  const tracker = useTracking();
  const [searchParams, setSearchParams] = useState(searchDefaultValues);
  const [clearSearch, setClearSearch] = useState(false);
  const debouncedParams = useDebouncedValue(searchParams, DEBOUNCE_DELAY);
  const [showPopover, setShowPopover] = useState(false);
  const hasFullAccess = user.account.legacyPro || user.account.performanceOrHigher;

  const {
    data: proposalModelsData,
    isFetching: isLoadingProposalModels
  } = useProposalModels({
    params: debouncedParams,
    config: {
      onSuccess: () => {
        setClearSearch(false);
      }
    }
  });

  function resetSearchAndClose() {
    tracker.trackProposalNotCreated({ user });
    setSearchParams((prevParams) => ({ q: '', sorts: prevParams.sorts }));
    onHide();
  }

  const proposalModels = proposalModelsData?.data || [];
  const disabledCreate = proposalModels.length >= MAX_PROPOSAL_MODELS;

  const verifyPlan = () => {
    if (hasFullAccess || (proposalModels.length === 0)) {
      setShowPopover(false);
      onNewProposalModel();
    } else {
      setShowPopover(true);
    }
  };

  return (
    <>
      <Modal show={show} onHide={resetSearchAndClose} size={modalSize} bodyClassName='p-0'>
        <div className='px-8 py-4 border-bottom border-1'>
          <h1 className='text-darker-gray'>Gerador de proposta</h1>
        </div>

        <div className='px-8 pb-8 pt-4'>
          <div className='w-100 rounded-0 bg-white mb-4'></div>
          <div>
            <div className='d-flex'>
              <h3>Modelos de proposta</h3>
            </div>
            <div className='d-flex justify-content-between align-items-center pb-8 mt-4'>
              <div className='d-flex align-items-center'>
                <StoredForm
                  defaultValues={searchDefaultValues}
                  initialValues={{ q: '' }}
                  onSubmit={setSearchParams}
                  name='proposal-models-search'
                  className='d-flex'
                >
                  {({ submitForm }) => (
                    <>
                      <FormField
                        as={TextInput}
                        name='q'
                        isClearable
                        onChange={submitForm}
                        onClear={() => setClearSearch(true)}
                        placeholder='Buscar modelo...'
                        autoComplete='off'
                        className='search-proposal-models-input'
                        rightAdornment={({ focus }) => (
                          <InputAdornment alignment='right' onClick={focus}>
                            <Icon className='text-primary' name='search' />
                          </InputAdornment>
                        )}
                      />
                      <FormField
                        as={ProposalSortInput}
                        name='sorts'
                        value={searchParams.sorts}
                        onChange={submitForm}
                        innerClassName='mx-3'
                      />
                    </>
                  )}
                </StoredForm>
              </div>
              <Tooltip
                placement='top'
                content={
                  'Limite de 50 modelos atingido na conta. ' +
                  'Exclua ou edite algum modelo existente'
                }
                hide={!disabledCreate}
              >
                <div>
                  <FeatureControlPopover
                    showPopover={showPopover}
                    placement='bottom'
                    user={user}
                  >
                    <Button
                      variant='outline-dark-gray'
                      disabled={disabledCreate}
                      onClick={verifyPlan}
                    >
                      <Icon name='note' className='me-1' />
                        Criar novo modelo
                    </Button>
                  </FeatureControlPopover>
                </div>
              </Tooltip>
            </div>

            <div className='height-6 overflow-y-auto overflow-hidden p-1'>
              <LoadingWrapper isLoading={isLoadingProposalModels}>
                {
                  proposalModels.length > 0
                    ? (
                      <Row className='g-4'>
                        {proposalModels.map((proposalModel, index) => (
                          <Col sm={3} key={proposalModel.id}>
                            <ProposalModelCard
                              onRename={onRename}
                              index={index}
                              onEdit={onEditProposalModel}
                              onOpenProposal={onOpenProposal}
                              proposalModel={proposalModel}
                              disabledCreate={disabledCreate}
                            />
                          </Col>
                        ))}
                      </Row>
                    )
                    : (
                      <div className='d-flex flex-column align-items-center justify-content-center'>
                        {
                          clearSearch || searchParams.q
                            ? (
                              <div className='text-center text-dark-gray'>
                                Nenhum resultado encontrado.
                              </div>
                            )
                            : (
                              <>
                                <img
                                  width={200}
                                  src={emptyProposalModel}
                                  alt='Nenhum modelo de proposta criado'
                                  className='mt-6'
                                />
                                <h4 className='my-4'>Nenhum modelo criado</h4>
                                <span className='text-dark-gray'>
                                  Crie um novo modelo para gerar propostas
                                </span>
                              </>
                            )}
                      </div>
                    )
                }
              </LoadingWrapper>
            </div>
          </div>
        </div>
      </Modal>
      {children}
    </>
  );
}

export default ProposalModelsModal;
