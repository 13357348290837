import React, { useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Popover from '@/components/Popover';
import Form from '@/components/Form';
import LoadingButton from '@/components/LoadingButton';
import FormField from '@/components/FormField';
import Tooltip from '@/components/Tooltip';
import Skeleton from '@/components/Skeleton';
import DatePicker from '@/components/DatePicker';
import { TextArea, LossReasonSelect } from '@/components/Inputs';
import { DEAL_STATUSES_KEY, DEAL_STATUSES, getString } from '@/utils';
import { now, parseDate } from '@/date';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';

const defaultValues = { deal_loss_reason: {} };

function StatusReasonForm({ onHide, isLostDeal, handleOnSubmit, deal }) {
  const initialValues = useMemo(() => ({
    finished_at: deal.finishedAt ? parseDate(deal.finishedAt) : now(),
    deal_loss_reason: {
      loss_reason_id: deal?.dealLossReason?.lossReason?.id,
      description: deal?.dealLossReason?.description
    }
  }), [deal]);
  return (
    <Form
      name='deal-status-reason-form'
      onSubmit={(values) => handleOnSubmit(values, onHide)}
      initialValues={initialValues}
      defaultValues={defaultValues}
      className='d-flex'
    >
      {({ handleBlur, handleSubmit, isSubmitting, values }) => {
        const isLostReasonFilled = Boolean(values.deal_loss_reason?.loss_reason_id);

        return (
          <>
            <div className='d-flex flex-column bg-white px-4 py-6'>
              <h4>{`Quando foi ${isLostDeal ? 'perdido' : 'ganho'}?`}</h4>
              <FormField
                name='finished_at'
                mode='single'
                as={DatePicker}
                selected={values.finished_at}
                required
              />
              {!isLostDeal && (
                <LoadingButton
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                  className='min-width-4 px-8'
                >
                  Salvar
                </LoadingButton>
              )}
            </div>
            {isLostDeal && (
              <div className='d-flex flex-column min-width-5 bg-light px-4 py-6'>
                <h4 className='mb-6'>Qual motivo da perda?</h4>
                <FormField
                  as={LossReasonSelect}
                  name='deal_loss_reason.loss_reason_id'
                  controlClassName='bg-white'
                  onBlur={handleBlur}
                  isSearchable
                />
                <FormField
                  as={TextArea}
                  name='deal_loss_reason.description'
                  variant='white'
                  placeholder='Comentário (opcional)'
                  maxLength={250}
                  onBlur={handleBlur}
                />
                <Tooltip
                  placement='bottom'
                  content='O motivo de perda é obrigatório'
                  trigger={['hover']}
                  hide={isLostReasonFilled}
                >
                  <div>
                    <LoadingButton
                      disabled={!isLostReasonFilled}
                      onClick={handleSubmit}
                      isLoading={isSubmitting}
                      className={classnames('w-100', {
                        'pe-none': !isLostReasonFilled
                      })}
                    >
                      Salvar
                    </LoadingButton>
                  </div>
                </Tooltip>
              </div>
            )}
          </>
        );
      }}
    </Form>
  );
}

StatusButtons.defaultProps = {
  deal: null,
  loading: true,
  onChangeStatus: () => { }
};

StatusButtons.propTypes = {
  deal: PropTypes.object,
  loading: PropTypes.bool,
  onChangeStatus: PropTypes.func
};

function StatusButtons({ deal, onChangeStatus, loading }) {
  const isLost = deal?.dealStatus?.key === DEAL_STATUSES_KEY.lost;
  const isOngoing = deal?.dealStatus?.key === DEAL_STATUSES_KEY.ongoing;
  const isWon = deal?.dealStatus?.key === DEAL_STATUSES_KEY.won;
  const { user } = useAuth();
  const tracker = useTracking();

  if (loading) {
    return (
      <div className='d-inline-flex'>
        <Skeleton width={90} height={30} className='ms-1' />
        <Skeleton width={130} height={30} className='ms-1' />
        <Skeleton width={80} height={30} className='ms-1' />
      </div>
    );
  }

  return (
    <div>
      <Popover
        rootCloseEvent='mousedown'
        placement='bottom-start'
        contentClassName='p-0'
        className='max-width-7'
        content={({ onHide }) => (
          <StatusReasonForm
            deal={deal}
            onHide={onHide}
            handleOnSubmit={(values, done) => {
              tracker.trackDealStatusChanged({
                user,
                status: getString(['models', 'deal_status', DEAL_STATUSES_KEY.lost]),
                page: 'Nova Ficha'
              });
              onChangeStatus({
                deal_status_id: DEAL_STATUSES.LOST,
                ...values
              }, done);
            }}
            isLostDeal
          />
        )}
      >
        <Button
          size='sm'
          variant={isLost ? 'light-red' : 'white'}
          className={classnames('px-2 me-1', { 'text-danger': isLost })}
        >
          <Icon name='lost-deal' size='sm' className='me-1' />
          {getString(['models', 'deal_status', DEAL_STATUSES_KEY.lost])}
        </Button>
      </Popover>
      <Button
        size='sm'
        variant={isOngoing ? 'light-yellow' : 'white'}
        className={classnames('px-2 me-1', { 'text-warning': isOngoing })}
        onClick={() => {
          tracker.trackDealStatusChanged({
            user,
            status: getString(['models', 'deal_status', DEAL_STATUSES_KEY.ongoing]),
            page: 'Nova Ficha'
          });
          onChangeStatus({ deal_status_id: DEAL_STATUSES.ONGOING });
        }}
      >
        <Icon name='active-deal' size='sm' className='me-1' />
        {getString(['models', 'deal_status', DEAL_STATUSES_KEY.ongoing])}
      </Button>
      <Popover
        rootClose
        rootCloseEvent='mousedown'
        placement='bottom-start'
        trigger={['click']}
        contentClassName='p-0'
        content={({ onHide }) => (
          <StatusReasonForm
            deal={deal}
            onHide={onHide}
            handleOnSubmit={(values, done) => {
              tracker.trackDealStatusChanged({
                user,
                status: getString(['models', 'deal_status', DEAL_STATUSES_KEY.won]),
                page: 'Nova Ficha'
              });
              onChangeStatus({
                deal_status_id: DEAL_STATUSES.WON,
                ...values
              }, done);
            }}
          />
        )}
      >
        <Button
          size='sm'
          variant={isWon ? 'light-green' : 'white'}
          className={classnames('px-2 me-2', { 'text-success': isWon })}
        >
          <Icon name='won-deal' size='sm' className='me-1' />
          {getString(['models', 'deal_status', DEAL_STATUSES_KEY.won])}
        </Button>
      </Popover>
    </div>
  );
}

export default StatusButtons;
