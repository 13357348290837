import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import classnames from 'classnames';
import Task from '@/task';
import TaskPopover from '@/components/TaskPopover';
import Tooltip from '@/components/Tooltip';
import EntityLink from '@/components/EntityLink';

const VARIANTS = {
  empty: {
    secondaryIcon: '',
    btnVariant: 'light-red',
    textVariant: 'danger',
    tooltipText: 'Nenhuma tarefa agendada. Clique para adicionar uma tarefa'
  },
  scheduled: {
    secondaryIcon: 'calendar',
    btnVariant: 'light-green',
    textVariant: 'success',
    tooltipText: 'Clique para mais detalhes'
  },
  overdue: {
    secondaryIcon: 'alert',
    btnVariant: 'light-yellow',
    textVariant: 'warning',
    tooltipText: 'Clique para mais detalhes'
  }
};

const propTypes = {
  className: PropTypes.string,
  task: PropTypes.object,
  entity: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.oneOf(['organization', 'person', 'deal'])
  })
};

const defaultProps = {
  className: null,
  task: null
};

const DEFAULT_TASK = { status: 'empty', type: 'alert' };

function TaskIcon({ className, task, entity }) {
  const [popoverShown, setPopoverShown] = useState(false);

  const taskObj = Task.build(task);

  const { status, type } = taskObj || DEFAULT_TASK;
  const variant = VARIANTS[status] || VARIANTS[DEFAULT_TASK.status];
  const { secondaryIcon, btnVariant, textVariant, tooltipText } = variant;

  return (
    <Tooltip
      placement='top'
      content={
        <span>
          {taskObj && <span>{taskObj.description}.<br /></span>}
          {tooltipText}
        </span>
      }
      hide={popoverShown}
      className='pe-none'
    >
      <div className={classnames(
        className,
        'position-relative',
        'd-inline-block',
        'p-1'
      )}>
        <TaskPopover
          variant='nextTask'
          task={taskObj}
          onEnter={() => setPopoverShown(true)}
          onExit={() => setPopoverShown(false)}
          entity={entity}
        >
          <Button
            className={classnames(
              'p-2',
              'rounded-3',
              'lh-0',
              `text-${textVariant}`
            )}
            variant={btnVariant}
            onClick={(event) => event.stopPropagation()}
            as={taskObj ? undefined : EntityLink}
            entity={entity}
            aria-label='Próxima tarefa'
          >
            <Icon
              name={type || DEFAULT_TASK.type}
              className={classnames({
                'invisible': popoverShown
              })}
            />

            {secondaryIcon &&
              <Icon
                className={classnames('position-absolute', {
                  'bottom-0 end-0 drop-shadow-white': !popoverShown,
                  'top-50 start-50 translate-middle': popoverShown
                })}
                name={secondaryIcon}
              />
            }
          </Button>
        </TaskPopover>
      </div>
    </Tooltip>
  );
}

TaskIcon.propTypes = propTypes;
TaskIcon.defaultProps = defaultProps;

export default TaskIcon;
