import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import { useAlert } from 'react-alert';
import * as Yup from 'yup';
import set from 'lodash/set';
import toPath from 'lodash/toPath';
import _ from 'lodash';
import Icon from '@/components/Icon';
import Form from '@/components/Form';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import Modal from '@/components/Modal';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';

import {
  default as RequiredFieldInput, getFieldName, getFieldSchema
} from '@/components/Inputs/RequiredFieldInput';
import APIErrorMessage from '@/components/APIErrorMessage';
import {
  useUpdateOrganization, useUpdatePerson, useUpdateDeal, getDealRequiredFields
} from '@/api';
import { DEAL_STATUSES } from '@/utils';

function RequiredFieldsModal(props) {
  const { fields, entityType, field, onHide, onFinish, deal, params, ...otherProps } = props;
  const alert = useAlert();
  const updateOrganizationMutation = useUpdateOrganization();
  const updatePersonMutation = useUpdatePerson();
  const updateDealMutation = useUpdateDeal();

  const { user } = useAuth();
  const tracker = useTracking();

  const onSubmit = async (formData) => {
    if (formData.organization) {
      const organizationId = deal.organization?.id || deal.person?.organizationId;
      if (organizationId) {
        try {
          await updateOrganizationMutation.mutateAsync({
            organizationId,
            params: formData.organization
          });
        } catch (err) {
          alert.show(
            <APIErrorMessage
              err={err}
              action='update'
              resource='organization'
            />,
            { variant: 'danger' }
          );

          return;
        }
      }
    }

    if (formData.person) {
      const personId = deal.person?.id;
      if (personId) {
        try {
          await updatePersonMutation.mutateAsync({
            personId,
            params: formData.person
          });
        } catch (err) {
          alert.show(
            <APIErrorMessage
              err={err}
              action='update'
              resource='person'
            />,
            { variant: 'danger' }
          );

          return;
        }
      }
    }

    try {
      const mergedParams = _.merge({}, params, formData.deal);
      const { data: { value } } = await updateDealMutation.mutateAsync({
        dealId: deal.id,
        params: mergedParams
      });

      tracker.trackRequiredFieldFilled({
        user,
        funnelId: mergedParams.funnel_id,
        stageId: mergedParams.stage_id,
        dealStatus: mergedParams.deal_status_id
      });

      // Inclui apenas o valor atualizado do negócio.
      onFinish({ value });
    } catch (err) {
      alert.show(
        <APIErrorMessage
          err={err}
          action='update'
          resource='deal'
        />,
        { variant: 'danger' }
      );
    }
  };

  const defaultValues = useMemo(() => getDefaultValues(fields), [fields]);
  const validationSchema = useMemo(() => generateValidationSchema(fields), [fields]);

  return (
    <Modal bodyClassName='p-8' onHide={onHide} { ...otherProps }>
      <div className='text-center'>
        <div className={classnames(
          'd-inline-block',
          'p-3',
          'rounded-circle',
          'bg-dark-gray',
          'text-white',
          'lh-0',
          'position-relative'
        )}>
          <Icon name='listing' size='lg'/>
        </div>
      </div>

      <div className='mt-2 mb-8'>
        <h2 className={classnames(
          'font-weight-bold',
          'mx-3',
          'pt-1',
          'pb-6',
          'text-center'
        )}>
          Ops, tem campos importantes para preencher
        </h2>
      </div>
      <Form
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <>
            <div className={classnames(
              'w-100',
              'px-3',
              'pt-3',
              'border',
              'border-light',
              'border-1',
              'rounded',
              'text-dark-gray',
              'max-height-5',
              'overflow-y-scroll'
            )}>
              {fields.map((requiredField) => (
                <RequiredFieldInput
                  key={requiredField.id}
                  field={requiredField}
                />
              ))}
            </div>

            <div className='d-flex justify-content-end mt-8'>
              <Button
                variant='outline-dark-gray'
                className='me-3'
                onClick={onHide}
              >
                Fechar
              </Button>

              <LoadingButton
                type='submit'
                isLoading={isSubmitting}
              >
                Salvar
              </LoadingButton>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
}

function getDefaultValues(data) {
  return data?.reduce(
    (result, requiredField) => {
      const name = getFieldName(requiredField);

      set(result, name, '');

      return result;
    },
    {}
  );
}

function generateValidationSchema(data) {
  return data?.reduce((schema, requiredField) => {
    const name = getFieldName(requiredField);
    const path = toPath(name);

    return recursivelyMergeSchemas(schema, generateFieldSchema(path, requiredField));
  }, Yup.object().shape());
}

function generateFieldSchema(path, field) {
  const [segment, ...rest] = path;

  if (rest.length > 0) {
    return Yup.object().shape({
      [segment]: generateFieldSchema(rest, field)
    });
  } else {
    const fieldSchema = getFieldSchema(field);

    return Yup.object().shape({
      [segment]: fieldSchema.nullable().required('Por favor, preencha este campo.')
    });
  }
}

function recursivelyMergeSchemas(baseSchema, additionalSchema) {
  const baseFields = baseSchema.fields || {};
  const addedField = additionalSchema.fields || {};

  return Yup.object().shape({
    ...baseFields,
    ...Object.keys(addedField).reduce((acc, key) => {
      const hasKeyConflict = key in baseFields;

      acc[key] = hasKeyConflict
        ? recursivelyMergeSchemas(baseFields[key], addedField[key])
        : addedField[key];
      return acc;
    }, {})
  });
}

export default RequiredFieldsModal;

export function useRequiredFieldsModal() {
  const [show, setShow] = useState(false);
  const [fields, setFields] = useState(null);
  const [deal, setDeal] = useState(null);
  const [params, setParams] = useState(null);

  const onClose = () => setShow(false);

  const check = async (updateDeal, updateParams) => {
    if (
      updateParams.stage_id || (
        updateParams.deal_status_id &&
        updateParams.deal_status_id !== DEAL_STATUSES.ONGOING
      )
    ) {
      const targetParams = updateParams.stage_id
        ? { stage_id: updateParams.stage_id }
        : {
          deal_status_id: updateParams.deal_status_id,
          loss_reason_id: updateParams?.loss_reason_id,
          loss_reason_description: updateParams?.loss_reason_description
        };

      const { data } = await getDealRequiredFields({
        dealId: updateDeal.id,
        params: {
          funnel_id: updateParams.funnel_id,
          ...targetParams
        }
      });

      if (data.length > 0) {
        setDeal(updateDeal);
        setParams(updateParams);
        setFields(data);
        setShow(true);

        return true;
      }
    }
  };

  return {
    check,
    show,
    fields,
    deal,
    params,
    onHide: onClose,
    onFinish: onClose
  };
}
