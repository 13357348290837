import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Badge from '@/components/Badge';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';

RestrictWarningBadge.defaultProps = {
  entity: null,
  loading: true
};

RestrictWarningBadge.propTypes = {
  entity: PropTypes.object,
  loading: PropTypes.bool
};


function RestrictWarningBadge({ entity, loading }) {
  const privacyUserNames = useMemo(() => (
    entity?.privacy?.additionalPrivacies?.map((privacy) => privacy.name) || []
  ), [entity]);

  if (loading || !entity || entity?.privacy?.type !== 'closed') {
    return null;
  }

  return (
    <div>
      <Tooltip
        content={
          `Apenas você${privacyUserNames.length === 0 ? '' : ', '}
          ${privacyUserNames.join(', ')} e o grupo Administradores possuem acesso`
        }
      >
        <Badge
          className='ms-3 pe-3 text-medium'
          variant='dark-gray'
        >
          <Icon name='lock' className='me-1 ms-1' />
          Acesso restrito
        </Badge>
      </Tooltip>
    </div>
  );
}

export default RestrictWarningBadge;
