import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { EditInPlace, MaskedInput } from '@/components/Inputs';
import { applyMask } from '@/masks';

function CnpjMaskerInputField({
  inputValue,
  labelName,
  inputName,
  onEditEntity
}) {
  const renderValue = (value, defaultText = 'Adicionar') => (
    applyMask(value, 'cnpj') || <span className='text-medium-gray'>{defaultText}</span>
  );

  return (
    <Row className='align-items-center mb-3'>
      <Col sm={4}>
        <span className='fw-bold text-small'>{labelName}</span>
      </Col>
      <Col sm={8}>
        <EditInPlace
          name={inputName}
          as={MaskedInput}
          value={inputValue}
          onConfirm={onEditEntity}
          maskType='cnpj'
        >
          <div className='white-space-nowrap'>
            {renderValue(inputValue)}
          </div>
        </EditInPlace>
      </Col>
    </Row>
  );
}

export default CnpjMaskerInputField;
