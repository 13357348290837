import React from 'react';
import PropTypes from 'prop-types';
import SortInput from '@/components/Inputs/SortInput';

const propTypes = {
  withoutRanking: PropTypes.bool
};

const defaultProps = {
  withoutRanking: false
};

function CustomerSortInput({ withoutRanking, ...props }) {
  const options = [
    {
      label: 'Última Atualização',
      value: 'updated_at',
      direction: { type: 'date', defaultValue: 'desc' }
    },
    {
      label: 'Ordem Alfabética',
      value: 'name',
      direction: { type: 'text', defaultValue: 'asc' }
    },
    !withoutRanking && {
      label: 'Ranking',
      value: 'ranking',
      direction: { type: 'number', defaultValue: 'desc' }
    },
    {
      label: 'Data de Cadastro',
      value: 'created_at',
      direction: { type: 'date', defaultValue: 'desc' }
    }
  ].filter(Boolean);

  return (
    <SortInput
      { ...props }
      options={options}
    />
  );
}

CustomerSortInput.propTypes = propTypes;
CustomerSortInput.defaultProps = defaultProps;

export default CustomerSortInput;
