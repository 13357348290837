import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { formatDate } from '@/date';
import { useDeleteProposalModel, useDuplicateProposalModel } from '@/api';
import Truncate from '@/components/Truncate';
import Card from '@/components/Card';
import Icon from '@/components/Icon';
import ConfirmationModal from '@/components/ConfirmationModal';
import Tooltip from '@/components/Tooltip';
import ProposalRenameModal from '@/components/Proposal/ProposalRenameModal';
import FeatureControlPopover from '@/components/FeatureControlPopover';
import ProposalModelsDropdownMenu from '@/components/Proposal/ProposalModelsDropdownMenu';

ProposalModelCard.propTypes = {
  proposalModel: PropTypes.object,
  onOpenProposal: PropTypes.func,
  onRename: PropTypes.func,
  onEdit: PropTypes.func,
  disabledCreate: PropTypes.bool
};

ProposalModelCard.defaultProps = {
  proposalModel: {},
  onOpenProposal: () => {},
  onRename: () => {},
  onEdit: () => {},
  disabledCreate: false
};

function ProposalModelCard(props) {
  const {
    proposalModel, onEdit, onRename, onOpenProposal, disabledCreate
  } = props;

  const alert = useAlert();
  const { user } = useAuth();
  const tracker = useTracking();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const deleteMutation = useDeleteProposalModel();
  const duplicateModelMutation = useDuplicateProposalModel();
  const createdBy = proposalModel?.createdBy?.name;
  const hasAccess = user?.account.plan.name === 'Pro';
  const hasFullAccess = user.account.legacyPro || user.account.performanceOrHigher;
  const isFirstModel = proposalModel?.firstModel;

  const handleDeleteProposalModel = async () => {
    tracker.trackProposalModelDeleted({ user });
    await deleteMutation.mutateAsync(
      { id: proposalModel.id },
      {
        onSuccess: () => {
          alert.show('Modelo excluído com sucesso!', {
            variant: 'success',
            timeout: 5000
          });
          setShowDeleteModal(false);
        },
        onError: () => {
          alert.show('Não foi possível excluir o modelo.', {
            variant: 'danger',
            timeout: 5000
          });
          setShowDeleteModal(false);
        }
      }
    );
  };

  const handleDuplicateProposalModel = async () => {
    await duplicateModelMutation.mutateAsync(
      { id: proposalModel.id },
      {
        onSuccess: () => {
          alert.show('Modelo duplicado com sucesso!', {
            variant: 'success',
            timeout: 5000
          });
        },
        onError: () => {
          alert.show('Não foi possível duplicar o modelo.', {
            variant: 'danger',
            timeout: 5000
          });
        }
      }
    );
  };

  const verifyPlan = () => {
    if (hasFullAccess || (hasAccess && isFirstModel)) {
      setShowPopover(false);
      onOpenProposal(proposalModel.id);
    } else {
      setShowPopover(true);
    }
  };

  const isInsideDropdown = (initialTarget) => {
    const dropdownIds = ['proposal-model-menu-toggle', 'proposal-model-menu'];
    let currentTarget = initialTarget;
    while (currentTarget && currentTarget !== document.body) {
      if (dropdownIds.includes(currentTarget.id)) {
        return true;
      }
      currentTarget = currentTarget.parentNode;
    }
    return false;
  };

  const handleClickEvent = (event) => {
    if (isInsideDropdown(event.target)) {
      setShowPopover(false);
    } else {
      verifyPlan();
    }
  };

  const getDropdownStyles = (iconPro, iconFree, unlocked) => {
    const iconName = unlocked ? iconPro : iconFree;
    const textClass = unlocked ? 'text-darker-gray fw-bold' : 'text-medium-gray fw-bold';
    return {
      iconClass: <Icon name={iconName} className={`me-2 ${textClass}`} />,
      textClass
    };
  };

  const editStyle = getDropdownStyles('edit', 'lock', hasFullAccess || (hasAccess && isFirstModel));
  const renameStyle = getDropdownStyles('edit-field', 'lock', hasFullAccess ||
  (hasAccess && isFirstModel));
  const copyStyle = getDropdownStyles('copy', 'lock', hasFullAccess || (hasAccess && isFirstModel));
  const deleteStyle = getDropdownStyles('delete', 'lock', hasFullAccess ||
  (hasAccess && isFirstModel));

  return (
    <>
      <FeatureControlPopover
        showPopover={showPopover}
        user={user}
        placement='right-start'
      >
        <Card
          className='p-3 border-1 cursor-pointer min-height-4 h-100 hover-bg-light'
          onClick={handleClickEvent}
        >
          <Card.Body className='d-flex flex-column justify-content-between p-0'>
            <div className='d-flex justify-content-between'>
              {hasFullAccess || (hasAccess && isFirstModel)
                ? <Icon name='proposal' />
                : <Icon name='lock' />}
              <ProposalModelsDropdownMenu
                proposalModel={proposalModel}
                onEdit={onEdit}
                onDuplicate={handleDuplicateProposalModel}
                onDelete={() => setShowDeleteModal(true)}
                setShowRenameModal={setShowRenameModal}
                editStyle={editStyle}
                renameStyle={renameStyle}
                copyStyle={copyStyle}
                deleteStyle={deleteStyle}
                hasAccess={hasFullAccess || (hasAccess && isFirstModel)}
                disabledCreate={disabledCreate}
              />
            </div>
            <Card.Content>
              <Truncate lines={2} className='h5 mb-1'>
                {proposalModel?.name ?? ''}
              </Truncate>
              <Tooltip content={`Por ${createdBy}`} hide={!createdBy} placement='bottom'>
                <span>Atualizado em {formatDate(proposalModel?.updatedAt)}</span>
              </Tooltip>
            </Card.Content>
          </Card.Body>
        </Card>
      </FeatureControlPopover>

      <ConfirmationModal
        confirmationText='Confirmar'
        show={showDeleteModal}
        onConfirm={handleDeleteProposalModel}
        onHide={() => setShowDeleteModal(false)}
      >
        <ConfirmationModal.Title>Deseja excluir este modelo?</ConfirmationModal.Title>
        <ConfirmationModal.Info>Esta ação não poderá ser desfeita</ConfirmationModal.Info>
      </ConfirmationModal>
      <ProposalRenameModal
        show={showRenameModal}
        title='Renomear modelo'
        icon='edit-field'
        onSubmit={onRename}
        onHide={() => setShowRenameModal(false)}
        proposalModel={proposalModel}
      />
    </>
  );
}

export default ProposalModelCard;
