import React from 'react';
import Avatar from '@/components/Avatar';
import { legacyUserUrl } from '@/routes';
import { useAuth } from '@/lib/auth';
import ExternalLink from '@/components/ExternalLink';

function HeaderUser() {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <div
      className='d-flex align-self-center align-items-center me-2 me-xl-4'
    >
      <ExternalLink href={legacyUserUrl(user.id)}>
        <Avatar
          name={user.name}
          url={user.avatarUrl}
          size='lg'
          tooltip={false}
        />
      </ExternalLink>
    </div>
  );
}

export default HeaderUser;
