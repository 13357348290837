import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon';
import classNames from 'classnames';

const propTypes = {
  entityType: PropTypes.oneOf(['organization', 'person']).isRequired,
  avatarUrl: PropTypes.string.isRequired
};

function HeaderAvatar({ entityType, avatarUrl }) {
  return (
    <div className='width-3 height-3 rounded overflow-hidden me-3'>
      {
        avatarUrl
          ? (
            <img
              className='w-100 h-100 object-fit-cover'
              src={avatarUrl}
              alt='logotipo'
            />
          )
          : (
            <div
              className={classNames(
                'w-100 h-100 d-flex align-items-center',
                'justify-content-center bg-white text-dark-gray'
              )}
            >
              <Icon name={entityType} size='md' />
            </div>
          )
      }
    </div>
  );
}

HeaderAvatar.propTypes = propTypes;

export default HeaderAvatar;
