import React from 'react';
import { Col, Row } from 'react-bootstrap';
import classnames from 'classnames';

import Avatar from '@/components/Avatar';
import Truncate from '@/components/Truncate';
import { EditInPlace, UserSelect } from '@/components/Inputs';

function EditUserField({
  labelName,
  avatarId,
  selectName,
  onEditEntity,
  avatarName,
  avatarUrl,
  canEditPolicy
}) {
  const onwerUserAvatarPresent = avatarName || avatarUrl;
  return (
    <Row className='align-items-center mb-3'>
      <Col sm={4}>
        <span className='fw-bold text-small'>{labelName}</span>
      </Col>
      <Col sm={8}>
        <EditInPlace
          as={UserSelect}
          name={selectName}
          placeholder='Selecione'
          label=''
          value={avatarId}
          onConfirm={onEditEntity}
          isSearchable
          isSelect
          policy={canEditPolicy}
        >
          <div className='d-flex align-items-center'>
            {
              onwerUserAvatarPresent
                ? (
                  <Avatar
                    className='me-1'
                    name={avatarName}
                    url={avatarUrl}
                  />
                )
                : null
            }
            <Truncate
              className={classnames(
                { 'max-width-4': onwerUserAvatarPresent },
                { 'text-medium-gray': !onwerUserAvatarPresent },
                { 'text-medium': onwerUserAvatarPresent }
              )}
            >
              {avatarName ?? 'Adicionar'}
            </Truncate>
          </div>
        </EditInPlace>
      </Col>
    </Row>
  );
}

export default EditUserField;
