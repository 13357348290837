import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Avatar from '@/components/Avatar';
import Truncate from '@/components/Truncate';

function UserField({ labelName, avatarName, avataerUrl }) {
  return (
    <Row className='align-items-center mb-3'>
      <Col sm={4}>
        <span className='fw-bold text-small'>{labelName}</span>
      </Col>
      <Col sm={8} className='d-flex align-items-center'>
        <Avatar
          className='me-1'
          name={avatarName}
          url={avataerUrl}
        />
        <Truncate className='max-width-4 text-medium'>
          {avatarName}
        </Truncate>
      </Col>
    </Row>
  );
}

export default UserField;
