import React from 'react';

import { EditInPlace, WhatsAppInput } from '@/components/Inputs';
import Popover from '@/components/Popover';
import ContactPhoneOptions from '@/components/WhatsApp/ContactPhoneOptions';

function AvailablePhoneField({
  previewValue,
  inputValue,
  inputName,
  entity,
  entityType,
  onEditEntity,
  voip
}) {
  const hasPhone = Boolean(inputValue);

  return (
    <Popover
      showArrow
      flip
      placement='right'
      hide={!hasPhone}
      content={() => (
        <ContactPhoneOptions
          entityTypeModal={entityType}
          phone={inputValue}
          contactName={entity.name}
          entity={entity}
          voip={voip}
        />
      )}>
      <div className='white-space-nowrap'>
        <EditInPlace
          name={inputName}
          label=''
          as={WhatsAppInput}
          value={inputValue}
          onConfirm={onEditEntity}
          disableLabelClick={hasPhone}
        >
          <div className='white-space-nowrap'>
            {previewValue || <span className='text-medium-gray'>Adicionar telefone</span>}
          </div>
        </EditInPlace>
      </div>
    </Popover>
  );
}

export default AvailablePhoneField;
