import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '@/components/Dropdown';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import classnames from 'classnames';

const propTypes = {
  proposalModel: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  setShowRenameModal: PropTypes.func.isRequired,
  editStyle: PropTypes.object.isRequired,
  renameStyle: PropTypes.object.isRequired,
  copyStyle: PropTypes.object.isRequired,
  deleteStyle: PropTypes.object.isRequired,
  hasAccess: PropTypes.bool.isRequired,
  disabledCreate: PropTypes.bool.isRequired
};

function ProposalModelsDropdownMenu({
  proposalModel,
  onEdit,
  onDuplicate,
  onDelete,
  setShowRenameModal,
  editStyle,
  renameStyle,
  copyStyle,
  deleteStyle,
  hasAccess,
  disabledCreate
}) {
  return (
    <Dropdown>
      <Dropdown.Toggle
        id='proposal-model-menu-toggle'
        variant='transparent-light'
        className='p-2 mt-n2 me-n2'>
        <Icon name='vertical-dots' className='text-darker-gray' />
      </Dropdown.Toggle>

      <Dropdown.Menu id='proposal-model-menu' renderOnMount className='width-5'>
        <Dropdown.Item
          as='button'
          className={editStyle.textClass}
          onClick={() => onEdit(proposalModel.id)}
          disabled={!hasAccess}
        >
          {editStyle.iconClass}
          Editar modelo
        </Dropdown.Item>

        <Dropdown.Divider />

        <Dropdown.Item
          as='button'
          className={renameStyle.textClass}
          onClick={() => setShowRenameModal(true)}
          disabled={!hasAccess}
        >
          {renameStyle.iconClass}
          Renomear
        </Dropdown.Item>

        <Dropdown.Divider />

        <Tooltip
          placement='top'
          content={
            'Limite de 50 modelos atingido na conta. ' +
            'Exclua ou edite algum modelo existente'
          }
          hide={!disabledCreate}
        >
          <div className={classnames(
            { 'opacity-50 cursor-default not-allowed': disabledCreate }
          )}>
            <Dropdown.Item
              as='button'
              className={copyStyle.textClass}
              disabled={!hasAccess || disabledCreate}
              onClick={onDuplicate}
            >
              {copyStyle.iconClass}
                Duplicar
            </Dropdown.Item>
          </div>
        </Tooltip>

        <Dropdown.Divider />

        <Dropdown.Item
          as='button'
          className={deleteStyle.textClass}
          onClick={onDelete}
          disabled={!hasAccess}
        >
          {deleteStyle.iconClass}
          Excluir
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

ProposalModelsDropdownMenu.propTypes = propTypes;

export default ProposalModelsDropdownMenu;
