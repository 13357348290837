import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';

import { useCurrentPlan, useUpdateSubscriptionPaymentMethod } from '@/api';
import { useDocumentTitle, useNextVisibleTime } from '@/hooks';
import { useOffcanvasPageContext } from '@/contexts';
import { settingsManageCurrentPlanPath } from '@/routes';

import Button from '@/components/Button';
import Footer from '@/components/Footer';
import Icon from '@/components/Icon';
import LoadingButton from '@/components/LoadingButton';
import Main from '@/components/Main';
import PaymentMethodForm from '@/components/Billing/PaymentMethodForm';
import { paymentMethodKey } from '@/components/HeaderPaymentMethod';

const PAGE_TITLE = 'Método de pagamento';
const PAGE_SUBTITLE = 'Escolha uma opção para renovação da sua assinatura';

const PAYMENT_TYPE_IDS = {
  credit_card: 13,
  bank_slip: 14,
  pix: 15
};

const PAYMENT_DATA = {
  paymentMethod: { id: null }
};

function PaymentMethod() {
  useDocumentTitle(PAGE_TITLE);
  const canvasContext = useOffcanvasPageContext();
  const navigate = useNavigate();
  const alert = useAlert();
  const closePaymentMethodConfig = canvasContext.hide;
  const updatePaymentMethodMutation = useUpdateSubscriptionPaymentMethod();

  const [paymentType, setPaymentType] = useState('credit_card');
  const [paymentData, setPaymentData] = useState(PAYMENT_DATA);
  const [isLoading, setIsLoading] = useState(false);

  const { data: { data: planData } = {}, isSuccess: loadedCurrentPlan } = useCurrentPlan();
  const { isWarningVisible, handleWarningClose } = useNextVisibleTime(paymentMethodKey);

  const isPix = paymentType === 'pix';
  const isBankSlip = paymentType === 'bank_slip';
  const isEnabledButton = isPix || isBankSlip ||
    (paymentData.stripe && paymentData?.event?.complete);

  const handleCreditCardCreationError = () => {
    alert.show(
      <div>
        <h6>Infelizmente, não conseguimos atualizar seu método de pagamento</h6>
        <div>Por favor, tente novamente ou adicione um novo cartão.</div>
      </div>,
      { variant: 'danger' }
    );
  };

  const createCreditCardPaymentMethod = async () => {
    const { error, paymentMethod } = await paymentData.stripe.createPaymentMethod(
      {
        elements: paymentData.elements,
        params: {
          billing_details: {
            address: {
              country: 'BR'
            }
          }
        }
      }
    );

    if (error) {
      console.error(error);
      setIsLoading(false);
      handleCreditCardCreationError();

      return { error };
    }

    return {
      payment_method_id: paymentMethod.id,
      payment_type_id: PAYMENT_TYPE_IDS.credit_card,
      details: {
        last_four_digits: paymentMethod.card.last4,
        expiration_month: paymentMethod.card.exp_month,
        expiration_year: paymentMethod.card.exp_year
      }
    };
  };

  const confirmUpdatePaymentMethod = (params) => {
    updatePaymentMethodMutation.mutate(params, {
      onSuccess: () => {
        alert.show(
          'Método de pagamento alterado com sucesso!',
          { variant: 'success' }
        );

        closePaymentMethodConfig();
      },
      onError: () => {
        alert.show(
          'Não foi possível alterar seu método de pagamento.',
          { variant: 'danger' }
        );
        setIsLoading(false);
      }
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    let confirmParams = null;

    if (isPix) {
      confirmParams = { payment_type_id: PAYMENT_TYPE_IDS.pix };
    } else if (isBankSlip) {
      confirmParams = { payment_type_id: PAYMENT_TYPE_IDS.bank_slip };
    } else {
      confirmParams = await createCreditCardPaymentMethod();
      if (confirmParams.error) {
        return;
      }
    }

    confirmUpdatePaymentMethod(confirmParams);
  };

  if (!loadedCurrentPlan) {
    return null;
  }

  const isCreditCardIncomplete = planData.paymentInfo?.method === 'credit_card' &&
    !planData.paymentInfo?.paymentMethodId;

  const allowMonthlyBankSlipPix = planData.allowMonthlyBankSlipPix;

  const allowAllPaymentMethods = isCreditCardIncomplete || allowMonthlyBankSlipPix;

  return (
    <>
      <Main fluid className='pt-6 px-5'>
        <Container className='d-flex h-100 flex-column'>
          <div className='d-flex align-items-start'>
            <div className='flex-fill'>
              <h2 className='mb-2'>{PAGE_TITLE}</h2>
            </div>

            <Button
              className={'rounded border-0 px-2 py-1'}
              variant='light-gray'
              type='button'
              aria-label='Fechar'
              onClick={closePaymentMethodConfig}
            >
              <Icon className='text-dark-gray' name='close' />
            </Button>
          </div>

          <p>{PAGE_SUBTITLE}</p>

          {isCreditCardIncomplete && isWarningVisible && (
            <div className='p-4 rounded mb-2 bg-light-yellow'>
              <div className='col d-flex align-items-center justify-content-between'>
                <div className='text-medium'>
                  Não faremos nenhuma cobrança agora, isso só ocorrerá na renovação.
                  Esse é um processo seguro do Agendor <b>para evitar o bloqueio da sua conta</b>.
                </div>
                <div className='cursor-pointer ms-1 me-2'>
                  <Icon
                    name='close'
                    size='md'
                    onClick={handleWarningClose}
                  />
                </div>
              </div>
            </div>
          )}

          <hr className='mb-7' />

          <span className='fw-bold text-dark-gray text-small mb-2'>Método de pagamento </span>

          <PaymentMethodForm
            isUpdatePaymentMethod
            allowAllPaymentMethods={allowAllPaymentMethods}
            paymentType={paymentType}
            setPaymentType={setPaymentType}
            setPaymentData={setPaymentData}
            period={planData.periodicity}
          />
        </Container>
      </Main>
      <Footer className='mt-auto flex-shrink-0 pb-6 ps-6 me-8' >
        <Container>
          <Row>
            <Col
              md={{ offset: 1, span: 10 }}
              className='d-flex'
            >
              <Button
                className='ms-auto me-3'
                variant='outline-dark-gray'
                disabled={isLoading}
                onClick={() => navigate(settingsManageCurrentPlanPath())}
              >
                Cancelar
              </Button>

              <LoadingButton
                onClick={handleSubmit}
                isLoading={isLoading}
                className='min-width-4 px-8'
                disabled={!isEnabledButton}
              >
                Salvar
              </LoadingButton>
            </Col>
          </Row>
        </Container>
      </Footer>
    </>
  );
}

export default PaymentMethod;
