import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';

import { parseDate, toDayOfWeek, toTextualDateString } from '@/date';
import { toCurrencyString } from '@/number';
import { applyMask } from '@/masks';

import Button from '@/components/Button';
import Card from '@/components/Card';
import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';

import checkImage from 'images/check.svg';

const propTypes = {
  data: PropTypes.shape({
    value: PropTypes.number.isRequired,
    expiresAt: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    pdf: PropTypes.string.isRequired
  })
};

function BankSlipCompletion({ data }) {
  const expirationDate = parseDate(data.expiresAt);
  const value = toCurrencyString(data.value);

  return (
    <Card className='p-4 border-light align-items-center'>
      <img className='width-3 mb-2' src={checkImage} />

      <h4 className='mb-2'>Boleto foi gerado</h4>

      <div className='text-dark-gray'>
        Quando o pagamento for processado, seu novo plano será liberado.
      </div>

      <hr className='w-100 mt-5 mb-6 text-dark-gray' />

      <div className='d-flex flex-column align-items-center text-small fw-bold text-dark-gray mb-4'>
        <div className='mb-1'>Cód. do boleto</div>

        <h4 className='mb-1 text-body'>{applyMask(data.number, 'bank_slip')}</h4>

        <div>Vencimento: {toDayOfWeek(expirationDate)}, {toTextualDateString(expirationDate)}</div>

        <div>Valor: {value}</div>
      </div>

      <div className='d-flex align-items-center mb-4'>
        <CopyToClipboard text={data.number}>
          <Button
            variant='outline-dark-gray'
            className='me-2'
          >
            <Icon name='copy' size='sm' />

            <span className='ms-2'>Copiar cód. do boleto</span>
          </Button>
        </CopyToClipboard>

        <Button
          as={ExternalLink}
          target='_blank'
          href={data.pdf}
        >
          <Icon name='download' size='sm' />

          <span className='ms-2'>Baixar boleto</span>
        </Button>
      </div>
    </Card>
  );
}

BankSlipCompletion.propTypes = propTypes;

export default BankSlipCompletion;
