import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Alert';
import classnames from 'classnames';
import Icon from '@/components/Icon';
import Button from '@/components/Button';

const propTypes = {
  variant: PropTypes.oneOf([
    'success',
    'danger',
    'light',
    'warning'
  ]).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func
};

const defaultProps = {
  className: null,
  onClose: null
};

const ICONS = {
  success: 'check',
  danger: 'alert',
  warning: 'alert',
  light: 'info'
};

const ICONCOLORS = {
  success: 'text-success',
  danger: 'text-danger',
  light: 'text-dark-gray'
};

function Alert({ children, className, variant, onClose }) {
  return (
    <Base
      className={
        classnames(
          'text-small',
          'd-inline-flex',
          'align-items-center',
          'pe-auto',
          className,
          { 'pe-2': onClose }
        )
      }
      variant={variant}
    >
      <div className='py-5'>
        <Icon name={ICONS[variant]} className={ICONCOLORS[variant]} />
      </div>

      <div className='p-3 me-auto flex-fill'>
        {children}
      </div>

      {
        onClose &&
        <div className='py-3'>
          <Button
            variant='link'
            className='p-2 border-0 text-darker-gray'
            onClick={onClose}
            aria-label='Fechar'
          >
            <Icon name='close' />
          </Button>
        </div>
      }
    </Base>
  );
}

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;

export default Alert;
