import React from 'react';
import PropTypes from 'prop-types';
import {
  CategorySelect,
  EditInPlace
} from '@/components/Inputs';

function SelectCategory({
  category,
  onUpdate,
  canEditPolicy
}) {
  return (
    <EditInPlace
      as={CategorySelect}
      name='category_id'
      label=''
      value={category?.id}
      tooltipString={`Responsável: ${category?.name}`}
      onConfirm={onUpdate}
      className='ms-2 mt-n2 width-5 min-width-3'
      policy={canEditPolicy}
      hideEditButtonWhenEmpty={Boolean(category)}
      isSelect
    >
      {category?.name || <span className='text-medium-gray'>Adicionar categoria</span>}
    </EditInPlace>
  );
}

SelectCategory.defaultProps = {
  ownerUser: {},
  onUpdate: () => { },
  canEditPolicy: () => { }
};

SelectCategory.propTypes = {
  ownerUser: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    avatarUrl: PropTypes.string
  }),
  onUpdate: PropTypes.func,
  canEditPolicy: PropTypes.func
};

export default SelectCategory;
