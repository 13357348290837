import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import { useAuth } from '@/lib/auth';
import { useCreateACContact } from '@/api';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import ModalAgendorChat from '@/components/WhatsApp/ModalAgendorChat';
import { useTracking } from '@/lib/tracking';
import { isOptin } from '@/utils';
import { useAgendorChatContext } from '@/contexts/agendor-chat';

WhatsappAndACButtons.propTypes = {
  name: PropTypes.string,
  phone: PropTypes.string,
  screen: PropTypes.string,
  entity: PropTypes.string,
  entityId: PropTypes.number
};

WhatsappAndACButtons.defaultProps = {
  name: '',
  phone: '',
  screen: '',
  entityId: 0,
  entity: ''
};

function WhatsappAndACButtons({ name, phone, entityId, entity, screen }) {
  const { user } = useAuth();
  const alert = useAlert();
  const tracker = useTracking();
  const [showACModal, setShowACModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const mutation = useCreateACContact();
  const hasAccountChat = user?.account?.accountChat;
  const isIframeAllowedAccount = isOptin(user?.features?.agendorChatIframeEnabled);

  const { openAgendorChat } = useAgendorChatContext();

  const handleOpenAgendorChat = () => {
    tracker.trackWhatsappUsed({
      user,
      action: 'Agendor chat',
      screen,
      location: 'Widget acoes'
    });
    setIsLoading(true);
    if (!hasAccountChat) {
      setIsLoading(false);
      setShowACModal(true);
    } else {
      const params = {
        name,
        phone_number: phone,
        entity,
        entity_id: entityId
      };
      mutation.mutate(params, {
        onSuccess: ({ url }) => {
          if (isIframeAllowedAccount) {
            openAgendorChat(url);
          } else {
            window.open(url, '_blank');
          }
          setIsLoading(false);
        },
        onError: () => {
          alert.show(`Seu usuário não está vinculado a uma caixa de entrada
            ou configurado como agente no Agendor Chat. Verifique com o administrador.`, {
            variant: 'danger',
            timeout: 10000
          });
          setIsLoading(false);
        }
      });
    }
  };

  const handleOpenWhatsApp = () => {
    tracker.trackWhatsappUsed({
      user,
      action: 'Whatsapp web',
      screen,
      location: 'Widget acoes'
    });
    window.open(`https://web.whatsapp.com/send?phone=${phone?.replace(/[\D]+/gu, '')}`, '_blank');
  };

  return (
    <>
      <ModalAgendorChat
        show={showACModal}
        onClose={() => setShowACModal(false)}
      />
      <div className='d-flex'>
        <LoadingButton
          size='sm'
          variant='light'
          loadingText='Carregando...'
          isLoading={isLoading}
          className='w-100 mb-2 me-2'
          onClick={handleOpenAgendorChat}
        >
          <Icon name='whatsapp' className='me-1 text-primary' />
          <span className='text-primary text-nowrap'>
            Agendor Chat
          </span>
        </LoadingButton>
        <Button
          size='sm'
          variant='light'
          className='w-100 mb-2'
          onClick={handleOpenWhatsApp}
        >
          <Icon name='whatsapp' className='me-1 text-primary' />
          <span className='text-primary text-nowrap'>
            WhatsApp
          </span>
        </Button>
      </div>
    </>
  );
}

export default WhatsappAndACButtons;
