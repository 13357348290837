import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useAuth } from '@/lib/auth';
import Icon from '@/components/Icon';
import Truncate from '@/components/Truncate';
import EntityLink, { isOrganizationModalEnabled } from '@/components/EntityLink';

RelatedEntityName.defaultProps = {
  className: ''
};

RelatedEntityName.propTypes = {
  relatedEntityType: PropTypes.string,
  relatedEntity: PropTypes.object,
  className: PropTypes.string
};

const IconWithText = ({ icon, text, className }) => (
  <span className={
    classnames(
      className,
      'd-flex align-items-center fw-bold'
    )}
  >
    <Icon name={icon} className='me-2' />
    {text}
  </span>
);

function RelatedEntityName({ relatedEntity, relatedEntityType }) {
  const { user } = useAuth();
  const target = isOrganizationModalEnabled(user) ? '' : '_blank';

  return (
    <>
      {
        relatedEntity
          ? (
            <EntityLink
              entity={{ type: relatedEntityType, id: relatedEntity.id }}
              target={target}
            >
              <Truncate>
                <IconWithText
                  className='text-primary'
                  icon={relatedEntityType}
                  text={relatedEntity.name}
                />
              </Truncate>
            </EntityLink>
          )
          : (
            <IconWithText
              className='text-dark-gray'
              icon='link'
              text='Nenhum contato relacionado'
            />
          )
      }
    </>
  );
}

export default RelatedEntityName;
