import React from 'react';

import Tabs from '@/components/Tabs';
import NavLink from '@/components/NavLink';
import { useChangeTab, useActiveTab } from '@/contexts/personModal';
import classnames from 'classnames';

const TABS = [
  {
    value: 'activities',
    label: 'Ver histórico'
  },
  {
    value: 'deals',
    label: 'Ver negócios'
  }
];

function PersonTabs() {
  const activeTab = useActiveTab();
  const changeTab = useChangeTab();

  return (
    <Tabs>
      {
        TABS.map(({ value, label }) => (
          <Tabs.Item key={value}>
            <NavLink className={classnames({ 'bg-gray bg-opacity-50': activeTab === value })}
              active={activeTab === value}
              onClick={() => changeTab(value)}
            >
              {label}
            </NavLink>
          </Tabs.Item>
        ))
      }
    </Tabs>
  );
}

export default PersonTabs;
