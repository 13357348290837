import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import Button from '@/components/Button';
import Modal from '@/components/Modal';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import { TextInput, TextArea } from '@/components/Inputs';
import LoadingButton from '@/components/LoadingButton';
import { propTypes } from 'react-bootstrap/esm/Image';

ActivitiesTemplatesModal.propTypes = {
  onCancel: PropTypes.func
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  name: Yup.string()
    .required('Por favor, informe o nome do modelo.')
    .max(60, 'Nome deve ter no máximo 60 caracteres.'),
  text: Yup.string()
    .required('Descrição é obrigatória')
});
/* eslint-enable no-magic-numbers */

const defaultValues = {
  name: '',
  text: ''
};

function ActivitiesTemplatesModal(props) {
  const { onCancel, onSubmit, ...otherProps } = props;

  return (
    <Modal className='p-8 sub-overlay' onHide={onCancel} { ...otherProps }>
      <div className='mt-2 mb-8'>
        <h4>Criar novo modelo</h4>
      </div>

      <Form
        name='create-activity-template-form'
        defaultValues={defaultValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <>
            <FormField
              as={TextInput}
              name='name'
              label='Nome do modelo'
              autoFocus
            />

            <FormField
              as={TextArea}
              name='text'
              label='Texto do modelo'
              placeholder='Digite algo'
            />

            <div className='d-flex justify-content-end mt-8'>
              <Button
                variant='light'
                className='me-3'
                onClick={onCancel}
              >
                Cancelar
              </Button>

              <LoadingButton
                isLoading={isSubmitting}
                type='submit'
              >
                Salvar modelo
              </LoadingButton>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
}

ActivitiesTemplatesModal.propTypes = propTypes;

export default ActivitiesTemplatesModal;
