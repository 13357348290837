import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import differenceInYears from 'date-fns/differenceInYears';
import isToday from 'date-fns/isToday';
import parseISO from 'date-fns/parseISO';
import { pluralize } from '@/utils';

import { EditInPlace, MaskedInput } from '@/components/Inputs';

function BirthYearField({
  birthday,
  birthYear,
  labelName,
  inputName,
  onEditEntity
}) {
  const renderValue = (value, defaultText = 'Adicionar') => (
    value || <span className='text-medium-gray'>{defaultText}</span>
  );

  const { age, isBirthdayToday } = useMemo(() => {
    if (!birthday && !birthYear) {
      return {};
    }

    const isoBirthday = parseISO(birthday);

    const realBirthday = isoBirthday.setYear(birthYear);

    return {
      age: differenceInYears(new Date(), realBirthday),
      isBirthdayToday: isToday(isoBirthday.setYear(new Date().getFullYear()))
    };
  }, [birthday, birthYear]);

  const isTodayString = isBirthdayToday ? ' Hoje!' : '';

  const ageString = age ? ` (${age} ${pluralize('ano', age)}${isTodayString})` : '';

  const inputValue = birthYear && `${birthYear}${ageString}`;

  return (
    <>
      <Row className='align-items-center mb-3'>
        <Col sm={4}>
          <span className='fw-bold text-small'>{labelName}</span>
        </Col>
        <Col sm={8}>
          <EditInPlace
            name={inputName}
            as={MaskedInput}
            value={birthYear?.toString()}
            placeholder='0000'
            onConfirm={onEditEntity}
            maskType='natural'
            autoComplete='off'
            maxLength={4}
          >
            {renderValue(inputValue)}
          </EditInPlace>
        </Col>
      </Row>
    </>
  );
}

export default BirthYearField;
