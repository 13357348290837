import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { QRCodeSVG } from 'qrcode.react';
import PropTypes from 'prop-types';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Icon from '@/components/Icon';

import { parseDate, toDateTimeString } from '@/date';
import { toCurrencyString } from '@/number';

const propTypes = {
  data: PropTypes.shape({
    qrCode: PropTypes.string.isRequired,
    expiresAt: PropTypes.string.isRequired
  })
};

function PixCompletion({ data }) {
  return (
    <Card className='p-4 border-light'>
      <div className='d-flex flex-column align-items-center mb-2'>
        <h2 className='mb-4'>Código do Pix gerado</h2>

        <div className='mb-4 text-body'>
          <QRCodeSVG
            fgColor='currentColor'
            value={data.qrCode}
            size='172'
          />
        </div>

        <CopyToClipboard text={data.qrCode}>
          <Button
            variant='lighter-blue'
            className='mb-2 rounded-sm text-primary text-small'
          >
            <Icon name='copy' size='sm' className='me-2' />

            Copiar código Pix
          </Button>
        </CopyToClipboard>

        <span className='text-smaller text-dark-gray'>
          Vencimento: {toDateTimeString(parseDate(data.expiresAt))}
        </span>

        <span className='text-smaller text-dark-gray'>
          Valor: {toCurrencyString(data.value)}
        </span>
      </div>

      <ol type='1' className='ps-4 text-smaller text-dark-gray'>
        <li>
          Acesse o app do seu banco ou internet banking e escolha &quot;Pagar com Pix&quot;.
        </li>

        <li>
          Copie e cole o código acima ou escaneie o QR code.
        </li>

        <li>
          Confira as informações.
        </li>

        <li>
          Finalize o pagamento.
        </li>
      </ol>
    </Card>
  );
}

PixCompletion.propTypes = propTypes;

export default PixCompletion;
