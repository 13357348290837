import React from 'react';
import PropTypes from 'prop-types';
import FetchableSelect from '@/components/Inputs/FetchableSelect';
import { useOrganizations } from '@/api';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  allowNull: PropTypes.bool,
  onChange: PropTypes.func
};

const defaultProps = {
  name: 'organization_id_in',
  label: 'Organization',
  placeholder: 'Qualquer empresa',
  multiple: false,
  isClearable: true,
  isSearchable: true,
  defaultValue: null,
  allowNull: false,
  onChange: () => {}
};

function OrganizationSelect(props) {
  const transformer = ({ data }) => {
    const result = [];
    if (props.allowNull) {
      result.push({ label: 'Indefinido', value: -1 });
    }
    const options = data?.map((item) => ({ label: item.name, value: item.id }));
    return result.concat(options);
  };

  return (
    <FetchableSelect
      { ...props }
      query={useOrganizations}
      transformer={transformer}
    />
  );
}

OrganizationSelect.propTypes = propTypes;
OrganizationSelect.defaultProps = defaultProps;

export default OrganizationSelect;
