import React, { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import classnames from 'classnames';

import History from '@/components/EntityModal/Activities/History';
import IconLabel from '@/components/IconLabel';
import Skeleton from '@/components/Skeleton';
import { useScreenSize } from '@/hooks';

const NAVS = {
  activitiesHistory: 'activitiesHistory',
  entityDetails: 'entityDetails'
};

function ActivityNavItem({ label, iconName, active, loading, onClick }) {
  if (loading) {
    return <Skeleton width={200} height={30} />;
  }

  return (
    <Nav.Item>
      <Nav.Link
        onClick={onClick}
        className={classnames(
          'me-4 px-2',
          { 'border-bottom border-darker-gray': active },
          { 'text-dark-gray': !active }
        )}
      >
        <IconLabel
          icon={{ name: iconName }}
          text={label}
          textSize='h4'
        />
      </Nav.Link>
    </Nav.Item>
  );
}

function ActivityNavs({
  entity,
  entityName,
  activities,
  isFetchingActivities,
  isFetchingNextPageActivities,
  fetchNextPageActivities,
  hasNextPageActivities,
  isLoading,
  onUpdateActivity,
  onDeleteActivity,
  detailsNavName,
  FilterComponent,
  children
}) {
  const { isMD, isUpperLG } = useScreenSize();
  const [navKey, setNavKey] = useState(NAVS.activitiesHistory);

  return (
    <div>
      {

        isUpperLG
          ? (
            <>
              <div className='mb-4 d-flex align-items-center'>
                <h4>Histórico de atividades</h4>
                {FilterComponent}
              </div>
              <History
                entity={entity}
                activities={activities}
                isFetchingActivities={isFetchingActivities}
                isFetchingNextPageActivities={isFetchingNextPageActivities}
                fetchNextPageActivities={fetchNextPageActivities}
                hasNextPageActivities={hasNextPageActivities}
                loading={isLoading}
                onUpdateActivity={onUpdateActivity}
                onDeleteActivity={onDeleteActivity}
                entityName={entityName}
              />
            </>
          )
          : (
            <div className='pt-2'>
              <Tab.Container
                activeKey={navKey}
                defaultActiveKey={NAVS.activitiesHistory}
              >
                <div className='d-flex'>
                  <Nav>
                    <ActivityNavItem
                      label='Histórico de atividades'
                      iconName='task'
                      active={navKey === NAVS.activitiesHistory}
                      loading={isLoading}
                      onClick={() => setNavKey(NAVS.activitiesHistory)}
                    />
                    {
                      isMD && (
                        <ActivityNavItem
                          label={detailsNavName}
                          iconName='info'
                          active={navKey === NAVS.entityDetails}
                          loading={isLoading}
                          onClick={() => setNavKey(NAVS.entityDetails)}
                        />
                      )
                    }
                  </Nav>

                  {(navKey === NAVS.activitiesHistory) && FilterComponent}
                </div>

                <Tab.Content
                  style={{ marginTop: '-1px' }}
                  className={classnames(
                    'p-4',
                    { 'bg-white': navKey === NAVS.activitiesHistory }
                  )}
                >
                  <Tab.Pane eventKey={NAVS.activitiesHistory}>
                    <History
                      entity={entity}
                      activities={activities}
                      isFetchingActivities={isFetchingActivities}
                      isFetchingNextPageActivities={isFetchingNextPageActivities}
                      fetchNextPageActivities={fetchNextPageActivities}
                      hasNextPageActivities={hasNextPageActivities}
                      loading={isLoading}
                      onUpdateActivity={onUpdateActivity}
                      onDeleteActivity={onDeleteActivity}
                      entityName={entityName}
                    />
                  </Tab.Pane>

                  <Tab.Pane eventKey={NAVS.entityDetails}>
                    {children}
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          )
      }
    </div>
  );
}

export default ActivityNavs;
