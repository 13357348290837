import { useCallback, useMemo } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useMixpanel } from '@/lib/mixpanel';
import { Tracker } from '@/lib/tracking/tracker';

export function useTracking() {
  const intercom = useIntercom();
  const mixpanel = useMixpanel();

  const intercomTrackerFn = useCallback((eventName, metadata = {}) => {
    try {
      intercom.trackEvent(eventName, metadata);
    } catch (error) {
      console.error(error);
    }
  }, [intercom]);

  const mixpanelTrackerFn = useCallback((eventName, metadata = {}) => {
    try {
      mixpanel.track(eventName, metadata);
    } catch (error) {
      console.error(error);
    }
  }, [mixpanel]);

  const mixpanelProfileFn = useCallback((properties) => {
    try {
      mixpanel.people.set(properties);
    } catch (error) {
      console.error(error);
    }
  }, [mixpanel]);

  const dataLayerTrackerFn = useCallback((data) => {
    try {
      if (window.dataLayer) {
        window.dataLayer.push(data);
      }
    } catch (error) {
      console.error(error);
    }
  });

  const tracker = useMemo(() => new Tracker({
    intercom: intercomTrackerFn,
    mixpanel: {
      trackerFn: mixpanelTrackerFn,
      profileFn: mixpanelProfileFn
    },
    dataLayer: dataLayerTrackerFn
  }), [intercomTrackerFn, mixpanelTrackerFn, mixpanelProfileFn, dataLayerTrackerFn]);

  return tracker;
}
