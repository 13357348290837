import React from 'react';
import BaseTable from '@/components/EntityModal/Table/BaseTable';
import PeopleTabEmpty from '@/components/EntityModal/PeopleTabEmpty';
import { convertDateTimeString } from '@/date';

const TABLE = {
  headers: [
    'Nome',
    'Cargo',
    'Email',
    'Telefone',
    'Data de Cadastro',
    'Data de Atualização'
  ],
  columns: [
    {
      callback: (value) => value,
      objectPath: 'jobTitle'
    },
    {
      callback: (value) => value,
      objectPath: 'contact.email'
    },
    {
      callback: (value) => value,
      objectPath: 'contact.availablePhone'
    },
    {
      callback: (value) => convertDateTimeString(value),
      objectPath: 'createdAt'
    },
    {
      callback: (value) => convertDateTimeString(value),
      objectPath: 'updatedAt'
    }
  ]
};

function PeopleTabTable({
  fetchNextPage,
  hasNextPage,
  isLoading,
  organizationId,
  items,
  itemsTotal
}) {
  return (
    <BaseTable
      entityType='person'
      headers={TABLE.headers}
      columns={TABLE.columns}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isLoading={isLoading}
      entityId={organizationId}
      items={items}
      itemsTotal={itemsTotal}
      EmptyComponent={<PeopleTabEmpty organizationId={organizationId} />}
    />
  );
}

export default PeopleTabTable;
