import React, { useMemo } from 'react';

import DetailsCard from '@/components/EntityModal/DetailsCard';
import ShowMoreDescription from '@/components/EntityModal/ShowMoreDescription';
import EditSelectField from '@/components/EntityModal/EditSelectField';
import DateField from '@/components/EntityModal/DateField';
import CpfMaskerInputField from '@/components/EntityModal/CpfMaskerInputField';
import EditInputField from '@/components/EntityModal/EditInputField';
import BirthdayField from '@/components/EntityModal/BirthdayField';
import BirthYearField from '@/components/EntityModal/BirthYearField';
import UserField from '@/components/EntityModal/UserField';
import EditUserField from '@/components/EntityModal/EditUserField';
import { canEditPerson } from '@/policies';
import DetailsCustomField from '@/components/EntityModal/DetailsCustomField';
import { CategorySelect, LeadOriginSelect } from '@/components/Inputs';
import OrganizationSelect from '@/components/EntityModal/OrganizationSelect';

function PersonBasicData({
  person,
  onEditEntity,
  entityType,
  entityCustomFields
}) {
  const basicDataCustomFields = useMemo(
    () => entityCustomFields.filter(({ section }) => section === 'basic_data'),
    [entityCustomFields]
  );

  return (
    <DetailsCard title='Dados básicos da pessoa' className='mb-3'>
      <ShowMoreDescription
        description={person?.description}
        onEditEntity={(params, done) => onEditEntity(params, done, { ...person })}
      />
      <CpfMaskerInputField
        inputValue={person?.cpf}
        labelName='CPF'
        inputName='cpf'
        onEditEntity={(params, done) => onEditEntity(params, done, { ...person })}
      />
      <EditSelectField
        as={CategorySelect}
        labelName='Categoria'
        selectName='category_id'
        onEditEntity={(params, done) => onEditEntity(params, done, { ...person })}
        entity={person.category}
        entityName={person.category?.name}
        entityId={person.category?.id}
      />
      <EditSelectField
        as={OrganizationSelect}
        labelName='Empresa'
        isEditInPlace
        selectName='organization_id'
        onEditEntity={(params, done) => onEditEntity(params, done, { ...person })}
        entity={person.organization}
        entityName={person.organization?.name}
        entityId={person.organization?.id}
        link={`?organizationId=${person.organizationId}`}
      />
      <EditInputField
        inputValue={person?.jobTitle}
        labelName='Cargo'
        inputName='job_title'
        onEditEntity={(params, done) => onEditEntity(params, done, { ...person })}
      />
      <BirthdayField
        inputValue={person?.birthday}
        labelName='Aniversário'
        inputName='birthday'
        onEditEntity={(params, done) => (
          onEditEntity(params, done, { ...person })
        )}
      />
      <BirthYearField
        birthYear={person?.birthYear}
        birthday={person?.birthday}
        labelName='Ano nasc.'
        inputName='birth_year'
        onEditEntity={(params, done) => onEditEntity(params, done, { ...person })}
      />
      <EditSelectField
        as={LeadOriginSelect}
        labelName='Origem'
        selectName='lead_origin_id'
        onEditEntity={(params, done) => onEditEntity(params, done, { ...person })}
        entity={person.leadOrigin}
        entityName={person.leadOrigin?.name}
        entityId={person.leadOrigin?.id}
      />
      <UserField
        labelName='Cadastrado por'
        avatarName={person.user?.name}
        avataerUrl={person.user?.avatarUrl}
      />
      <EditUserField
        labelName='Responsável'
        selectName='owner_user_id'
        avatarId={person?.ownerUser?.id}
        avatarName={person?.ownerUser?.name}
        avatarUrl={person?.ownerUser?.avatarUrl}
        onEditEntity={(params, done) => onEditEntity(params, done, { ...person })}
        canEditPolicy={canEditPerson}
      />
      <DateField labelName='Data de cadastro' stringDate={person?.createdAt} />
      <DateField labelName='Última atualização' stringDate={person?.updatedAt} />

      {basicDataCustomFields.map(
        ({ identifier, customFieldOptions, type, name }) => (
          <DetailsCustomField
            key={identifier}
            onEdit={(params, done) => onEditEntity(params, done, { ...person, entityType })}
            identifier={identifier}
            customFieldOptions={customFieldOptions}
            type={type}
            name={name}
            rawValue={person?.customFields[identifier]}
            placeholder='Adicionar'
          />
        )
      )}
    </DetailsCard>
  );
}

export default PersonBasicData;
