import React from 'react';
import { useAlert } from 'react-alert';
import ActivityForm from '@/components/EntityModal/Activities/Form';
import { useCreateActivity } from '@/api';
import { usePerson } from '@/contexts/personModal';
import APIErrorMessage from '@/components/APIErrorMessage';
import Skeleton from '@/components/Skeleton';

function Form() {
  const alert = useAlert();
  const person = usePerson();

  const createActivityMutation = useCreateActivity({ entityType: 'person' });

  const onCreateActivity = (params, done) => {
    createActivityMutation.mutate(params, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='activities' action='create' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Atividade cadastrada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        done?.();
      }
    });
  };

  if (!person) {
    return <Skeleton width='100%' height={160} className='mb-3' />;
  }

  return (
    <ActivityForm
      entityId={person.id}
      entityName='person'
      onCreate={onCreateActivity}
    />
  );
}

export default Form;
