import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { parseDate, toShortDateString } from '@/date';

import { EditInPlace, MaskedInput } from '@/components/Inputs';
import { applyMask } from '@/masks';

function BirthdayField({
  inputValue,
  labelName,
  inputName,
  onEditEntity
}) {
  const birthday = useMemo(() => (
    inputValue ? toShortDateString(parseDate(inputValue)) : ''
  ), [inputValue]);

  const renderValue = (value, defaultText = 'Adicionar') => (
    value || <span className='text-medium-gray'>{defaultText}</span>
  );

  return (
    <Row className='align-items-center mb-3'>
      <Col sm={4}>
        <span className='fw-bold text-small'>{labelName}</span>
      </Col>
      <Col sm={8}>
        <EditInPlace
          name={inputName}
          as={MaskedInput}
          value={applyMask(birthday, 'monthDay')}
          placeholder='00/00'
          onConfirm={onEditEntity}
          maskType='monthDay'
          autoComplete='off'
          maxLength={5}
        >
          {renderValue(birthday)}
        </EditInPlace>
      </Col>
    </Row>
  );
}

export default BirthdayField;
