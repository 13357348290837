import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const ongoingChecklistsKeys = {
  all: ['ongoing-checklist']
};

export async function getOngoingChecklist(params = {}) {
  const { data } = await get('/ongoing_checklist', params);

  return data;
}

export function useOngoingChecklist({ params = {}, config = {} }) {
  return useQuery({
    ...config,
    queryKey: ongoingChecklistsKeys.all,
    queryFn: () => getOngoingChecklist(params)
  });
}

export function checkOngoingChecklistItem(data, key) {
  const { data: items } = data;

  const index = items.findIndex((item) => item.key === key && !item.completed);
  if (index === -1) {
    return data;
  }

  const copy = [...items];

  copy.splice(index, 1, { ...items[index], completed: true });

  return { data: copy };
}
