import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Layout from '@/components/Layout';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { useOnboarding } from '@/feature/growth';


const propTypes = {};

const defaultProps = {};

function Page() {
  const { user } = useAuth();
  const tracker = useTracking();

  const { experimentName, activeVariant } = useOnboarding();

  useEffect(() => {
    if (user) {
      tracker.trackUserLoaded({
        user,
        abTest: { [experimentName]: activeVariant }
      });
    } else {
      throw new Error('Internal page was rendered without user.');
    }
  }, [user]);

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

Page.propTypes = propTypes;
Page.defaultProps = defaultProps;

export default Page;
