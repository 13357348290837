import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Truncate from '@/components/Truncate';
import { EditInPlace, TextInput } from '@/components/Inputs';

function EditInputField({
  inputValue,
  labelName,
  inputName,
  onEditEntity
}) {
  const renderValue = (value, defaultText = 'Adicionar') => (
    value || <span className='text-medium-gray'>{defaultText}</span>
  );

  return (
    <Row className='align-items-center mb-3'>
      <Col sm={4}>
        <span className='fw-bold text-small'>{labelName}</span>
      </Col>
      <Col sm={8}>
        <EditInPlace
          name={inputName}
          as={TextInput}
          value={inputValue}
          onConfirm={onEditEntity}
        >
          <Truncate>
            {renderValue(inputValue)}
          </Truncate>
        </EditInPlace>
      </Col>
    </Row>
  );
}

export default EditInputField;
