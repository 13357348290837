import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Collapse from '@/components/Collapse';
import { useAgendorChatContext } from '@/contexts/agendor-chat';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import Button from '@/components/Button';
import LoadSpinner from '@/components/LoadSpinner';
import { useAuth } from '@/lib/auth';
import { isOptin } from '@/utils';

function IframeAgendorChat() {
  const { user } = useAuth();
  const { isOpen, iframeSrc, openAgendorChat, closeAgendorChat } = useAgendorChatContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen === true) {
      setIsLoading(true);
    }
  }, [isOpen]);

  const widthSize = 550;
  const { pathname } = useLocation();
  const notAllowedPages = ['settings', 'checkout', 'plans', 'pix', 'bank_slip'];

  const isIframeAllowedAccount = isOptin(user?.features?.agendorChatIframeEnabled);
  const isIFrameAllowedOnPage = !notAllowedPages.some((page) => pathname.includes(page));

  if (isIframeAllowedAccount && isIFrameAllowedOnPage) {
    return (
      <Collapse
        in={isOpen}
        id='iframe-agendor-chat'
        className='iframe-modal'
        dimension='width'
      >
        <>
          <Tooltip
            placement='top'
            content={isOpen ? 'Ocultar' : 'Exibir Agendor Chat'}
            className='z-index-2020'
          >
            <Button
              as='label'
              id='iframe-agendor-chat-button'
              className='
                position-absolute
                z-index-2020'
              variant='primary'
              style={{
                width: isOpen ? '2rem' : '3rem',
                height: isOpen ? '2rem' : '3rem',
                right: isOpen ? widthSize : 0,
                top: isOpen ? '5%' : '50%',
                borderRadius: '12px 0 0 12px',
                ...(isOpen
                  ? {
                    borderRadius: '12px',
                    transform: 'translateX(50%)',
                    padding: 0
                  }
                  : {})
              }}
              onClick={() => {
                if (isOpen) {
                  closeAgendorChat();
                } else {
                  openAgendorChat(iframeSrc);
                }
              }}
            >
              <Icon
                name={isOpen ? 'double-arrow-right' : 'whatsapp'}
              />
            </Button>
          </Tooltip>
          {
            isOpen && (
              <div
                className='
                  p-0
                  bg-white
                  position-absolute
                  h-100
                  z-index-1090
                  animation-fade-in-delay'
                style={{
                  top: 0,
                  right: 0,
                  width: widthSize
                }}
              >
                {
                  isLoading && (
                    <div className='
                      d-flex
                      h-100
                      justify-content-center
                      align-items-center
                      text-medium-gray
                      ms-4
                      mt-4
                      mb-8'
                    >
                      <LoadSpinner size='sm' className='d-inline-block me-2' />
                        Carregando agendor chat...
                    </div>
                  )
                }
                <iframe
                  src={iframeSrc}
                  onLoad={handleIframeLoad}
                  className='w-100 h-100 border-0 animation-fade-in-delay'
                  title='Agendor Chat'
                  allow='microphone'
                />
              </div>
            )
          }
        </>
      </Collapse>
    );
  }

  return null;
}

export default IframeAgendorChat;
