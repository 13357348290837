import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import classnames from 'classnames';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { getValidationErrors } from '@/utils';
import { TextInput, CustomFieldSelect, CustomFieldSectionSelect } from '@/components/Inputs';
import Icon from '@/components/Icon';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import OptionsList from '@/components/Inputs/OptionsList';

const propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool,
  initialData: PropTypes.object,
  onHide: PropTypes.func,
  onFormSuccess: PropTypes.func,
  mutation: PropTypes.object,
  isEdit: PropTypes.bool,
  entity: PropTypes.oneOf(['organization', 'person', 'deal']).isRequired
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(30, 'O nome do campo deve ter no máximo 30 caracteres.')
    .required('Por favor, informe o nome do campo.'),
  type: Yup.string()
    .required('Por favor, selecione um tipo de campo.'),
  section: Yup.string()
    .required('Por favor, selecione uma seção para o campo.')
});


const defaultProps = {
  show: false,
  isEdit: false,
  title: '',
  initialData: {},
  onFormSuccess: () => {},
  onHide: () => {},
  mutation: {}
};

const defaultValues = {
  section: 'basic_data', name: '', type: 'text'
};

function CustomFieldFormModal(props) {
  const {
    show, initialData, onHide,
    onFormSuccess, mutation,
    isEdit, title, entity
  } = props;

  const handleCancel = () => {
    onHide();
  };

  const handleTypeSelection = (e) => {
    initialData.type = e;
  };

  const handleChangeOptions = (opts) => {
    initialData.updatedCustomOptions = opts;
  };

  const handleFormSubmit = (formData, { setFieldError, setSubmitting }) => {
    const params = isEdit ? { customFieldId: initialData?.id, params: formData } : formData;
    if (initialData.type === 'multiselect' || initialData.type === 'select') {
      if (params.params === undefined) {
        params.custom_field_options = initialData.updatedCustomOptions;
      } else {
        params.params.custom_field_options = initialData.updatedCustomOptions;
      }
    }
    mutation.mutate(params, {
      onSuccess: onFormSuccess,
      onError: (err) => {
        const errors = getValidationErrors(err, 'custom_fields');
        Object.entries(errors).forEach(([key, message]) => {
          /*
           * Campo identifier é usado apenas no backend para validação unique com tratamento
           * de chars sem acentuacao, exemplo: name: 'Instituição', identifier: 'instituicao'
           * com isso, caso exista um erro de campo com nome duplicado, é necessário informar
           * a mensagem de erro no campo name, por isso foi feito esse tratamento abaixo
           */
          const fieldKey = key === 'identifier' ? 'name' : key;
          setFieldError(fieldKey, message);
        });
        setSubmitting(false);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='md'
    >
      <Form
        className='pt-6 pb-2 px-0'
        name='custom-fields-modal'
        onSubmit={handleFormSubmit}
        defaultValues={defaultValues}
        initialValues={initialData}
        validationSchema={schema}
      >
        {
          ({ handleBlur, handleSubmit, isSubmitting }) => (
            <>
              <header className='text-center mb-6'>
                <div
                  className={classnames(
                    'd-inline-block',
                    'p-3',
                    'rounded-circle',
                    'bg-dark-gray',
                    'text-white',
                    'lh-0',
                    'position-relative'
                  )}
                >
                  <Icon name='product' size='lg' />
                  <div
                    className={classnames(
                      'position-absolute',
                      'end-0',
                      'bottom-0',
                      'p-0',
                      'rounded-circle',
                      'bg-primary',
                      'border',
                      'border-primary'
                    )}
                  >
                    <Icon name='plus' size='sm' />
                  </div>
                </div>
                <h2 className='mt-4'>
                  {title}
                </h2>
              </header>

              <Container className='px-2'>
                <h4 className='mb-4'>Dados do campo</h4>

                <Row className='pb-4 gx-3'>
                  <Col sm={12}>
                    <FormField
                      as={TextInput}
                      name='name'
                      label='Nome'
                      autoComplete='off'
                      placeholder='Digite aqui...'
                      maxLength={30}
                      variant='light'
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col sm={6}>
                    <FormField
                      as={CustomFieldSelect}
                      name='type'
                      disabled={isEdit}
                      onBlur={handleBlur}
                      onChange={handleTypeSelection}
                    />
                  </Col>

                  <Col sm={6}>
                    <FormField
                      as={CustomFieldSectionSelect}
                      name='section'
                      label='Seção do formulário'
                      onBlur={handleBlur}
                      entity={entity}
                    />
                  </Col>
                </Row>
                {
                  initialData.type === 'multiselect' || initialData.type === 'select'
                    ? <Row>
                      <Col sm={12}>
                        <OptionsList
                          options={
                            initialData.customFieldOptions === undefined
                              ? []
                              : initialData.customFieldOptions
                          }
                          onChange={handleChangeOptions}
                        />
                      </Col>
                    </Row>
                    : <Row/>
                }
                <hr className='mx-0' />
              </Container>
              <div className='px-0 mb-2 pt-6'>
                <div className='d-flex justify-content-end px-2'>
                  <Button
                    className='width-4'
                    variant='outline-dark-gray'
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>

                  <LoadingButton
                    className='ms-3 width-4'
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                  >
                    Salvar
                  </LoadingButton>
                </div>
              </div>
            </>
          )
        }
      </Form>
    </Modal>
  );
}

CustomFieldFormModal.propTypes = propTypes;
CustomFieldFormModal.defaultProps = defaultProps;

export default CustomFieldFormModal;
