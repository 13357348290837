import React from 'react';
import EntityTitle from '@/components/EntityModal/EntityTitle';
import Menu from '@/components/EntityModal/Menu';
import Tabs from '@/components/EntityModal/Person/Header/Tabs';
import { usePerson, useOnUpdate, useOnDelete, useVoip } from '@/contexts/personModal';
import { canEditPerson } from '@/policies';
import { personEditPath } from '@/routes';
import SelectOwnerUser from '@/components/EntityModal/SelectOwnerUser';
import HeaderAvatar from '@/components/EntityModal/HeaderAvatar';
import RestrictWarningBadge from '@/components/EntityModal/RestrictWarningBadge';
import AddDealButton from '@/components/EntityModal/AddDealButton';
import Skeleton from '@/components/Skeleton';
import SkeletonWrapper from '@/components/SkeletonWrapper';
import SelectCategory from '@/components/EntityModal/SelectCategory';
import AvailablePhoneField from '@/components/EntityModal/AvailablePhoneField';
import EmailField from '@/components/EntityModal/EmailField';
import DotList from '@/components/DotList';

const ENTITY_TYPE = 'person';

function Header() {
  const person = usePerson();
  const onUpdate = useOnUpdate();
  const onDelete = useOnDelete();
  const voip = useVoip();

  const isLoading = !person;

  return (
    <div className='rounded-top px-4 pt-4'>
      <div className='mb-4 d-flex justify-content-between'>
        <div className='d-flex flex-fill'>
          <SkeletonWrapper
            isLoading={isLoading}
            SkeletonComponent={() => <Skeleton width={64} height={64} className='me-3' />}
          >
            <HeaderAvatar
              entityType={ENTITY_TYPE}
              avatarUrl={person?.avatar?.url}
            />
          </SkeletonWrapper>

          <div className='py-1 flex-fill'>
            <div className='w-100 d-flex align-items-center mb-1'>
              <div className='flex-fill d-flex align-items-center'>
                <SkeletonWrapper
                  isLoading={isLoading}
                  SkeletonComponent={() => <Skeleton width={250} height={30} />}
                >
                  <EntityTitle
                    onUpdate={onUpdate}
                    entity={{ title: person?.name }}
                  />

                  {person?.jobTitle && (
                    <span className='text-dark-gray ms-1'>
                      ({person?.jobTitle})
                    </span>
                  )}
                </SkeletonWrapper>
              </div>

              <div>
                <SkeletonWrapper
                  isLoading={isLoading}
                  SkeletonComponent={() => <Skeleton width={50} height={20} />}
                >
                  <RestrictWarningBadge entity={person} loading={false} />
                </SkeletonWrapper>
              </div>
            </div>

            <div className='w-100 d-flex justify-items-center'>
              <div className='d-flex flex-fill align-items-center'>
                <DotList className='d-flex align-items-center'
                  items={[
                    <SkeletonWrapper
                      key={1}
                      isLoading={isLoading}
                      SkeletonComponent={() => <Skeleton width={160} height={15} />}
                    >
                      <SelectCategory
                        category={person?.category}
                        canEditPolicy={canEditPerson}
                        onUpdate={onUpdate}
                      />
                    </SkeletonWrapper>,

                    <SkeletonWrapper
                      key={2}
                      isLoading={isLoading}
                      SkeletonComponent={() => <Skeleton width={160} height={15} />}
                    >
                      <AvailablePhoneField
                        inputValue={person?.contact?.whatsapp}
                        previewValue={person?.contact?.availablePhone}
                        inputName='contact.whatsapp'
                        onEditEntity={(params, done) => onUpdate(params, done)}
                        entity={person}
                        entityType='person'
                        voip={voip}
                      />
                    </SkeletonWrapper>,

                    <SkeletonWrapper
                      key={3}
                      isLoading={isLoading}
                      SkeletonComponent={() => <Skeleton width={160} height={15} />}
                    >
                      <EmailField
                        inputValue={person?.contact?.email}
                        inputName='contact.email'
                        onEditEntity={(params, done) => onUpdate(params, done)}
                        entityId={person?.id}
                        entityType='person'
                      />
                    </SkeletonWrapper>,

                    <SkeletonWrapper
                      key={4}
                      isLoading={isLoading}
                      SkeletonComponent={() => <Skeleton width={160} height={15} />}
                    >
                      <SelectOwnerUser
                        ownerUser={person?.ownerUser}
                        canEditPolicy={canEditPerson}
                        onUpdate={onUpdate}
                      />
                    </SkeletonWrapper>
                  ]} />
              </div>

              <div className='d-flex'>
                <SkeletonWrapper
                  isLoading={isLoading}
                  SkeletonComponent={() => (
                    <>
                      <Skeleton width={200} height={30} />

                      <Skeleton width={110} height={30} className='ms-3' />
                    </>
                  )}
                >
                  <AddDealButton
                    entityId={person?.id}
                    entityType={ENTITY_TYPE}
                  />
                  <Menu
                    entity={person}
                    entityType={ENTITY_TYPE}
                    loading={false}
                    onDelete={onDelete}
                    editPath={personEditPath(person?.id)}
                  />
                </SkeletonWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Tabs />
    </div>
  );
}

export default Header;
