import React from 'react';
import { Col } from 'react-bootstrap';

import Sidebar from '@/components/EntityModal/Organization/Sidebar/Sidebar';
import DealTab from '@/components/EntityModal/DealTab';
import PeopleTab from '@/components/EntityModal/PeopleTab';
import Activities from '@/components/EntityModal/Organization/Activities/Activities';
import Form from '@/components/EntityModal/Organization/Activities/Form';
import { useActiveTab } from '@/contexts/organizationModal';
import { useScreenSize } from '@/hooks';

function ModalContent({ organizationId }) {
  const activeTab = useActiveTab();
  const { isMD } = useScreenSize();

  if (activeTab === 'deals') {
    return (
      <>
        <Col sm='12'>
          <DealTab entityId={organizationId} entityType='organization' />
        </Col>
      </>
    );
  }

  if (activeTab === 'people') {
    return (
      <>
        <Col sm='12'>
          <PeopleTab organizationId={organizationId} />
        </Col>
      </>
    );
  }

  return (
    <>
      <Col className='bg-white py-6 rounded-bottom-start px-0' lg='8' sm='12'>
        <div className={isMD ? 'px-3' : 'px-8'}>
          <Form />

          <Activities />
        </div>
      </Col>

      {!isMD && (
        <Col lg='4' className='pe-0'>
          <div className='pe-4 pb-4'>
            <Sidebar/>
          </div>
        </Col>
      )}
    </>
  );
}

export default ModalContent;
