import React, { createContext, useContext } from 'react';
import { useStore } from 'zustand';

import { usePersonModalStore } from '@/store/person-modal';

const PersonModalContext = createContext(undefined);

export function usePersonContext() {
  const context = useContext(PersonModalContext);

  if (context === undefined) {
    throw new Error('usePersonModalContext must be used within PersonModalProvider');
  }

  return context;
}

function usePersonModalStoreContext(selector) {
  const context = usePersonContext();

  return useStore(context.store, selector);
}

export const usePerson = () => usePersonContext().person;

export const useOnUpdate = () => usePersonContext().onUpdate;

export const useOnUpdateOrganization = () => usePersonContext().onUpdateOrganization;

export const useOnDelete = () => usePersonContext().onDelete;

export const useVoip = () => usePersonContext().voip;

export const useActiveTab = () => usePersonModalStoreContext(
  (state) => state.activeTab
);

export const useChangeTab = () => usePersonModalStoreContext(
  (state) => state.changeTab
);

export function PersonModalProvider({ children, value: componentValue }) {
  const {
    person,
    onUpdate,
    onDelete,
    onUpdateOrganization,
    voip
  } = componentValue;

  const [store] = usePersonModalStore();

  const value = {
    store,
    person,
    onUpdate,
    onDelete,
    onUpdateOrganization,
    voip
  };

  return (
    <PersonModalContext.Provider value={value}>
      {children}
    </PersonModalContext.Provider>
  );
}
