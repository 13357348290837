import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import TruncateLinkfied from '@/components/TruncateLinkfied';
import Skeleton from '@/components/Skeleton';
import Truncate from '@/components/Truncate';
import Avatar from '@/components/Avatar';
import { DatePickerInput, EditInPlace, TextArea, UserSelect } from '@/components/Inputs';
import DetailsCard from '@/components/EntityModal/DetailsCard';
import DetailsCustomField from '@/components/EntityModal/DetailsCustomField';
import CreatedBy from '@/components/EntityModal/Deal/CreatedBy';
import {
  parseWithTimezoneOffset,
  parseDate,
  getTimelineDayLabel,
  toDateString,
  toTimeStringWithSeparator
} from '@/date';
import { canEditDeal } from '@/policies';

DetailsData.defaultProps = {
  deal: {},
  customFields: [],
  loading: false,
  onEditDeal: () => {}
};

DetailsData.propTypes = {
  deal: PropTypes.object,
  customFields: PropTypes.array,
  loading: PropTypes.bool,
  onEditDeal: PropTypes.func
};

function DetailsData({ deal, customFields, loading, onEditDeal }) {
  const finishedAtDate = deal?.finishedAt ? parseWithTimezoneOffset(deal.finishedAt) : null;
  const startedAtDate = deal?.startedAt ? parseWithTimezoneOffset(deal.startedAt) : null;
  const createdAtDate = deal?.createdAt ? parseDate(deal.createdAt) : null;

  const startedAtLabel = startedAtDate && getTimelineDayLabel(startedAtDate);
  const finishedAtLabel = finishedAtDate && getTimelineDayLabel(finishedAtDate);
  const createdAtLabel = createdAtDate && getTimelineDayLabel(createdAtDate);

  const startedAtDateString = startedAtDate ? toDateString(startedAtDate) : null;
  const finishedAtDateString = finishedAtDate ? toDateString(finishedAtDate) : null;
  const createdAtDateString = (
    createdAtDate ? toTimeStringWithSeparator(createdAtLabel, createdAtDate) : null
  );

  const startedAtDateText = startedAtLabel || startedAtDateString || 'Indefinido';
  const finishedAtDateText = finishedAtLabel || finishedAtDateString || 'Indefinido';
  const createdAtDateText = createdAtDateString || 'Indefinido';
  const ownerUserAvatar = deal?.ownerUser
    ? { name: deal.ownerUser.name, url: deal.ownerUser.avatarUrl }
    : null;

  if (loading) {
    return (
      <Skeleton className='mt-3 mb-3' width='100%' height={200} />
    );
  }

  return (
    <DetailsCard className='mt-3' title='Dados do negócio'>
      <div className='align-items-center mb-3'>
        <EditInPlace
          name='description'
          as={TextArea}
          value={deal?.description}
          onConfirm={onEditDeal}
          policy={canEditDeal}
        >
          <span className='fw-bold text-small'>Descrição</span>
        </EditInPlace>

        <div className={classnames({ 'text-medium-gray': !deal?.description })}>
          <TruncateLinkfied
            text={deal?.description || 'Nenhuma informação adicionada'}
            maxLength={150}
          />
        </div>
      </div>

      <Row className='align-items-center mb-3'>
        <Col sm={4}>
          <span className='fw-bold text-small'>Cadastrado por</span>
        </Col>

        <Col sm={8} className='d-flex align-items-center'>
          <CreatedBy deal={deal} />
        </Col>
      </Row>

      <Row className='align-items-center mb-3'>
        <Col sm={4}>
          <span className='fw-bold text-small'>Responsável</span>
        </Col>

        <Col sm={8}>
          <EditInPlace
            as={UserSelect}
            name='owner_user_id'
            placeholder='Selecione'
            label=''
            value={deal?.ownerUser?.id}
            onConfirm={onEditDeal}
            isSearchable
            isSelect
            policy={canEditDeal}
          >
            <div className='d-flex align-items-center'>
              {
                ownerUserAvatar
                  ? (
                    <Avatar
                      className='me-1'
                      name={ownerUserAvatar.name}
                      url={ownerUserAvatar.url}
                    />
                  )
                  : null
              }
              <Truncate
                className={classnames(
                  { 'max-width-4': ownerUserAvatar },
                  { 'text-medium-gray': !ownerUserAvatar },
                  { 'text-medium': ownerUserAvatar }
                )}
              >
                {ownerUserAvatar?.name ?? 'Indefinido'}
              </Truncate>
            </div>
          </EditInPlace>
        </Col>
      </Row>

      <Row className='align-items-center mb-3'>
        <Col sm={4}>
          <span className='fw-bold text-small'>Data de início</span>
        </Col>

        <Col sm={8}>
          <EditInPlace
            as={DatePickerInput}
            initialShow={true}
            placement='left'
            name='started_at'
            value={startedAtDateText}
            onConfirm={onEditDeal}
            isDatePicker
            policy={canEditDeal}
          >
            <div>{startedAtDateText}</div>
          </EditInPlace>
        </Col>
      </Row>

      <Row className='align-items-center mb-3'>
        <Col sm={4}>
          <span className='fw-bold text-small'>Data de conclusão</span>
        </Col>

        <Col sm={8}>
          <EditInPlace
            as={DatePickerInput}
            initialShow={true}
            placement='left'
            name='finished_at'
            value={finishedAtDateText}
            onConfirm={onEditDeal}
            isDatePicker
            policy={canEditDeal}
          >
            <div className={classnames(
              { 'text-medium-gray': !deal?.finishedAt }
            )}>
              {finishedAtDateText}
            </div>
          </EditInPlace>
        </Col>
      </Row>

      <Row className='align-items-center mb-3'>
        <Col sm={4}>
          <span className='fw-bold text-small'>Data de cadastro</span>
        </Col>

        <Col sm={8}>
          <span className='text-nowrap'>{createdAtDateText}</span>
        </Col>
      </Row>

      {
        customFields.map(({ identifier, customFieldOptions, type, name }) => (
          <DetailsCustomField
            key={identifier}
            onEdit={onEditDeal}
            identifier={identifier}
            customFieldOptions={customFieldOptions}
            type={type}
            name={name}
            rawValue={deal?.customFields?.[identifier]}
          />
        ))
      }
    </DetailsCard>
  );
}

export default DetailsData;
