import React from 'react';
import { personCreatePath } from '@/routes';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Authorization from '@/components/Authorization';
import { canCreatePerson } from '@/policies';

function ButtonAddPeople({ organizationId }) {
  const path = personCreatePath(organizationId);

  return (
    <Authorization policy={canCreatePerson}>
      <Button href={path}>
        <Icon name='plus' className='me-2' />
        Adicionar pessoa
      </Button>
    </Authorization>
  );
}

export default ButtonAddPeople;
