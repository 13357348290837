import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import capitalize from 'lodash/capitalize';

import {
  useCurrentAccount, useSubscriptionPreview, useCurrentPlan,
  currentPlanKeys, useCreateSubscription, useTransaction
} from '@/api';
import { CheckoutProvider } from '@/contexts';
import { useQueryParams, useStepper } from '@/hooks';
import { useAuth } from '@/lib/auth';
import { settingsCurrentPlanPath, bankSlipPath, pixPath } from '@/routes';
import { isBlank } from '@/utils';

import AccountData from '@/components/Billing/Steps/AccountData';
import ChoosePlan from '@/components/Billing/Steps/ChoosePlan';
import Footer from '@/components/Footer';
import LoadingOverlay from '@/components/LoadingOverlay';
import Main from '@/components/Main';
import Payment from '@/components/Billing/Steps/Payment';
import PaymentSummary from '@/components/Billing/PaymentSummary';
import StepperHeader from '@/components/StepperHeader';
import StripeConfirmation from '@/components/Billing/Steps/StripeConfirmation';
import StripeElement from '@/components/Billing/StripeComponents/StripeElement';
import PaymentFailureModal from '@/components/Billing/PaymentFailureModal';
import PaymentSuccessModal from '@/components/Billing/PaymentSuccessModal';
import ThreeDSecureModal from '@/components/Billing/ThreeDSecureModal';
import PendingBankSlipWarning from '@/components/PendingBankSlipWarning';

const REFETCH_INTERVAL = 5000;

const ACCOUNT_DATA_INDEX = 1;

const HEADER_LABELS = [
  'Plano',
  'Dados de faturamento',
  'Pagamento',
  'Confirmação'
];

const STEPS = [
  'plan',
  'billing',
  'payment',
  'confirmation'
];

const PAYMENT_DATA = {
  type: 'credit_card',
  credit_card: {
    is_valid: false,
    end_digits: null,
    brand: null
  }
};

const PAGE_TITLE = 'Você está a um passo de adquirir o Agendor!';

const PAYMENT_OPTIONS = [
  {
    label: 'Cartão de crédito',
    value: 'credit_card'
  },
  {
    label: 'Pix',
    value: 'pix'
  },
  {
    label: 'Boleto',
    value: 'bank_slip'
  }
];

function Checkout({ currentPlanData, currentAccountData }) {
  const {
    plan: queryPlan,
    period: queryPeriod,
    maxUsers: queryMaxUsers,
    cupom: queryCoupon
  } = useQueryParams();

  let planCode = queryPlan || currentPlanData.productCode;
  const planPeriod = queryPeriod || currentPlanData.periodicity;
  const activeUsersCount = currentPlanData.currentActiveUsersCount;
  const couponCode = queryCoupon;

  if (!planCode || planCode === 'free_plan') {
    planCode = 'pro_plan';
  }

  const { user } = useAuth();
  const navigate = useNavigate();
  const createSubscriptionMutation = useCreateSubscription();
  const queryClient = useQueryClient();

  const [isAccountDataSubmitted, setIsAccountDataSubmitted] = useState(false);

  const requiredFields = currentAccountData
    ? {
      name: currentAccountData.name,
      legalName: currentAccountData.legalName,
      cpfCnpj: currentAccountData.cpfCnpj,
      WorkPhone: currentAccountData.contact.workPhone,
      cityName: currentAccountData.address?.city?.name,
      country: currentAccountData.address.country,
      district: currentAccountData.address.district,
      state: currentAccountData.address.state,
      postalCode: currentAccountData.address.postalCode,
      streetNumber: currentAccountData.address.streetNumber?.toString() || '',
      streetName: currentAccountData.address.streetName
    }
    : {};

  const isValidAccountData = useMemo(() => Object.values(requiredFields).every(
    (value) => !isBlank(value) && value.trim()
  ), [requiredFields]);

  const hideAccountData = isValidAccountData && !isAccountDataSubmitted;

  const filteredSteps = STEPS.filter((_, index) => !hideAccountData ||
    index !== ACCOUNT_DATA_INDEX);

  const filteredHeaderLabels = HEADER_LABELS.filter((_, index) => !hideAccountData ||
    index !== ACCOUNT_DATA_INDEX);

  const LAST_LABEL_INDEX = filteredHeaderLabels.length - 1;
  const selectedMaxUsers = queryMaxUsers || user.account.maxUsers;

  const { nextStep, prevStep, step } = useStepper(LAST_LABEL_INDEX);
  const [checkoutResumeData, setCheckoutResumeData] = useState();
  const [plan, setPlan] = useState(planCode);
  const [period, setPeriod] = useState(planPeriod || 'yearly');
  const [coupon, setCoupon] = useState();
  const [usersCount, setUsersCount] = useState(selectedMaxUsers);
  const [paymentData, setPaymentData] = useState(PAYMENT_DATA);
  const [encryptedData, setEncryptedData] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState();
  const [paymentErrors, setPaymentErrors] = useState();
  const [transactionId, setTransactionId] = useState(null);
  const [transactionPollingEnabled, setTransactionPollingEnabled] = useState(false);
  const [threeDSecureUrl, setThreeDSecureUrl] = useState(null);
  const [showThreeDSecureModal, setShowThreeDSecureModal] = useState(false);

  const { isLoading: isLoadingCheckoutResume } = useSubscriptionPreview({
    config: {
      cacheTime: 0,
      onSuccess: ({ data }) => setCheckoutResumeData(data)
    },
    params: {
      period,
      product_code: plan,
      discount_id: coupon?.billingDiscount.id,
      users_count: usersCount
    }
  });

  const isBankSlip = paymentData.type === 'bank_slip';
  const isCreditCard = paymentData.type === 'credit_card';
  const isPix = paymentData.type === 'pix';

  const onCreatePayment = () => {
    const params = {
      payment_type_id: getPaymentTypeId(paymentData),
      payment_method_id: paymentData.paymentMethod?.id,
      product_code: plan,
      quantity: usersCount,
      period: period || '',
      discount_id: coupon?.billingDiscount.id
    };

    createSubscriptionMutation.mutate(params, {
      onSuccess: ({ data }) => {
        setTransactionPollingEnabled(true);
        setTransactionId(data.id);
      },
      onError: (err) => {
        setPaymentErrors(err.data?.errors);
        setPaymentStatus('error');
      }
    });
  };

  const handleBankSlipGenerated = (data) => {
    setTransactionPollingEnabled(false);

    const updatedData = {
      ...data,
      planName: checkoutResumeData.name
    };

    navigate(bankSlipPath(), { state: updatedData });
  };

  const handlePixGenerated = (data) => {
    setTransactionPollingEnabled(false);

    const updatedData = {
      ...data,
      planName: checkoutResumeData.name
    };

    navigate(pixPath(), { state: updatedData });
  };

  const handlePaymentCompleted = () => {
    setTransactionPollingEnabled(false);

    if (isCreditCard) {
      setPaymentStatus('success');
    }
  };

  const handleCreditCard = (url) => {
    setThreeDSecureUrl(url);
    setShowThreeDSecureModal(true);
  };

  useTransaction({
    billingTransactionId: transactionId,
    config: {
      refetchInterval: REFETCH_INTERVAL,
      cacheTime: 0,
      enabled: Boolean(transactionPollingEnabled && transactionId),
      onSuccess: async ({ data }) => {
        switch (data.statusName) {
          case 'Aguardando Pagto':
            return;
          case 'Em análise':

            if (isCreditCard && data.transactionDetails.three_d_secure_url) {
              const decodedUrl = decodeURIComponent(data.transactionDetails.three_d_secure_url);
              handleCreditCard(decodedUrl);
            }

            if (isBankSlip) {
              handleBankSlipGenerated(data);
            }

            if (isPix) {
              handlePixGenerated(data);
            }

            return;
          case 'Aprovado':
          case 'Completo':
            await Promise.all([queryClient.invalidateQueries(currentPlanKeys.currentPlan)]);

            return handlePaymentCompleted();
          default:
            setTransactionPollingEnabled(false);
            setPaymentStatus('error');
        }
      }
    }
  });

  const value = {
    accountData: currentAccountData,
    currentPlanData,
    period,
    plan,
    setPlan,
    setPeriod,
    usersCount,
    setUsersCount,
    activeUsersCount,
    coupon,
    setCoupon,
    paymentData,
    setPaymentData,
    encryptedData,
    setEncryptedData,
    checkoutResumeData,
    isLoadingCheckoutResume,
    isLoadingCreatePayment: createSubscriptionMutation.isLoading,
    transactionPollingEnabled,
    onCreatePayment,
    step,
    prevStep,
    nextStep,
    setIsAccountDataSubmitted,
    couponCode
  };

  const actualStep = filteredSteps[step];

  const CurrentStep = useMemo(() => {
    const steps = [
      <ChoosePlan key={1} />,
      <AccountData key={2} />,
      <></>,
      <StripeConfirmation key={4} />
    ];

    const filteredCurrentSteps = steps.filter((_, index) => !hideAccountData ||
      index !== ACCOUNT_DATA_INDEX);

    return filteredCurrentSteps[step];
  }, [step, hideAccountData]);


  if (!checkoutResumeData) {
    return null;
  }

  return (
    <CheckoutProvider value={value}>
      <Main fluid className='py-8 ps-4 pe-8 container-full-height'>
        <Container className='pb-8'>
          <div className='text-center'>
            <h1 className='mb-2'>{PAGE_TITLE}</h1>

            <p className='subtitle text-dark-gray'>
              O CRM com o melhor custo-benefício do mercado.
            </p>

            <StepperHeader
              headerClassName='d-flex justify-content-center align-items-center fw-bold mt-4'
              currentStep={step}
              headerLabels={filteredHeaderLabels}
            />
          </div>
          <div className='py-3 mb-3'>
            <Row>
              <Col xs={12} md={10} lg={8} className='mx-auto'>
                <PendingBankSlipWarning
                  currentPlanFullName={`${currentPlanData.name} ${currentPlanData.maxUsersCount}`}
                  selectedPlanFullName={`${checkoutResumeData.name} ${usersCount}`}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={{ span: 5, offset: 1 }} xxl={{ span: 4, offset: 2 }} className='pe-0'>
                <StripeElement>
                  <Payment
                    radioOptions={PAYMENT_OPTIONS}
                    show={actualStep === 'payment'}
                    isStripeComponent
                  />

                  {CurrentStep}
                </StripeElement>
              </Col>

              <Col xl={5} xxl={4}>
                <LoadingOverlay show={isLoadingCheckoutResume} className='rounded'>
                  <PaymentSummary isPlanStep={actualStep === 'plan'} />
                </LoadingOverlay>
              </Col>
            </Row>
          </div>
        </Container>
      </Main>

      <ThreeDSecureModal
        show={showThreeDSecureModal}
        url={threeDSecureUrl}
        onHide={() => setShowThreeDSecureModal(false)}
      />;

      <PaymentSuccessModal
        show={paymentStatus === 'success'}
        title={`Seu Plano ${capitalize(checkoutResumeData.name)} está liberado!`}
        message={`Agora você já pode utilizar os recursos avançados do Agendor
          para agilizar a operação do seu time e vender mais.`}
        redirectPath={settingsCurrentPlanPath()}
      />

      <PaymentFailureModal
        show={paymentStatus === 'error'}
        onHide={() => setPaymentStatus()}
        errors={paymentErrors}
      />

      {/* Componente que renderizará o conteúdo do BillingFooter */}
      <Footer id='billing-footer' className='shadow-up bg-white' />
    </CheckoutProvider>
  );
}

function getPaymentTypeId(paymentData) {
  const paymentTypeIds = {
    credit_card: 13,
    bank_slip: 14,
    pix: 15
  };

  return paymentTypeIds[paymentData.type];
}

function CheckoutWrapper() {
  const {
    data: { data: currentPlanData } = {},
    isSuccess: isSuccessCurrentPlan
  } = useCurrentPlan();

  const {
    data: { data: currentAccountData } = {},
    isSuccess: isSuccessCurrentAccount
  } = useCurrentAccount();

  if (!isSuccessCurrentAccount || !isSuccessCurrentPlan) {
    return null;
  }

  return (
    <Checkout
      currentPlanData={currentPlanData}
      currentAccountData={currentAccountData}
    />
  );
}

export default CheckoutWrapper;
