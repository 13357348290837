import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  truncate: PropTypes.bool,
  selectable: PropTypes.bool,
  onClick: PropTypes.func
};

const defaultProps = {
  className: '',
  items: [],
  truncate: false,
  selectable: false,
  onClick: null
};

function DotList({ className, items, truncate, selectable, onClick }) {
  return (
    <ul className={classnames(
      'dot-list',
      'p-0',
      'm-0',
      'overflow-x-clip',
      'text-break-all',
      className,
      { 'text-truncate': truncate }
    )}>
      {items.map((item, index) => (
        <li
          onClick={(e) => selectable && onClick(e)}
          key={index}
          className={classnames(
            'dot-list-item',
            'd-inline',
            { 'cursor-default': selectable }
          )}
        >
          {item}
        </li>
      ))}
    </ul>
  );
}

DotList.propTypes = propTypes;
DotList.defaultProps = defaultProps;

export default DotList;
