import React from 'react';
import Icon from '@/components/Icon';
import { SummaryContent, TemplateCard } from '@/feature/automation';
import Keyword from '@/feature/automation/Keyword';
import { extractComponentText } from '@/utils';

const triggers = {
  on_deal_stage_arrived: {
    triggerIdentifier: (<span>Quando um <Keyword>negócio</Keyword> chegar a uma etapa</span>),
    triggerIcon: 'funnel',
    triggerForm: 'funnel',
    triggerDependant: 'stage',
    triggerValidation: 'funnel_stage',
    triggerName: 'on_deal_stage_arrived'
  },
  on_deal_created: {
    triggerIdentifier: (<span>Quando um <Keyword>negócio</Keyword> for adicionado</span>),
    triggerIcon: 'deal',
    triggerForm: 'funnel',
    triggerValidation: 'funnel',
    triggerName: 'on_deal_created'
  },
  on_person_created: {
    triggerIdentifier: (<span>Quando uma <Keyword>pessoa</Keyword> for adicionada</span>),
    triggerIcon: 'person',
    triggerName: 'on_person_created'
  },
  on_organization_created: {
    triggerIdentifier: (<span>Quando uma <Keyword>empresa</Keyword> for adicionada</span>),
    triggerIcon: 'organization',
    triggerName: 'on_organization_created'
  },
  on_deal_won: {
    triggerIdentifier: (<span>Quando um <Keyword>negócio</Keyword> for ganho</span>),
    triggerIcon: 'won-deal',
    triggerForm: 'funnel',
    triggerValidation: 'funnel',
    triggerName: 'on_deal_won'
  },
  on_deal_lost: {
    triggerIdentifier: (<span>Quando um <Keyword>negócio</Keyword> for perdido</span>),
    triggerIcon: 'lost-deal',
    triggerForm: 'funnel',
    triggerValidation: 'funnel',
    triggerName: 'on_deal_lost'
  }
};

const actions = {
  schedule_activity: {
    actionIdentifier: 'Agendar uma atividade',
    actionIcon: 'task',
    actionForm: 'activity',
    actionName: 'schedule_activity',
    helpPath: 'task-automation'
  },
  duplicate_deal: {
    actionIdentifier: 'Duplicá-lo para outro funil',
    actionIcon: 'copy',
    actionForm: 'action_funnel',
    actionName: 'duplicate_deal',
    helpPath: 'deal-automation'
  },
  send_email: {
    actionIdentifier: 'Enviar um e-mail',
    actionIcon: 'email-sent',
    actionForm: 'email',
    actionName: 'send_email',
    helpPath: 'email-automation'
  }
};

export const templates = {
  on_deal_stage_arrived_schedule_activity: {
    ...triggers.on_deal_stage_arrived,
    ...actions.schedule_activity
  },
  on_deal_created_schedule_activity: {
    ...triggers.on_deal_created,
    ...actions.schedule_activity
  },
  on_person_created_schedule_activity: {
    ...triggers.on_person_created,
    ...actions.schedule_activity
  },
  on_organization_created_schedule_activity: {
    ...triggers.on_organization_created,
    ...actions.schedule_activity
  },
  on_deal_won_duplicate_deal: {
    ...triggers.on_deal_won,
    ...actions.duplicate_deal
  },
  on_deal_lost_duplicate_deal: {
    ...triggers.on_deal_lost,
    ...actions.duplicate_deal
  },
  on_deal_stage_arrived_send_email: {
    ...triggers.on_deal_stage_arrived,
    ...actions.send_email
  },
  on_deal_won_send_email: {
    ...triggers.on_deal_won,
    ...actions.send_email
  },
  on_deal_lost_send_email: {
    ...triggers.on_deal_lost,
    ...actions.send_email
  }
};

export function getAutomationName(template) {
  const { triggerIdentifier, actionIdentifier } = template;
  const triggerIdentifierText = extractComponentText(triggerIdentifier);

  return `${triggerIdentifierText} → ${actionIdentifier}`;
}

function AutomationComponent(props) {
  const { type, data, className, component } = props;
  const template = templates[type];

  switch (component) {
    case 'icon':
      return (
        <Icon name={template?.triggerIcon} className='text-dark-gray'/>
      );
    case 'summaryContent':
      return (
        <SummaryContent data={data} template={template} className={className} />
      );
    default:
      return (
        <TemplateCard template={template}/>
      );
  }
}

export default AutomationComponent;
