import { emitter, useExperiment } from '@marvelapp/react-ab-test';
import { useAuth } from '@/lib/auth';
import { funnelsPath } from '@/routes';

const abTest = {
  name: 'Teste A/B Onboarding',
  variants: [
    'Grupo A - Controle',
    'Grupo B - Teste'
  ]
};

emitter.defineVariants(abTest.name, abTest.variants);

export function useOnboarding() {
  const { user } = useAuth();

  const userIdentifier = user.account.id.toString();
  const experiment = useExperiment(abTest.name, userIdentifier);

  return { ...experiment, homePath: funnelsPath };
}
