import { getUrlWithQueryString } from '@/utils';

export function settingsCurrentPlanPath() {
  return '/settings/account/current_plan';
}

export function settingsManageCurrentPlanPath() {
  return '/settings/account/current_plan/manage';
}

export function settingsManageUpdatePlanPath(params) {
  return getUrlWithQueryString('/settings/account/current_plan/manage/update', params);
}

export function settingsManagePaymentMethodPath() {
  return '/settings/account/current_plan/manage/payment_method';
}

export function chargeCompletionPath() {
  return '/settings/account/current_plan/manage/charge_completion';
}

export function settingsCustomFieldsPath(entity = '') {
  return `/settings/custom_fields/${entity}`;
}

export function settingsProductsPath() {
  return '/settings/products';
}

export function settingsFunnelsPath() {
  return '/settings/funnels';
}

export function settingsPath() {
  return '/settings';
}

export function settingsModulesVoipPath() {
  return '/settings/modules/voip';
}

export function settingsVoipPanelPath() {
  return '/settings/voip/panel';
}

export function settingsVoipCallsPath() {
  return '/settings/voip/calls';
}

export function settingsFunnelStagesPath(funnelId) {
  return `/settings/funnels/${funnelId}/stages`;
}

export function settingsFunnelsDealsRelocationsPath(funnelId) {
  return `/settings/funnels/${funnelId}/deals_relocation`;
}

export function settingsStagesDealsRelocationsPath(funnelId, stageId) {
  return `/settings/funnels/${funnelId}/stages/${stageId}/deals_relocation`;
}

export function settingsFunnelStagesRequiredFieldsPath(funnelId) {
  return `/settings/funnels/${funnelId}/stages/required_fields`;
}

export function settingsProductCategoriesPath() {
  return '/settings/products/categories';
}

export function settingsAutomationsPath() {
  return '/settings/automations';
}

export function settingsAutomationTemplatesPath() {
  return '/settings/automations/templates';
}

export function settingsEmailTemplatesPath() {
  return '/settings/email_template';
}
