import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from 'react-bootstrap/ProgressBar';

const propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired
};

function Header({ currentStep, totalSteps }) {
  const stepPosition = currentStep + 1;
  const progressBarPercentage = stepPosition / totalSteps * 100;

  return (
    <ProgressBar
      className='fixed-top w-100 rounded-0'
      variant='primary'
      now={progressBarPercentage}
    />
  );
}

Header.propTypes = propTypes;
export default Header;
