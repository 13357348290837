import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { settingsVoipPanelPath } from '@/routes';
import { useAuth } from '@/lib/auth';
import Icon from '@/components/Icon';
import { toCurrencyString } from '@/number';

const MIN_BALANCE_WARNING = 20;

function VoipMessageBalance({ accountBalance }) {
  const { user } = useAuth();
  const isAdmin = user?.admin;

  if (accountBalance >= MIN_BALANCE_WARNING) {
    return null;
  }

  return (
    <Card className='bg-light-yellow text-warning border-0 p-2 mb-4'>
      <Row>
        <Col sm={2} className='d-flex align-items-center'>
          <Icon name='alert' variant='warning' className='ms-2'></Icon>
        </Col>
        <Col sm={10} className='ms-n3 mt-1'>
          <span className='text-warning fw-bold ms-n2'>
            Seus créditos estão acabando!
          </span>
          <li className='text-small'>
            Você possui menos de{' '}
            <b>{toCurrencyString(MIN_BALANCE_WARNING)} de créditos</b>{' '}
            no telefone virtual.
          </li>
          {isAdmin
            ? (
              <div>
                <li className='text-small'>
                  Efetue uma nova recarga para não correr riscos de ficar sem créditos.
                </li>
                <Button
                  size='sm'
                  variant='link'
                  className='text-nowrap fw-bold px-0 mt-1'
                  href={settingsVoipPanelPath()}
                >
                  <Icon name='external-link' className='text-dark me-1'></Icon>
                  <span className='text-dark'>Recarregar</span>
                </Button>
              </div>
            )
            : (
              <li className='text-small'>
                Solicite ao administrador da conta para<br/>
                adicionar créditos.
              </li>
            )}
        </Col>
      </Row>
    </Card>
  );
}

export default VoipMessageBalance;
