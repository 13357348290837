import React from 'react';
import PropTypes from 'prop-types';

import emptyImage from 'images/empty-list-deals.png';
import ButtonAddPeople from '@/components/EntityModal/AddPersonButton';

PersonTabEmpty.propTypes = {
  organizationId: PropTypes.number.isRequired
};

function PersonTabEmpty({ organizationId }) {
  return (
    <>
      <div className='d-flex flex-column text-center justify-content-center'>
        <img className='mx-auto mb-4' src={emptyImage} />

        <h4 className='mb-4'>Adicione pessoas nesta empresa</h4>

        <div className='mb-5 text-dark-gray'>
          Você ainda não cadastrou nenhuma pessoa nesta empresa. Que tal adicionar alguma pessoa
          <br />
          decisora ou influenciadora?
        </div>

        <div>
          <ButtonAddPeople organizationId={organizationId} />
        </div>
      </div>
    </>
  );
}

export default PersonTabEmpty;
