import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Badge';
import classnames from 'classnames';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'dark',
    'gray',
    'dark-gray',
    'flat-green',
    'flat-red',
    'white',
    'light',
    'light-green',
    'light-red',
    'yellow',
    'turquoise'
  ])
};

const defaultProps = {
  variant: 'primary'
};

const Badge = React.forwardRef(
  ({ children, variant, className, ...props }, ref) => (
    <Base
      bg={variant}
      ref={ref}
      className={classnames({
        'text-dark-gray': variant === 'white' || variant === 'light',
        'text-success': variant === 'light-green',
        'text-danger': variant === 'light-red',
        'text-warning': variant === 'yellow'
      }, className)}
      { ...props }
    >
      {children}
    </Base>
  )
);

Badge.propTypes = propTypes;
Badge.defaultProps = defaultProps;
Badge.displayName = 'Badge';

export default Badge;
